import React from 'react';
import Grid, { GridCell } from '../DisplaySystem/Grid';
import RegistrationForm from '../Authentication/Registration/RegistrationForm';

function Register() {
   
    return (
      // className is home-page to use identical css styling but this is definitely for the registration page
      <div className="content binary-map-background">
        <Grid>
          <GridCell>
            <RegistrationForm />
          </GridCell>
        </Grid>
      </div>
    );
}

export default Register;