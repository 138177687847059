import React from 'react';

function AccessDenied() {
    return (
        <div className="access-denied">
            <br/>
            <h1>Access Denied Go Back</h1>
            <p>Sorry, you do not have access to this page.</p>
            <p>Please contact the EvaluateLocate team if you believe this is an error.</p>
            <br/>
        </div>
    );
}

export default AccessDenied;