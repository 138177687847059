import React from 'react';
//import Grid, { GridCell } from '../DisplaySystem/Grid';

/*import StripePricingTable from '../Billing/StripePricingTable';*/

function AccessOptions() {
     
    return (
      <div className='content binary-map-background'>
        <div className='content-container top-start'>
          <div className="transparent-text-box pricing-info">
            <h1 style = {{marginBottom: "6px"}}>Subscription options</h1>
            <h3>To change your package,&nbsp;
               <a style = {{color: '#00b0bc', textDecoration: 'none', fontWeight: 'bold'}} href = "mailto:Duncan@EvaluateLocate.com?subject=EvaluateLocate subscription upgrade&body=Hi Duncan, I'd like to upgrade my access to EvaluateLocate to the enhanced subscription level of ">contact us</a>
            </h3>
            
            <br/>

            <div style ={{background: "rgba(1, 47, 62, 0.95)", padding: "10px", borderRadius: "8px", border: "1px solid rgba(255, 255, 255, 0.1)"}}>
              <h3 style ={{marginTop: "0px"}}><b>Standard subscription</b></h3>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center' , textAlign: 'center', marginBottom: '7px'}}>
                <h2 style={{marginTop: '0px', marginBottom: '0px', paddingTop: '0px'}}><b>£85</b></h2>
                <p  style={{marginTop: '0px', marginBottom: '0px', paddingTop: '0px'}}>&nbsp; per calendar month</p>
              </div>
              <p>Provides one user with a browsing allowance of <b>1000 tokens</b> per calendar month</p>
              <div className="pricing-subtext">
                <p>(£0.09 per token)</p>
              </div>
            </div>
            
            <br/>
            
            <div style ={{background: "rgba(1, 47, 62, 0.95)", padding: "10px", borderRadius: "8px", border: "1px solid rgba(255, 255, 255, 0.1)"}}>
              <h3 style ={{marginTop: "0px"}}><b>Enhanced subscription</b></h3>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center' , textAlign: 'center', marginBottom: '7px'}}>
                <h2 style={{marginTop: '0px', marginBottom: '0px', paddingTop: '0px'}}><b>£155</b></h2>
                <p>&nbsp; per calendar month</p>
              </div>
              <p>Provides one user with a browsing allowance of <b>2500 tokens</b> per calendar month</p>
              <div className="pricing-subtext">
                <p>(£0.06 per token)</p>
              </div>
            </div>
            
            <br/>
            
            <div style ={{background: "rgba(1, 47, 62, 0.95)", padding: "10px", borderRadius: "8px", border: "1px solid rgba(255, 255, 255, 0.1)"}}>
              <h3 style ={{marginTop: "0px"}}><b>Top-up browsing tokens</b></h3>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center' , textAlign: 'center', marginBottom: '7px'}}>
                <h2 style={{marginTop: '0px', marginBottom: '0px', paddingTop: '0px'}}><b>£30</b></h2>
                <p>&nbsp; one-off payment</p>
              </div>
              <p>Provides the given user with an additional <b>400 tokens</b>. Requires a subscription</p>
              <div className="pricing-subtext">
                <p>(£0.08 per token)</p>
              </div>
            </div>
            
            <br/>
            
            <div style ={{background: "rgba(1, 47, 62, 0.95)", padding: "10px", borderRadius: "8px", border: "1px solid rgba(255, 255, 255, 0.1)"}}>
              <h3 style ={{marginTop: "0px"}}><b>Enterprise (POA)</b></h3>
              <p>Multiple user discounts available</p>
            </div>
            
            <br/>
            <br/>

            <div className="pricing-footer">
              <p><b>Notes on tokens and subscriptions:</b></p>
              <ul>
                <li style = {{marginBottom: "6px"}}>Browsing using the app requires one token per single selection (e.g. charting one metric & location)</li>
                <li style = {{marginBottom: "6px"}}>Exporting reports in pdf format requires 10 tokens</li>
                <li style = {{marginBottom: "6px"}}>Unused tokens ‘roll over’ to the next calendar month, but only while a user retains their subscription</li>
              </ul>
            </div>
            
          </div>
        </div>
        {/*<StripePricingTable />*/}
      </div>
    );
}

export default AccessOptions;