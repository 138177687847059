import { useEffect } from 'react';

function useStyleOnClassAddition() {
    useEffect(() => {

        const applyStylesToParents = (element) => {
            // Assuming element is 'custom-popup', style its relevant parents
            const contentWrapper = element.closest('.leaflet-popup-content-wrapper');
            const popupTip = element.closest('.leaflet-popup-pane').querySelector('.leaflet-popup-tip');
            const closeButton = element.closest('.leaflet-popup-pane').querySelector('.leaflet-popup-close-button');
        
            if (contentWrapper) contentWrapper.style.backgroundColor = '#000515';
            if (popupTip) popupTip.style.backgroundColor = '#000515';
            if (closeButton) {
                closeButton.style.color = '#008B95';
                closeButton.style.fontSize = 'x-large';
            }
        };
        
        const observerCallback = (mutationsList) => {
            for (const mutation of mutationsList) {
                mutation.addedNodes.forEach(node => {
                    // Check if the node is the custom-popup or contains it
                    if (node.nodeType === 1 && (node.classList.contains('custom-popup') || node.querySelector('.custom-popup'))) {
                        const targetElement = node.classList.contains('custom-popup') ? node : node.querySelector('.custom-popup');
                        applyStylesToParents(targetElement);
                    }
                });
            }
        };
        
        const observer = new MutationObserver(observerCallback);
        
        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
        
        return () => observer.disconnect();
    }, []);
}

export default useStyleOnClassAddition;