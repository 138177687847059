// Base URL for API calls to database
export const API_URL = 'https://api.evaluatelocate.com';
// Local host URL for testing of new API versions (comment out above)
// export const API_URL = 'http://localhost:3002';


// Timeout age in ms of data in local storage
export const APP_LOCAL_STORAGE_TIMEOUT_MAX_AGE = 86400000; //Twenty four hours
// List for reversing colour scale for certain strings appearing in the currentDataRef. Currently used in maps.
export const STRINGS_TO_REVERSE_SCALE = ['unemploy', 'inactivity', 'claimant', 'average age', 'retirement age'];

// Days since last log in with which to treat users as new and provide extra guidance
export const NEW_USER_GUIDE_VERSION_CUTOFF_DAYS_SINCE_LAST_LOGIN = 7;