const GridCell = ({ children }) => {
    return (
        <div className="GridCell" data-gridcell="true">
            {children}
        </div>
    );
};

GridCell.displayName = "GridCell";

export default GridCell;