const dropdownStyles = {
    container: (provided, state) => ({
      ...provided,
      width: '100%',
      borderRadius: '7px',
      borderWidth: '2px',
      border: 'none'

    }),
    control: (provided, state) => ({
      ...provided,
      fontSize: 18,
      fontWeight: 'bold',
      color: '#ffffff',
      backgroundColor: state.isFocused ? '#008B95' : 'transparent',
      borderColor: state.isSelected ? '#ffffff' : 'transparent',
      borderOpacity: '50%',
      borderStyle: 'solid',
      opacity: '100%',
      minHeight: '70px',
      minWidth: '100%',
      width: '100%',
      alignItems: 'center', // Aligns selected option in the middle
      justifyContent: 'center', // Ensures dropdown indicator stays on the right
      padding: '0px',
      overflowX: 'hidden',
      overflowY: 'auto',
      flexWrap: 'wrap',
      borderRadius: '7px',
      borderWidth: '2px',
      border: 'none',
      ':hover': {
        borderColor: '#008B95',
        borderWidth: '3px',
        borderStyle: 'solid',
      }
    }),
    option: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#008B95' : '#006b73',
      color: state.isSelected ? '#73C8CE' : '#ffffff',
      background: state.isFocused ? '#008B95' : '#012f3e',
      fontWeight: state.isSelected ? 'bold': 'normal',
      padding: '8px 5px',
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      minWidth: '120px',
      display: 'flex',
      whiteSpace: 'normal', // Allows text to wrap within options
      alignItems: 'center',
      justifyContent: 'flex-start'
    }),
    menu: (provided, state) => ({
      ...provided,
      border: state.isFocused ? '1px solid #ffffff': '#008b958a',
      backgroundColor: state.isFocused ? '#008B95' : 'transparent',
      borderColor: state.isSelected ? '#008B95' : 'transparent',
      marginTop: '0px',
      width: '100%',
      minWidth: '120px',
      alignItems: 'flex-start',
      justifyContent: 'center',
      textAlign: 'center',
      borderRadius: '8px'
    }),
    menuList: (provided, state) => ({
      ...provided,
      fontSize: 16,
      border: state.isFocused ? '1px solid #ccc': '#08b958a',
      maxHeight: '350px',
      minWidth: '120px',
      width: '100%',
      paddingTop: 0,
      paddingBottom: 0,
      alignItems: 'left',
      justifyContent: 'left',
      textAlign: 'left',
      borderRadius: '8px',
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: 'none',
      alignItems: 'left',
      justifyContent: 'left',
      textAlign: 'left'
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      fontSize: 20,
      color: '#ffffff',
      transition: 'opacity 300ms ease',
      alignItems: 'center',
      justifyContent: 'center'
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      color: '#bcbcbc',
      height: '40px',
      width: '40px',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    input: (provided, state) => ({
      ...provided,
      color: '#008B95',
      margin: '0px',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: '#ccc',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#ffffff',
      transition: 'opacity 300ms ease',
      opacity: state.isDisabled ? 0.5 : 1,
      alignItems: 'left',
      justifyContent: 'left',
      marginLeft: '0px',
      marginRight: '0px',
      width: '100%',
      whiteSpace: 'normal', // Allows text to wrap within selected value
    }),
    valueContainer: (provided, state) => ({
    ...provided,
    padding: '0 1px',
    alignItems: 'center',
    justifyContent: 'center',
    ':hover': {
        borderWidth: '1px',
        borderColor: '#ffffff',
      },
    }),
    
    indicatorContainer: (provided, state) => ({
      ...provided,
      height: '0px',
      width: '0px',
      color: '#008B95',
      margin: '0px',
      padding: '0px',
      }),
    indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '40px',
    width: '0px',
    color: '#008B95',
    margin: '0px',
    padding: '0px',
    }),
    indicatorSeparator: (provided, state) => ({
    ...provided,
    marginLeft: '0px',
    backgroundColor: state.isSelected? '#008B95' :'#bcbcbc',
    width: 0,
    }),
    dropdownIndicator: (provided, state) => ({
    ...provided,
    width: '0px',
    color: state.isSelected? '#008B95' :'#bcbcbc',
    ':hover': {
    color: '#008B95'
    },
    }),
    loadingIndicator: (provided, state) => ({
    ...provided,
    color: '#008B95',
    }),
    loadingMessage: (provided, state) => ({
    ...provided,
    color: '#ffffff',
    fontSize: '16px',
    }),
    noOptionsMessage: (provided, state) => ({
    ...provided,
    text: 'No options available, check connection',
    color: '#ffffff',
    fontSize: '16px',
    }),
    group: (provided, state) => ({
    ...provided,
    paddingBottom: '10px',
    paddingTop: '10px',
    ':hover': {
        border: '2px',
        borderColor: '#ffffff',
      },
    }),
    groupHeading: (provided, state) => ({
    ...provided,
    color: '#ffffff',
    fontSize: '18px',
    }),
    clearIndicator: (provided, state) => ({
    ...provided,
    color: '#bcbcbc',
      ':hover': {
        backgroundColor: '#4C0805',
        color: '#ffffff',
        borderColor: '#ffffff',
      },
      ':click': {
        backgroundColor: '#4C0805',
        color: '#ffffff',
      },
    }),
    menuPortal: (provided, state) => ({
    ...provided,
    zIndex: 9999
    }),
};

export default dropdownStyles;