import React, { useRef, useState, useEffect, useMemo } from 'react';
import { Provider, useDispatch } from 'react-redux';
import { store } from '../../redux/store';
import { createRoot } from 'react-dom/client';
import { useSelector, shallowEqual } from 'react-redux';
import { setMapCenter, setMapZoom } from '../../redux/MapPersistence/mapPersistenceSlice';
import { setScale, setLegendColourPercentiles } from '../../redux/MapPersistence/mapScaleSlice';
import { useDebouncedDispatch } from '../../redux/MapPersistence/useDebounceDispatch';
import { scaleLinear } from 'd3-scale';
import * as d3 from 'd3-array';
import { interpolateRgb } from 'd3-interpolate';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-control-geocoder';
import customIconUrl from '../../Assets/EL_Logo_RGB_CIRCLESONLYversion_TRANSPARENT.png';
import LoadingWheel from '../../Assets/LoadingSystem/LoadingWheel';
import { STRINGS_TO_REVERSE_SCALE } from '../../config';
import { selectMapData } from '../../redux/Selectors/MapsPageSelectors';
import MapLegend from './MapLegend';
import MapSearch from './MapSearch';
import SingleDateSlider from './SliderSingle';
import { addUniqueNewAvailableDataMenuAreas } from '../../redux/DataRequestSelections/locationsSlice';
import { updateAddressSpecificTitle } from '../../redux/DataMenuSelections/reportsSlice';
import MarkerPopup from './MapMarkerPopup';
import MapAreaPopup from './MapAreaPopup';
import useStyleOnClassAddition from './useStyleOnClassAddition.js';
import { useNavigate } from 'react-router-dom';
import { parseYYYYMM, formatDate } from '../../RequestDataSystem/DataDateFormattingFunctions';
import {determineStatPrefix, determineStatSuffix} from '../../RequestDataSystem/DataMetricFormatFunctions';
import { setLoading } from '../../redux/AppearancePersistence/appearancePersistenceSlice';


/// SUPPORTING FUNCTIONS AND SETTINGS

// Override the default icon for all Leaflet markers
L.Icon.Default.mergeOptions({
  iconUrl: customIconUrl,
  iconRetinaUrl: null,
  shadowUrl: null,
  iconSize: [30, 30], // size of the icon
  iconAnchor: [15, 15], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -15] // point from which the popup should open relative to the iconAnchor
});

// Supporting rounding functions
const roundToNearestPercent = (value, xPercentOfRange) => Math.round(value / xPercentOfRange) * xPercentOfRange;
const roundToTwoSignificantFigures = (num) => {
  if (num === 0) return 0; // Handle 0 explicitly
  const d = Math.ceil(Math.log10(Math.abs(num)));
  const power = 2 - d;
  const magnitude = Math.pow(10, power);
  const shifted = Math.round(num * magnitude);
  return shifted / magnitude;
};
const roundToThreeSignificantFigures = (num) => {
  if (num === 0) return 0; // Directly handle the zero case.
  // Calculate the number of digits before the decimal point
  const digitsBeforeDecimal = Math.floor(Math.log10(Math.abs(num))) + 1;
  // Calculate the power to scale the number to around two significant digits
  const power = 3 - digitsBeforeDecimal;
  // Scale the number, round it, and then scale back
  const scaledNum = Math.round(num * Math.pow(10, power));
  const roundedNum = scaledNum / Math.pow(10, power);
  // Return the number ensuring it maintains up to two significant digits
  return parseFloat(roundedNum.toPrecision(3));
};
// Utility function to check if two arrays are equal
function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;
  // Compare each element
  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

// Map tile layer URL (set as constant for now)
const tileLayerURL='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png';







// BEGIN MAIN FUNCTIONAL COMPONENT
function MapWindow({  recalculateScale, setRecalcScale }) {
  // Use the custom hook to apply styles
  useStyleOnClassAddition();

  //Updates render uniqueKey on component mount
  const [uniqueKey, setUniqueKey] = useState(Date.now());
  useEffect(() => {
    // This will generate a new key every time the component mounts, forcing a re-render of MapLegend later, whenever MapWindow is mounted
    setUniqueKey(Date.now());
  }, []); // Empty dependency array ensures this runs only on mount

  // Local state setup (further declared in sections below)
  const mapRef = useRef(null);
  const [mapInstance, setMapInstance] = useState(null);

  // Direct Redux data fetching without using createSelector for simple values
  const reduxPercentiles = useSelector(state => state.mapScale.percentiles, shallowEqual);
  const reduxColours = useSelector(state => state.mapScale.colours, shallowEqual);
  const gradientFractions = useSelector((state) => state.mapScale.legendPercentiles, shallowEqual);

  const dataGeoType = useSelector(state => state.maps.selectedGeographyType);
  const currentDataRef = useSelector(state => state.maps.currentDataRef);

  const initialCenter = useSelector(state => state.mapPersistence.center, shallowEqual);
  const initialZoom = useSelector(state => state.mapPersistence.zoom, shallowEqual);
  // Map search centre and zoom are separate to the initial centre and zoom as they can be changed within a render
  const [mapSearchCenter, setMapSearchCenter] = useState(initialCenter);
  const [mapSearchZoom, setMapSearchZoom] = useState(initialZoom);
  const [markerActiveSetting, setMarkerActiveSetting] = useState(false);
  const [newMarkerLatLon, setNewMarkerLatLon] = useState({ lat: null, lon: null });
  const [newMarkerFormattedAddress, setNewMarkerFormattedAddress] = useState(null);

  // Recent search locations of interest to include as markers, coming with supporting properties within the object
  const recentLocations = useSelector(state => state.locations.recentLocations);

  // Use createSelector for complex data fetching
  const inputData = useSelector((state) => selectMapData(state, currentDataRef), shallowEqual);

  // Data information for labels and popups
  let mapDataRawDate = useSelector(state => state.maps.selectedDate);
  let mapDataDate = null;
  try {
    mapDataDate = formatDate(parseYYYYMM(mapDataRawDate), 'monthYear');
  } catch (error) {
    mapDataDate = null;
  }
  const mapDataMetric = useSelector(state => state.maps.selectedMetric);

  // On change to metric type, set the recalculating of the scale to true (passes up to UI too)
  useEffect(() => {
    setRecalcScale(true);
  }, [mapDataMetric, setRecalcScale]);

  // Route navigation passed down to popups
  const navigate = useNavigate();
  const handleNavigate = (url) => {
    navigate(url);
  };

  
  // ColourScaleParams based on percentiles of any data content
  const colourSettings = useMemo(() => ({
    absoluteValues: false,
    values: [],
    percentiles: [0, 0.05, 0.15, 0.5, 0.85, 0.95, 1],
    colours: ['#602424', '#B43030', '#D30000', '#FFB400', '#18AF15', '#0B7100', '#006444'],
  }), []);
  // ColourScaleParams is memoised so that it is only created once and with zero dependencies only once on initial page load

  // Logic for reversing scale for certain strings appearing in the currentDataRef
  const reverseScaleSetting = useMemo(() => {
    // Check if currentDataRef includes any of the strings that require reversing the scale
    if (typeof currentDataRef === 'string') {
      const stringsToReverseScale = STRINGS_TO_REVERSE_SCALE;
      const lowerCaseDataRef = currentDataRef.toLowerCase();
      return stringsToReverseScale.some(str => lowerCaseDataRef.includes(str));
    }
  }, [currentDataRef]);
  
  // Create debounced versions of dispatch actions. Times in ms
  // These times determine how long a user needs to leave the map at a certain position and zoom level in order...
  // ...for that position and zoom level to be remembered on *returning to the map page* (not just changing settings)
  const debouncedSetMapCenter = useDebouncedDispatch(setMapCenter, 2000);
  const debouncedSetMapZoom   = useDebouncedDispatch(setMapZoom, 2000);
  const debouncedSetScale = useDebouncedDispatch(setScale, 10);
  const debouncedSetLegendColourPercentiles = useDebouncedDispatch(setLegendColourPercentiles, 10);
  const debouncedAddAdditionalNearbyLocations = useDebouncedDispatch(addUniqueNewAvailableDataMenuAreas, 10);
  const debouncedAddSpecificReportTitle = useDebouncedDispatch(updateAddressSpecificTitle, 10);
  

  


  /// FUNCTIONAL USEFFECTS TO UPDATE STATE AS REQUIRED

  // Set position and zoom level whenever mapSearchCenter and mapSearchZoom change (not initial center and zoom are separate)
  useEffect(() => {
    if (mapInstance) {
      mapInstance.setView(mapSearchCenter, mapSearchZoom, { animate: true });
    }
  }, [mapInstance, mapSearchCenter, mapSearchZoom]);

  // When data geo type changes, get correct GEOjson file
  const [MapGeo, setMapGeo] = useState(null);
  useEffect(() => {
    // Only proceed if a specific Geo type is selected
    if (dataGeoType === 'Postal Districts') {
      const abortController = new AbortController();
      const { signal } = abortController;
      fetch('/PostalDistricts.json', { signal })
          .then(response => {
              if (!response.ok) {
                  throw new Error('Network response was not ok fetching GEOjson data');
              }
              return response.json();
          })
          .then(geojsonData => {
              setMapGeo(geojsonData);
          })
          .catch(error => {
              if (error.name !== 'AbortError') {
                  console.error("Fetch error:", error);
              }
          });
      // Cleanup function to abort fetch on component unmount
      return () => abortController.abort();
    }

    // Same procedure for Regions
    if (dataGeoType === 'Regions') {
      const abortController = new AbortController();
      const { signal } = abortController;
      fetch('/Regions.json', { signal })
          .then(response => {
              if (!response.ok) {
                  throw new Error('Network response was not ok fetching GEOjson data');
              }
              return response.json();
          })
          .then(geojsonData => {
              setMapGeo(geojsonData);
          })
          .catch(error => {
              if (error.name !== 'AbortError') {
                  console.error("Fetch error:", error);
              }
          });
      // Cleanup function to abort fetch on component unmount
      return () => abortController.abort();
    }

    // Same procedure for Regions
    if (dataGeoType === 'LocalAuthorities') {
      const abortController = new AbortController();
      const { signal } = abortController;
      fetch('/LocalAuthorityDistricts.json', { signal })
          .then(response => {
              if (!response.ok) {
                  throw new Error('Network response was not ok fetching GEOjson data');
              }
              return response.json();
          })
          .then(geojsonData => {
              setMapGeo(geojsonData);
          })
          .catch(error => {
              if (error.name !== 'AbortError') {
                  console.error("Fetch error:", error);
              }
          });
      // Cleanup function to abort fetch on component unmount
      return () => abortController.abort();
    }
  //
  }, [dataGeoType]);


  // Create an actual colour scheme based on settings, when necessary updating this
  const colourScheme = useMemo(() => {
    // Determine if we should use redux state or calculate a new colour scheme
    if (!recalculateScale) {
      // Use redux state directly
      return scaleLinear().domain(reduxPercentiles).range(reduxColours).interpolate(interpolateRgb);
    } else if (inputData && inputData.values && colourSettings) {
      
      // Calculate a new colour scheme based on input data and colour settings
      const sortedData = [...inputData.values].sort((a, b) => a - b);

      /// This section adapts the given percentiles, overuling the strict logic as passed
      ///  but to ensure that any negative numbers and the zero point are treated appropriately
      let finalPercentiles = [];
      // ***Define zeroElementRef*** as the reference of the middle of the standard colour range (i.e. usually amber)
      const zeroElementRef = Math.round(0.5 * colourSettings.percentiles.length);
      if (sortedData[0] < 0) {
        // Now count the number of colours in the colours array before and after zeroElementRef
        const negativeLevelsCount = zeroElementRef -1; // (The minus one is to not count zeroElementRef itself)
        const positiveLevelsCount = colourSettings.colours.length - zeroElementRef; 

        // Calculate **percentileatZero** at which the given metric values switch from negative to positive when in order
        const countNegative = sortedData.filter(value => value < 0).length;
        const percentileAtZero = countNegative / sortedData.length;
        // Calculate new percentiles for negative values
        const negativeLevels = Array.from({ length: negativeLevelsCount }, (_, i) => 
          (i / negativeLevelsCount) * percentileAtZero
        );
        // Calculate new percentiles for positive values
        const positiveLevels = Array.from({ length: positiveLevelsCount }, (_, i) => 
          percentileAtZero + ((i + 1) / (positiveLevelsCount + 1)) * (1 - percentileAtZero)
        );
        // Combine the new percentiles, including zero and one explicitly to be sure
        const negativesAdaptedPercentiles = [0,...negativeLevels.slice(1), percentileAtZero, ...positiveLevels.slice(0, -1), 1];
        finalPercentiles = negativesAdaptedPercentiles
      } else {
        finalPercentiles = colourSettings.percentiles
      }
      debouncedSetLegendColourPercentiles({legendPercentiles: finalPercentiles});

      // Now calculate a list of values, domain, which maps to the suitable final percentiles and colours, rounding this suitably
      const domain = finalPercentiles.map(percentile => d3.quantile(sortedData, percentile));
      const domainRounded = domain.map(num => roundToTwoSignificantFigures(num));

      // Determine colourRange, check if reverseScaleSetting is true
      let colourRange = colourSettings.colours;
      colourRange = reverseScaleSetting ? [...colourRange].reverse() : colourRange;

      // Extend colour scale alongside domain by large numbers in each direction
      const domainExtended = [-999999999, ...domainRounded, +999999999]
      const colourRangeExtended = [colourRange[0], ...colourRange, colourRange[colourRange.length -1]];
      // This deals with over and under extremes in future comparisons if colour scale is subsequently fixed
      
      //Return scale function using extended versions
      return scaleLinear().domain(domainExtended).range(colourRangeExtended).interpolate(interpolateRgb);
    }
  }, [inputData, recalculateScale, reverseScaleSetting, colourSettings, reduxPercentiles, reduxColours, debouncedSetLegendColourPercentiles]);

  // Dispat new scale settings to the Redux store when colourScheme changes
  useEffect(() => {
    // Only dispatch if recalculateScale is true to prevent unnecessary updates
    if (recalculateScale && inputData && inputData.values) {
      const domain = colourScheme.domain();
      const colours = colourScheme.range();
      // Check if the current redux state differs from the calculated one to avoid unnecessary dispatches
      if (!arraysEqual(domain, reduxPercentiles) || !arraysEqual(colours, reduxColours)) {
        debouncedSetScale({ percentiles: domain, colours });
      }
    }
  }, [recalculateScale, colourScheme, inputData, debouncedSetScale, reduxPercentiles, reduxColours]);

  // If inputData changes and has values and labels available, perform the transformation for the correct map format
  const MapDataTransformed = useMemo(() => {
    if (!inputData || !inputData.values || !inputData.labels) return [];
    return inputData.labels.LocationLabels.map((location, index) => [location, inputData.values[index]]);
  }, [inputData]);


  // MapWindow Loading Conditions supporting state
  const [geoJsonLayerAdded, setGeoJsonLayerAdded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isFadingOut, setIsFadingOut] = useState(false);
  // Set universal loading state to false once all actions are complete
  const dispatch = useDispatch();
  useEffect (() => {
    if (!isLoading && !isFadingOut) {
      dispatch(setLoading(false));
    }
  }, [isLoading, isFadingOut, geoJsonLayerAdded, MapDataTransformed, dispatch]);
  





  // MapWindow Loading Conditions (also includes fade-out logic)
  useEffect(() => {
    // If geoJsonLayerAdded is true and isLoading is still true, start the fade-out process.
    if (geoJsonLayerAdded && MapDataTransformed && isLoading) {
      setIsFadingOut(true); // Begin fade-out
      const timeoutId = setTimeout(() => {
        setIsLoading(false); // After the fade-out animation, hide the overlay
        setIsFadingOut(false); // Reset fading out state
      }, 700); // Duration should match fade-out animation duration
      return () => clearTimeout(timeoutId);
    }
    if (!geoJsonLayerAdded || !MapDataTransformed) {
      setIsLoading(true);
    }
  }, [geoJsonLayerAdded, MapDataTransformed, isLoading]);



 

  // FIRST MAJOR USEEFFECT for the actual map creation
  useEffect(() => {
    if (!mapRef.current) return; // Guard clause to ensure ref exists

    // Initial setup of map instance
    const initialMapInstance = L.map(mapRef.current, {
      renderer: L.canvas(), // Use Canvas renderer for better performance on large datasets
      zoomSnap: 0.1
    }).setView(initialCenter, initialZoom);

    // Adjust the renderer's padding to render GeoJSON layers outside the viewport
    initialMapInstance.getRenderer(initialMapInstance).options.padding = 1; // Adjust this value as needed
    setMapInstance(initialMapInstance);

    // Define event handlers
    const handleZoomEnd = () => {
      const newZoom = initialMapInstance.getZoom();
      const newCenter = initialMapInstance.getCenter();
      const newCenterObj = { lat: newCenter.lat, lng: newCenter.lng };
      debouncedSetMapCenter(newCenterObj);
      debouncedSetMapZoom(newZoom);
    };
    const handleMoveEnd = () => {
      const newZoom = initialMapInstance.getZoom();
      const newCenter = initialMapInstance.getCenter();
      const newCenterObj = { lat: newCenter.lat, lng: newCenter.lng };
      debouncedSetMapCenter(newCenterObj);
      debouncedSetMapZoom(newZoom);
    };
    // Attach event listeners
    initialMapInstance.on('zoomend', handleZoomEnd);
    initialMapInstance.on('moveend', handleMoveEnd);

    return () => {
      // Cleanup function to remove the map instance and event listeners
      initialMapInstance.off('zoomend', handleZoomEnd);
      initialMapInstance.off('moveend', handleMoveEnd);
      initialMapInstance.remove();
      setMapInstance(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array means this only loads once on component mount



  // SECOND MAJOR USEEFFECT for adding the tile layer to the map instance
  useEffect(() => {
    if (!mapInstance) return;

    // Set tile layer
    let tileLayer = L.tileLayer(tileLayerURL, {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
      subdomains: 'abcd',
      maxZoom: 18,
      locationbar: true,
    }).addTo(mapInstance);

    // Clean up tile layer
    return () => {
      tileLayer.remove();
    }
    // Focused dependency array means this only loads once on component mount or when recentLocations changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapInstance, tileLayerURL]);
  



  // THIRD MAJOR USEEFFECT for adding the map markers
  // Define persistent map to track markers
  const markersMap = new Map();
  // UseEffect itself
  useEffect(() => {
    if (!mapInstance || !recentLocations || !recentLocations.length ) return;
    // Add or update markers
    recentLocations.forEach(location => {
      const { lat, lng } = location.geometry.location;
      if (!markersMap.has(location.place_id)) {
        const marker = L.marker([lat, lng], {
          icon: L.icon({
            iconUrl: customIconUrl,
            iconSize: [30, 30],
            iconAnchor: [15, 15],
            popupAnchor: [0, -15]
          })
        }).addTo(mapInstance);

        // Initialize popup content with React component
        const popupContent = document.createElement('div');
        const root = createRoot(popupContent); // Creates a root.
        root.render(
          <Provider store={store}>
            <MarkerPopup
              location={location}
              onNavigate={handleNavigate} />
          </Provider>
        );
        marker.bindPopup(popupContent,{ autoPan: false });

        // Add event listener for styling the popup
        marker.getPopup().on('add', () => {
          const popupElement = marker.getPopup().getElement();
          if (popupElement) {
            popupElement.querySelector('.leaflet-popup-content-wrapper').style.backgroundColor = '#000515';
            popupElement.querySelector('.leaflet-popup-tip').style.backgroundColor = '#000515';
            popupElement.querySelector('.leaflet-popup-close-button').style.color = '#008B95';
            popupElement.querySelector('.leaflet-popup-close-button').style.fontSize = 'x-large';
          }
          
        });
        markersMap.set(location.place_id, marker);
        // Ensure pop up opens, with a delay to ensure it is ready
        if (markerActiveSetting) {
          setTimeout(() => marker.openPopup(), 500);
        }
      }
    });
    // Remove markers no longer in recentLocations
    markersMap.forEach((marker, place_id) => {
      if (!recentLocations.some(loc => loc.place_id === place_id)) {
        mapInstance.removeLayer(marker);
        markersMap.delete(place_id);
      }
    });

    return () => {
      // Clean up markers
      markersMap.forEach(marker => {
        mapInstance.removeLayer(marker);
      });
    }
    // Focused dependency array means this only loads once on component mount or when recentLocations changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapInstance, recentLocations]);



  // FOURTH MAJOR USEFFECT for adding additional layers to the map instance
  useEffect(() => {
    if (mapDataDate === null || !mapInstance || !mapRef.current || !MapDataTransformed.length || !MapGeo) return;

    // Check and remove the existing GeoJSON layer if it exists
    // Assuming mapInstance holds a reference to the existing geoJsonLayer if it's been added previously
    if (mapInstance.geoJsonLayer) {
      mapInstance.removeLayer(mapInstance.geoJsonLayer);
    }
    // Process data
    const dataMap = new Map(MapDataTransformed);

    // Add GeoJSON layer if data is available, with attached LocationClick function
    const geoJsonLayer = L.geoJSON(MapGeo, {
      style: function(feature) {
        const locationName = feature.properties.name;
        const value = MapDataTransformed.find(([name]) => name === locationName)?.[1];
        return {
          fillColor: colourScheme(value), // Applies colour based on value
          fillOpacity: 0.6,
          weight: 0.65,
          opacity: 0.5,
          color: '#3f3f3f' 
          };
      },
      onEachFeature: function(feature, layer) {
        layer.on({
            click: function(e) {
                const locationName = e.target.feature.properties.name;
                const value = roundToThreeSignificantFigures(dataMap.get(locationName));
                const formattedValue = `${determineStatPrefix(mapDataMetric)}${value.toLocaleString()}${determineStatSuffix(mapDataMetric)}`;
                const metric = mapDataMetric;
                const date = mapDataDate;
    
                const popupContent = document.createElement('div');
                popupContent.className = 'custom-popup';
                const root = createRoot(popupContent);
                root.render(
                  <Provider store={store}>
                    <MapAreaPopup
                      locationName={locationName}
                      value={formattedValue}
                      metric={metric}
                      date={date}
                      onNavigate={handleNavigate}
                    />
                  </Provider>
                );
    
                const popupOptions = {
                    minWidth: 200,
                    autoClose: true,
                    closeOnClick: false
                };
    
                // Bind and open popup
                const popup = e.target.bindPopup(popupContent, popupOptions).openPopup();
                e.target._root = root;
    
                // Apply styles with no delay
                const popupElement = popup.getElement();
                if (popupElement) {
                    const contentWrapper = popupElement.querySelector('.leaflet-popup-content-wrapper');
                    const popupTip = popupElement.querySelector('.leaflet-popup-tip');
                    const closeButton = popupElement.querySelector('.leaflet-popup-close-button');

                    if (contentWrapper) contentWrapper.style.backgroundColor = '#000515';
                    if (popupTip) popupTip.style.backgroundColor = '#000515';
                    if (closeButton) {
                        closeButton.style.color = '#008B95';
                        closeButton.style.fontSize = 'x-large';
                    }
                }
                
            }
        });
    
        layer.on('popupclose', function(e) {
            if (e.target._root) {
                e.target._root.unmount();  // Unmount the React component
                e.target._root = null; // Clean up the reference
            }
        });
    }
    }).addTo(mapInstance);

    // Store the newly added geoJsonLayer for future reference
    mapInstance.geoJsonLayer = geoJsonLayer;
    setGeoJsonLayerAdded(!!geoJsonLayer);
    return () => {
      // Clean up the GeoJSON layer
      if (mapInstance.geoJsonLayer) {
        mapInstance.removeLayer(mapInstance.geoJsonLayer);
        mapInstance.geoJsonLayer = null; // Reset the reference
      }
    };
    // Focused dependency array avoids repeat render attempts when dependencies change which are precursors or co-changers to the listed dependencies below
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapInstance, MapDataTransformed,  MapGeo, colourScheme]);




  // Function to calculate the Euclidean distance between two points
  const calculateSimpleDistance = (point1, point2) => {
    return Math.sqrt((point2.lng - point1.lng) ** 2 + (point2.lat - point1.lat) ** 2);
  };
  
  // Finding nearest GeoJSON areas using a simple distance formula
  const findNearestFeaturesFromLayer = (layer, lat, lon, count) => {
    const distances = [];
  
    layer.eachLayer((leafletLayer) => {
      const layerPoint = leafletLayer.getLatLng ? leafletLayer.getLatLng() : leafletLayer.getBounds().getCenter();
      const distance = calculateSimpleDistance({ lat, lng: lon }, layerPoint);
      distances.push({ name: leafletLayer.feature.properties.name, distance });
    });
  
    distances.sort((a, b) => a.distance - b.distance);
    return distances.slice(0, count).map(entry => entry.name);
  };



  // FIFTH MAJOR USEEFFECT for finding nearby geojson areas to any new search results
  useEffect(() => {

    if (newMarkerLatLon.lat && newMarkerLatLon.lon && MapGeo) {
      debouncedAddSpecificReportTitle(newMarkerFormattedAddress);

      const nNearest = 3;
      const nearestAreas = findNearestFeaturesFromLayer(mapInstance.geoJsonLayer, newMarkerLatLon.lat, newMarkerLatLon.lon, nNearest);

 
      // Dispatch action to add these areas to the state
      if (newMarkerFormattedAddress && nearestAreas.length) {
        debouncedAddAdditionalNearbyLocations({
          formatted_address: newMarkerFormattedAddress,
          newAreas: nearestAreas
        });
      }
    }
  // Purposefully selective triggers for useEffect. Only run if newMarkerLatLon changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMarkerLatLon.lat, newMarkerLatLon.lon])
 


  // MAP LEGEND CALCULATIONS
  // Calculate legendGradesRounded considering the reversal of grades if needed
  const legendData = useMemo(() => {
    // Initial check for valid inputs
    const isValidColours = Array.isArray(reduxColours) && reduxColours.length > 0;
    const isValidFractions = Array.isArray(gradientFractions) && gradientFractions.every(f => typeof f === 'number' && !isNaN(f));
    
    if (!isValidColours || !isValidFractions) {
      console.error("Invalid inputs for gradient calculation");
      return {
        gradientBackground: 'linear-gradient(to top, #ffffff 0%, #000000 100%)', // Default gradient in case of error
        legendGradesRounded: []
      };
    }
  
    // If inputs are valid, calculate gradientBackground and legendGradesRounded
    let adjustedLegendColours = [...reduxColours].slice(1, -1); // Always slice first and last elements
    const gradientBackground = `linear-gradient(to top, ${adjustedLegendColours.map((colour, index) => `${colour} ${(gradientFractions[index] * 100).toFixed(2)}%`).join(', ')})`;
  
    const legendGradesTopDown = [...reduxPercentiles].slice(2, -2).reverse();
    const range = Math.max(...legendGradesTopDown) - Math.min(...legendGradesTopDown);
    const xPercentOfRange = range / 100;
    const legendGradesRounded = legendGradesTopDown.map(grade => roundToTwoSignificantFigures(roundToNearestPercent(grade, xPercentOfRange)));
  
    return { gradientBackground, legendGradesRounded };
  }, [reduxColours, gradientFractions, reduxPercentiles]);


  // Import universal state to double check if in exporting mode
  const isExporting = useSelector(state => state.appearancePersistence.exportingState);

 
  // FINAL RETURN STATEMENT
  return (
  <div className='content-container'>
    {(isLoading || isFadingOut) && <div className={`loading-overlay ${isFadingOut ? 'fadeOut' : ''}`}><LoadingWheel /></div>}
    <div ref={mapRef} id="map" className="map" style={{ height: '100%', width: '100%' }}></div>
    {(!isLoading && !isFadingOut && !isExporting) &&<MapSearch
      setMapSearchCenter={setMapSearchCenter}
      setMapSearchZoom={setMapSearchZoom}
      setMarkerActiveSetting={setMarkerActiveSetting}
      setNewMarkerLatLon={setNewMarkerLatLon}
      setNewMarkerFormattedAddress={setNewMarkerFormattedAddress} />}
    {(!isLoading && !isFadingOut) && <MapLegend key={uniqueKey} legendData={legendData} metricName={mapDataMetric} recalculateScale={recalculateScale} setRecalcScale={setRecalcScale} />}
    {(!isLoading && !isFadingOut && !isExporting) && <SingleDateSlider/>}
  </div>
  );
}
      
export default React.memo(MapWindow);