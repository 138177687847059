import React from 'react';
import { Slider, Rail, Handles, Tracks } from 'react-compound-slider';

// Define styles for the slider components in an object
const sliderStyle = {
  position: 'relative',
  width: '100%'
};

const DateRangeSlider = ({ domain, values, onChange }) => (
  <div className="range-slider" >
    <Slider
      mode={2}
      step={1}
      domain={domain}
      rootStyle={sliderStyle}
      values={values}
      onUpdate={onChange} // Handles both update and change with one prop
    >
      <Rail>
        {({ getRailProps }) => (
          <div {...getRailProps()} className="slider-rail" />
        )}
      </Rail>
      <Handles>
        {({ handles, getHandleProps }) => (
          <div className="slider-handles">
            {handles.map(handle => (
              <div
                key={handle.id}
                style={{ left: `${handle.percent}%` }}
                className="slider-handle"
                {...getHandleProps(handle.id)}
              />
            ))}
          </div>
        )}
      </Handles>
      <Tracks left={false} right={false}>
        {({ tracks, getTrackProps }) => (
          <div className="slider-tracks">
            {tracks.map(({ id, source, target }) => (
              <div
                key={id}
                style={{ left: `${source.percent}%`, width: `${target.percent - source.percent}%` }}
                className="slider-track"
                {...getTrackProps()}
              />
            ))}
          </div>
        )}
      </Tracks>
    </Slider>
  </div>
);

export default DateRangeSlider;