import React, { createContext, useState, useEffect } from 'react';

// Create the context
export const InstallPromptContext = createContext(null);

// Provider component
export const InstallPromptProvider = ({ children }) => {
    const [deferredPrompt, setDeferredPrompt] = useState(null);

    useEffect(() => {
        const handleBeforeInstallPrompt = (e) => {
            console.log('Captured beforeinstallprompt at top level');
            e.preventDefault(); // To prevent the immediate popup
            setDeferredPrompt(e);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    return (
        <InstallPromptContext.Provider value={deferredPrompt}>
            {children}
        </InstallPromptContext.Provider>
    );
};