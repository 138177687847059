import React from 'react';
import '../../DisplaySystem/Components.css';
import {determineStatPrefix, determineStatSuffix} from '../../RequestDataSystem/DataMetricFormatFunctions';

// Supporting functions
function roundToFourSigFigs(num) {
  if (num === 0) return 0;  // Immediately return 0 to handle zero input case

  const digits = Math.ceil(Math.log10(Math.abs(num)));
  const power = 4 - digits;
  const multiplier = Math.pow(10, power);
  return Math.round(num * multiplier) / multiplier;
}


function MapLegend({ legendData, metricName, recalculateScale, setRecalcScale }) {

  /*const handleMapScaleFixToggle = () => {
    setRecalcScale(!recalculateScale);
  };*/

  // Inline styling for map-legend-colour-bar to ensure no CSS lookup delay in lifecycle
  return (
    <div className='map-legend'>
      
      <div className='map-legend-colour-bar' style={{ background: legendData.gradientBackground, width: '20px', height: '200px', marginRight: '7px' }} ></div>
      <div className='legend-key'>
        {legendData.legendGradesRounded.map((grade, index, array) => {
          // Determine text for each grade
          let gradeText = `${determineStatPrefix(metricName)}${roundToFourSigFigs(grade).toLocaleString()}${determineStatSuffix(metricName)}`;
          if (index === 0) {
            gradeText += '+'; // Add "+" for the first item
          } else if (index === array.length - 1) {
            gradeText = '< ' + gradeText; // Add "<" for the last item
          }
          return <span key={index}>{gradeText}</span>;
        })}
      </div>
      {/*<button className='form-button' onClick={handleMapScaleFixToggle}>
        {recalculateScale? 'Fix' : 'Unfix scale'}
      </button>*/}
    </div>
  );
}
export default MapLegend;