import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedMetric: 'Economic Momentum (EVI % change)',
  selectedMetricDescription: 'Economic Momentum expresses the percentage change in the local EVI rating during the past 12 months. A positive percentage change indicates an improving economic outlook while a negative score reflects a weakening situation. The EVI takes into account more than 100 metrics and is approximately equally weighted across the three headline data pillars around household prosperity, business activity and the labour market.',
  selectedLocations: ['United Kingdom'],
  currentDataRefs: [],
};

const chartsSlice = createSlice({
  name: 'charts',
  initialState,
  reducers: {
    updateChartMetric: (state, action) => {
      state.selectedMetric = action.payload;
    },
    updateChartMetricDescription: (state, action) => {
      state.selectedMetricDescription = action.payload;
    },
    updateChartLocations: (state, action) => {
      state.selectedLocations = action.payload;
    },
    updateChartDataRefs: (state, action) => {
      // This will expect action.payload to be an array of refs
      state.currentDataRefs = action.payload;
    },
    appendChartDataRef: (state, action) => {
      // This option appends a new reference without duplicating existing ones
      if (!state.currentDataRefs.includes(action.payload)) {
        state.currentDataRefs.push(action.payload);
      }
    }
  },
});

export const { updateChartMetric, updateChartMetricDescription, updateChartLocations, updateChartDataRefs, appendChartDataRef } = chartsSlice.actions;
export default chartsSlice.reducer;