import {React} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Select from 'react-select';
import plusButtonDropdownStyles from '../../DisplaySystem/DropdownsStyles/KeyStatPlusCustomStyles';
import { appendReportMetric } from '../../redux/DataMenuSelections/reportsSlice';

// Main return function
function KeyStatWindowAddition() {

    // Import available metrics
    const availableMetrics = useSelector(state => state.dataMenu.availableMetrics);

    // Redux dispatch
    const dispatch = useDispatch();
    // Create handler for metric selection, dispatching this to redux at the correct index value in the selected metrcics array
    const handleKeyStatMetricAddition = selectedOption => {
        dispatch(appendReportMetric({ newMetric: selectedOption.value })); // Change to newMetric
    };

    // Import universal setting for display (or not in this case) during report export process
    const optimizedForExport = useSelector(state => state.appearancePersistence.optimizedForExport);



    
    // Return statement
    return (
        <div className="content-container">
            {!optimizedForExport && <div className="comparator comparator-plus">
                <div className="comparator-primary-and-metric-wrapper"
                    style={{height: '100%', width: '100%', margin: '0px', backgroundColor: '#012F3E', border: 'none', justifyContent: 'center', alignItems: 'center'}}>
                    <Select
                        options={availableMetrics.map(metric => ({ value: metric.Metric, label: metric.Metric }))}
                        onChange={handleKeyStatMetricAddition}
                        value={null}
                        styles={plusButtonDropdownStyles}
                        placeholder='+'
                        menuPortalTarget={document.body}
                    />
                </div>
            </div>}
        </div>
    );
}

export default KeyStatWindowAddition;