import { createContext, useContext, useCallback } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {store} from '../redux/store';
import { setExportOptimization, setExportingState } from '../redux/AppearancePersistence/appearancePersistenceSlice';
import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { setLoading } from '../redux/AppearancePersistence/appearancePersistenceSlice';
import { useDispatch } from 'react-redux';
import { notifyDataExport } from './NotifyDataExport';

const ExportPDFContext = createContext(null);

export const useExportPDF = () => useContext(ExportPDFContext);



export const ExportProvider = ({ children, contentRef }) => {
    // Store all original styles to be sure to reinstate later
    const originalStylesRef = useRef("");

    // Local state here to track when pdf export is in progress
    const [isExporting, setIsExporting] = useState(false);

    // Local state here to track pdf export type and file name
    const [inUseExportType, setInUseExportType] = useState('default');
    const [inUseFileName, setInUseFileName] = useState('EvaluateLocate Exported Report.pdf');

    
 
    // Entry point of external logic. This exportToPDF function is available to all components
    const exportToPDF = useCallback(async ({ exportType, fileName }) => {
        // Take export type from props where called by the context receiving component
        if (exportType) {
            setInUseExportType(exportType);
        }

        // Set filename: if provided, use it; otherwise, use a filename based on the export type
        if (fileName) {
            setInUseFileName(fileName);
        } else {
            // Auto-generate the filename based on the export type
            const state = store.getState();
            let fileNameInternal;

            switch (exportType) {
                case 'map':
                    fileNameInternal = state.appearancePersistence.mapPdfFileName;
                    break;
                case 'chart':
                    fileNameInternal = state.appearancePersistence.chartPdfFileName;
                    break;
                case 'report':
                    fileNameInternal = state.appearancePersistence.reportPdfFileName;
                    break;
                default:
                    fileNameInternal = state.appearancePersistence.defaultPdfFileName;
            }

            setInUseFileName(fileNameInternal);
        }

        // Immediately start checking credits and setting up for export
        try {
            const { success, message } = await notifyDataExport();
            if (success) {
                store.dispatch(setExportOptimization(true)); // Request visual optimisation for export
                setTimeout(() => { setIsExporting(true) }, 2500); // Set the flag to start the export process after slight additional delay
            } else {
                console.error(`Failed to export: ${message}`); // Provide feedback on failure
            }
        } catch (error) {
            console.error('Credit balance check failed:', error);
            alert('Failed to perform credit balance check. Please try again.');
        }
    }, []);

    
    // Get universal pdf export optimisation state
    const optimizedForExport = useSelector(state => state.appearancePersistence.optimizedForExport);

    // Sync universal state for when export is in progress or preparing
    useEffect(() => {
        if (isExporting || optimizedForExport) {
            store.dispatch(setExportingState(true));
        } else {
            store.dispatch(setExportingState(false));
        }
    }, [isExporting, optimizedForExport]);
    
    // Get universal loading state and ready to reset as needed
    const universalLoading = useSelector(state => state.appearancePersistence.isLoading);
    const dispatch = useDispatch();

    

    // Track universal state changes for optimised export settings
    useEffect(() => {
        // Apply temporary styles and settings when optimizedForExport becomes true
        if (optimizedForExport) {
            // Applying PDF export styles
            if (!originalStylesRef.current) {
                // Ensure original styles are captured only once
                originalStylesRef.current = document.body.style.cssText;
            }
            document.body.classList.add('export-pdf-style');
            document.body.style.width = '1000px';
            document.body.style.height = '1469px';  // Core content window height 1414px plus 55px for navbar.//
                                                    //Export headers and footers remove a further 260px leaving  1000px width by 1154px height//
        } else {
            // When it changes otherwise, remove temporary styles
            document.body.classList.remove('export-pdf-style');
            document.body.style.cssText = originalStylesRef.current;
            originalStylesRef.current = "";
        }
            // This cleanup function will be called when `optimizedForExport` changes or component unmounts
            return () => {
            document.body.classList.remove('export-pdf-style');
            document.body.style.cssText = originalStylesRef.current;
        };
    }, [optimizedForExport]);


    // Prepare main function to perform the actual export
    const performExport = useCallback((exportType = 'default') => {
        if (contentRef.current) {
            // Get window device pixel ratio for scale later
            const dpi = window.devicePixelRatio || 1;

            html2canvas(contentRef.current, {
                windowWidth: 1000, windowHeight: 1469, backgroundColor: '#012F3E', scale: dpi, // Core content window height 1414px plus 55px for navbar //
            }).then(canvas => {
                // Log canvas dimensions
                
                // Convert canvas to image
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF({ orientation: 'portrait', unit: 'mm', format: [210, 297], compress: true });
                pdf.addImage(imgData, 'JPEG', 0, 0, 210, 297, '', 'FAST');
                pdf.save(inUseFileName);
            }).catch(error => {
                console.error("Error capturing canvas:", error);
            }).finally(() => {
                store.dispatch(setExportOptimization(false));
                setIsExporting(false);
                // Also check resest universal loading state to false, as all should be loaded when exported
                dispatch(setLoading(false));
            });
        } else {
            console.error("Content reference not found");
        }
    }, [contentRef, inUseFileName, dispatch]);
    

    // React to changes in optimisation and loading states, to only call the actual export function at the right moment
    useEffect(() => {
        // Ensure the document is ready to export
        if (isExporting && inUseExportType) {
            // Ensure all other necessary conditions are met, but after waiting if needed
            const checkConditions = async () => {
                const maxChecks = 10; // Check if conditions are met up to this many times
                let currentCheck = 0;
                while (currentCheck < maxChecks && (universalLoading || !optimizedForExport)) {
                  await new Promise(resolve => setTimeout(resolve, 1000)); // Check every second
                  currentCheck++;
                }
                if (!universalLoading && optimizedForExport) {
                  performExport(inUseExportType); // Function that handles the actual export logic
                }
              };
            checkConditions();
        }
    }, [isExporting, optimizedForExport, universalLoading, inUseExportType, performExport]); 


    return (
        <ExportPDFContext.Provider value={{ exportToPDF }}>
            {children}
        </ExportPDFContext.Provider>
    );
};