import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isDataLoaded: false,
  mapData: {},
  chartData: {},
  reportData: {},
};


const fetchedDataSlice = createSlice({
  name: 'fetchedData',
  initialState,
  reducers: {
    // Action to store fetched map data
    storeMapData(state, action) {
      const { reference, data, timestamp } = action.payload;
      state.mapData[reference] = { ...data, timestamp };
    },
    // Action to store fetched chart data
    storeChartData(state, action) {
      const { reference, data, timestamp } = action.payload;
      state.chartData[reference] = { ...data, timestamp };
    },
    // Action to store fetched report data
    storeReportData(state, action) {
      const { reference, data, timestamp } = action.payload;
      state.reportData[reference] = { ...data, timestamp };
    },
    // Optionally, could add actions to remove or clear data here
},
});

export const { storeMapData, storeChartData, storeReportData } = fetchedDataSlice.actions;
export default fetchedDataSlice.reducer;