import React from 'react';

function ErrorBox({ message }) {
  const MAX_LENGTH = 150; // Define the maximum length of the error message
  
  // Function to truncate the message with ellipsis if it's longer than MAX_LENGTH
  const truncateMessage = (msg) => {
    return msg.length > MAX_LENGTH ? msg.substring(0, MAX_LENGTH) + '...  ' : msg;
  };
  //Applytruncate function to incoming message
  const displayMessage = truncateMessage(message);

  // Render an empty div if the message is null, empty, or undefined
  if (!message) {
    return <div style={{ display: 'none' }}></div>;
  }

  // Otherwise, render the error box with the (potentially truncated) message
  return (
    <div className="error-box">
      {displayMessage && <p className="error-message">{displayMessage}</p>}
    </div>
  );
}

export default ErrorBox;
