import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedDate: '', // This is set to the latest available date automatically by DataMenu
  selectedMetric: 'Economic Vitality Index (EVI)',
  selectedMetricDescription: 'This metric shows the EvaluateLocate Economic Vitality Index rating at the selected period of time. The median rating at the beginning of the index in January 2010 was 100, and the scores for particular locations show how they have progressed at times of economic opportunity and stress. The index takes into account more than 100 metrics and is approximately equally weighted across the three headline data pillars around household prosperity, business activity and the labour market.',
  selectedGeographyType: 'Postal Districts',
  currentDataRef: null,
};

export const mapsSlice = createSlice({
  name: 'maps',
  initialState,
  reducers: {
    updateMapDate: (state, action) => {
      state.selectedDate = action.payload;
    },
    updateMapMetric: (state, action) => {
      state.selectedMetric = action.payload;
    },
    updateMapMetricDescription: (state, action) => {
      state.selectedMetricDescription = action.payload;
    },
    updateMapGeographyType: (state, action) => {
      state.selectedGeographyType = action.payload;
    },
    updateMapDataRef: (state, action) => {
      state.currentDataRef = action.payload;
    },
  }
});

export const { updateMapDate, updateMapMetric, updateMapMetricDescription, updateMapGeographyType, updateMapDataRef } = mapsSlice.actions;
export default mapsSlice.reducer;