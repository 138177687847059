import React, { useContext, useState } from 'react';
import Grid, { GridCell } from '../DisplaySystem/Grid';
import DetailsChangeForm from '../Authentication/AccountManagement/DetailsChangeForm';
import PasswordChangeForm from '../Authentication/AccountManagement/PasswordChangeForm';
import AuthContext from '../Authentication/PermissionLevels/AuthContext';
import AdminCreditsInfo from '../Authentication/AdminAuthentication/AdminCreditsInfo';

function AdminSection() {
  const { authState } = useContext(AuthContext);
  const [passwordFormVisible, setPasswordFormVisible] = useState(false);
  const [detailsFormVisible, setDetailsFormVisible] = useState(false);
  const [displayCredits, setDisplayCredits] = useState(true);
  const [displayAccountDetails, setDisplayAccountDetails] = useState(false);

  // Handlers for visibility of different forms
  const handlePasswordChange = () => {
    setPasswordFormVisible(false); // hide the form after a successful password change
  };
  const handleDetailsChange = () => {
    setDetailsFormVisible(false); // Hide the form after a successful change to details
  };

  const handlePasswordFormButtonActivation = () => {
    setDetailsFormVisible(false);
    setDisplayCredits(!displayCredits);
    setPasswordFormVisible(!passwordFormVisible);
  }
  const handleDetailsFormButtonActivation = () => {
    setPasswordFormVisible(false);
    setDisplayCredits(!displayCredits);
    setDetailsFormVisible(!detailsFormVisible);
  }

  const handleAccountDetailsDisplayToggle = () => {
    setDisplayAccountDetails(!displayAccountDetails);
  }


  return (
      <div className='content'>
        <Grid>
          <GridCell>
            <div className='form-container top-start yscroll-allowed'>
              <div className="form-box">
              <h1>Secure Section: Admin Actions</h1>
              <h2>User details for: {authState.FirstName} {authState.SecondName}</h2> 
              {(displayAccountDetails && !detailsFormVisible && !passwordFormVisible) ? (
                <div>
                  <div className='form-box-table-container'>
                    <div className='form-box-table-first-column'>
                      <p>Company:</p>
                    </div>
                    <div className='form-box-table-item'>
                      <p><b>{authState.Company}</b></p>
                    </div>
                  </div>
                  <div className='form-box-table-container'>
                    <div className='form-box-table-first-column'>
                      <p>Email:</p>
                    </div>
                    <div className='form-box-table-item'>
                      <p><u>{authState.Email}</u></p>
                    </div>
                  </div>
                  <div className='form-box-table-container'>
                    <div className='form-box-table-first-column'>
                      <p>Alternative Email:</p>
                    </div>
                    <div className='form-box-table-item'>
                      <p><u>{authState.AlternativeEmail}</u></p>
                    </div>
                  </div>
                  <div className='form-box-table-container'>
                    <div className='form-box-table-first-column'>
                      <p>Phone Number:</p>
                    </div>
                    <div className='form-box-table-item'>
                      <p>{authState.PhoneNumber}</p>
                    </div>
                  </div>
                  <div className='form-box-table-container'>
                    <div className='form-box-table-first-column'>
                      <p>Username:</p>
                    </div>
                    <div className='form-box-table-item'>
                      <p>{authState.Username}</p>
                    </div>
                  </div>
                  <div className='form-box-table-container'>
                    <div className='form-box-table-first-column'>
                      <p>Permissions:</p>
                    </div>
                    <div className='form-box-table-item'>
                      <p>{authState.Permissions}</p>
                    </div>
                  </div>
                  <div className='form-box-table-container'>
                    <div className='form-box-table-first-column'>
                      <p>Last Login Date:</p>
                    </div>
                    <div className='form-box-table-item'>
                      <p>{new Date(authState.LastLoginDate).toLocaleDateString('en-GB')}</p>
                    </div>
                  </div>
                </div>
              ): (null) }


                {!detailsFormVisible && !passwordFormVisible && <button 
                  className="form-button" 
                  onClick={() => handleAccountDetailsDisplayToggle()}>
                  {displayAccountDetails ? 'Hide Account Details' : 'Show Account Details'}
                </button>}

                <button 
                  className="form-button" 
                  onClick={() => handleDetailsFormButtonActivation()}>
                  {detailsFormVisible ? 'Cancel Details Update' : 'Update Details'}
                </button>

                <button 
                  className="form-button" 
                  onClick={() => handlePasswordFormButtonActivation()}>
                  {passwordFormVisible ? 'Cancel Password Update' : 'Update Password'}
                </button>

              </div>

              {detailsFormVisible && authState.isAuthenticated ? (
              <div className='form-container top-start'>
                <DetailsChangeForm onDetailsChange={handleDetailsChange} />
              </div>
              ) : (null)}

              {passwordFormVisible && authState.isAuthenticated ? (
              <div className='form-container top-start'>
                <PasswordChangeForm onPasswordChange={handlePasswordChange} />
              </div>
              ) : (null)}

            </div>
          </GridCell>
          <GridCell>
            <AdminCreditsInfo displayCredits={displayCredits}/>
          </GridCell>
        </Grid>
      </div>
  );
}

export default AdminSection;