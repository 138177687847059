import React, { useState } from   'react';
import { useSelector } from       'react-redux';
import Grid, { GridCell } from    '../DisplaySystem/Grid';
import MapWindow from             '../Components/MapComponents/Map';
import DataMenu from              '../RequestDataSystem/DataMenu';
import MetricDetailDropdown from  '../Components/InfoComponents/MetricDetailDropdown';
import TipsDropdown from          '../Components/InfoComponents/TipsDropdown';
import Modal from                 '../Components/InfoComponents/ModalBox';
import shareGeneralSymbol from '../Assets/SocialShareLogos/shareGeneralSymbol.png';
import { useExportPDF } from      '../ExportSystem/ExportPDFContext';
import SocialMediaShare from '../NetworkSharing/SocialMediaShare';
import pdfSymbol from '../Assets/SocialShareLogos/pdf.png';


function MapsPage() {

  // Name imported pdf export function
  const { exportToPDF } = useExportPDF();

  // Share & Export Modal system
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const handleOpenShareModal = () => setIsShareModalOpen(true);
  const handleCloseShareModal = () => setIsShareModalOpen(false);

  // Import universal state to double check if in exporting mode
  const isExporting = useSelector(state => state.appearancePersistence.exportingState);

 
  // Local state for scale recalculation
  const [recalcScale, setRecalcScale] = useState(true);
  /*const handleMapScaleFixToggle = () => {
    setRecalcScale(!recalcScale);
  };*/

  const metricDescriptionDisplay = useSelector(state => state.maps.selectedMetricDescription);
  const mapMetricName = useSelector(state => state.maps.selectedMetric);
  const mapSelectedDate = useSelector(state => state.maps.selectedDate);

  // Get universal loading state from redux
  const universalLoading = useSelector(state => state.appearancePersistence.isLoading);

  // Get the new user guide state from redux to allow extra guidance for newer users
  const newUserGuideVersion = useSelector(state => state.appearancePersistence.newUserGuideVersion);


  const fileNamePDF = `EvaluateLocate_Exported_Map_${mapMetricName}_${mapSelectedDate}.pdf`;
  // Combined handler for export clicks
  const handlePDFExportClick = () => {
    exportToPDF({exportType: 'map', fileName: fileNamePDF});
    setIsShareModalOpen(false);
  };

  return (
    <Grid>
      <GridCell>
        <div className='content-container top-start' style={{ height: 'auto', marginTop: '5px', marginBottom: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <DataMenu
          settingMapDate={true}
          settingMapMetric={true}
          settingMapGeographyType={true}
          />

          {!isExporting && <button
            className='form-button'
            style={{ display: 'flex', flexDirection: 'row', width: '85%', maxWidth: '500px', height: '45px', justifyContent: 'center', alignItems: 'center', marginTop: '15px', marginBottom: '5px'}}
            onClick={handleOpenShareModal}>
              <img src={shareGeneralSymbol} alt="share symbol" className="social-share-image" style = {{ height: '30px', width: '30px'}}/>
              <p style={{margin: '10px'}}>Share or export this map</p>
          </button>}

          {!isExporting && <Modal
            isOpen={isShareModalOpen}
            onClose={handleCloseShareModal}
            title={'Share or export'}>
              <h3>Share a link to this map:</h3>
              <SocialMediaShare/>
              <h3>Export to your preferred format:</h3>
              <div style ={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' , textAlign: 'center'}}>
                {!isExporting && <button
                                  className='export-share-button'
                                  onClick={() => handlePDFExportClick()}>
                                    <p style = {{ margin: '1px'}}>Export this map to .pdf</p>
                                    <p style = {{fontSize: '11px', margin: '1px'}}>(10 tokens)</p>
                                    <img src={pdfSymbol} alt="PDF symbol" className="social-share-image" style = {{ height: '52px', width: '52px'}}/>
                                  </button>}
              </div>
          </Modal>}

          <MetricDetailDropdown
            title="About this metric"
            content={<div>
                       <p>{universalLoading ? 'Loading...' : metricDescriptionDisplay}</p>
                     </div>}/>
          <TipsDropdown
            autoExpand={newUserGuideVersion}
            title="Using the Interactive Map"
            content={<div>
                       <p>Our interactive map shows the ‘temperature’ of the UK in relation to more than 100 metrics. The key on the bottom left changes depending on the metric selected.</p>
                       <p>Use the drop-down menu above to select the metric you want to display and any month up to the present.</p>
                       <p>Entering the postcode district reference for a location (e.g. TW15) will generate the most precise report, but you can also search by place name, region or nation.</p>
                       <p>The search engine drops location pins onto the map. Select these for the option to generate detailed charts and location reports.</p>
                     </div>}/>
        </div>
      </GridCell>

      <GridCell>
        < MapWindow
          recalculateScale = {recalcScale}
          setRecalcScale = {setRecalcScale}
        />
      </GridCell>
    </Grid>
  );
}
  
export default React.memo(MapsPage);