import {React, useState, useEffect } from   'react';
import { useSelector, shallowEqual } from   'react-redux';
import Grid, { GridCell } from              '../DisplaySystem/Grid';
import DataMenu from                        '../RequestDataSystem/DataMenu';
import { selectAllReportData } from         '../redux/Selectors/ReportsPageSelectors';
/*import { setTrueFalseReportColourCoding } from '../redux/DataMenuSelections/reportsSlice';*/
import KeyStatWindow from                   '../Components/ReportComponents/KeyStatBox';
import KeyStatWindowAddition from           '../Components/ReportComponents/KeyStatBoxPlusButton';
import TipsDropdown from                    '../Components/InfoComponents/TipsDropdown';
import Modal from                           '../Components/InfoComponents/ModalBox';
import SocialMediaShare from '../NetworkSharing/SocialMediaShare';
import shareGeneralSymbol from '../Assets/SocialShareLogos/shareGeneralSymbol.png';
import pdfSymbol from '../Assets/SocialShareLogos/pdf.png';
import csvSymbol from '../Assets/SocialShareLogos/csv.png';
import { useExportPDF } from                '../ExportSystem/ExportPDFContext'
import { setLoading } from    '../redux/AppearancePersistence/appearancePersistenceSlice';
import { useDispatch } from   'react-redux';
import UseWindowSize from '../DisplaySystem/WindowSize';
import LoadingWheel from '../Assets/LoadingSystem/LoadingWheel';
import { handleReportCSVExport } from '../ExportSystem/ReportCSVExport';

function ReportsPage() {
  const { width } = UseWindowSize();
  const horizontalLayoutBreakpoint = 1200;

  // Import universal state to double check if in exporting mode
  const isExporting = useSelector(state => state.appearancePersistence.exportingState);

  // Get input data from Redux store
  const inputData = useSelector(selectAllReportData, shallowEqual);

  // Access current data refs for the report to track when they change
  const ReportDataRefs = useSelector(state => state.reports.currentDataRefs);
  const ReportStoredDataRefs = Object.keys(useSelector(state => state.fetchedData.reportData));

  // Get report date code
  const reportDateCode = useSelector(state => state.reports.selectedDate);
  // Get headline list of selected locations
  const selectedLocations = useSelector(state => state.reports.selectedLocations);
  // Combine key info for filename
  const fileNameCSV = `EvaluateLocate_Exported_Report_Data_${reportDateCode}_${selectedLocations}.csv`;
  const fileNamePDF = `EvaluateLocate_Exported_Report_${reportDateCode}_${selectedLocations}.pdf`;

  // Get title information on main location
  const ReportFirstLocation = selectedLocations.length > 0 ? selectedLocations[0] : '';
  // More specific report titles for instances when the report is for a particular address 
  const ReportAddressSpecificTitle = useSelector(state => state.reports.addressSpecificTitle);

  // Create state for report title
  const [ReportTitleLocation, setReportTitleLocation] = useState(ReportFirstLocation);
  // Assign title conditionally, with an effect to update it if the first selected location changes
  useEffect(() => {
    if (ReportAddressSpecificTitle!==''){
      setReportTitleLocation(ReportAddressSpecificTitle);
    } else {
      setReportTitleLocation(ReportFirstLocation);
    }
  }, [ReportFirstLocation, ReportAddressSpecificTitle]);


  // Source current report metrics to display from store
  const metricsToDisplay = useSelector(state => state.reports.selectedReportMetrics);
  // Link this with state and effect that monitors for the length of the metricsToDisplay array
  const [reportMetricsLength, setReportMetricsLength] = useState(metricsToDisplay.length);
  useEffect(() => {
    setReportMetricsLength(metricsToDisplay.length);
  }, [metricsToDisplay.length]);

    

  // Name imported pdf export function
  const { exportToPDF } = useExportPDF();
  const dispatch = useDispatch();

  // Share & Export Modal system
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const handleOpenShareModal = () => setIsShareModalOpen(true);
  const handleCloseShareModal = () => setIsShareModalOpen(false);

  // Combined handlers for export clicks
  const handlePDFExportClick = () => {
    exportToPDF({exportType: 'report', fileName: fileNamePDF});
    setIsShareModalOpen(false);
  };
  const handleCSVExportClick = () => {
    handleReportCSVExport({ inputData: inputData, metricsToDisplay: metricsToDisplay, filename: fileNameCSV });
    setIsShareModalOpen(false);
  };




  /*
    // Get state for colour coding toggle
  const colourCodingSetting = useSelector(state => state.reports.reportColourCoding);
  // Create handler for colour coding toggle
  const handleColourCodingToggle = () => {
    dispatch(setTrueFalseReportColourCoding(!colourCodingSetting));
  };
  */

  // Set universal loading state to false once all actions are complete, also retrigger whenever data refs change
  useEffect (() => {
    if (inputData.length > 0 && ReportTitleLocation && (ReportDataRefs.every(element => ReportStoredDataRefs.includes(element))) ) {
      // Introduce 500ms delay before setting universal loading state to false, to allow any necessary final rendering when all selected data is present
      setTimeout(() => dispatch(setLoading(false)), 500);
    }
  }, [inputData.length, ReportTitleLocation, ReportDataRefs, ReportStoredDataRefs, dispatch]);


  // Get the new user guide state from redux to allow extra guidance for newer users
  const newUserGuideVersion = useSelector(state => state.appearancePersistence.newUserGuideVersion);

  return (
      <Grid >
        <GridCell>
          <div className='content-container top-start' style={{ marginTop: '5px', marginBottom: '5px'}}>
            <div className="location-report-title">
              <h2>Location Report: {ReportTitleLocation}</h2>
            </div>
            <DataMenu settingReportDate={true} settingReportLocations={true} />

            {!isExporting && <button
            className='form-button'
            style={{ display: 'flex', flexDirection: 'row', width: '85%', maxWidth: '500px', height: '45px', justifyContent: 'center', alignItems: 'center', marginTop: '15px', marginBottom: '5px'}}
            onClick={handleOpenShareModal}>
              <img src={shareGeneralSymbol} alt="share symbol" className="social-share-image" style = {{ height: '30px', width: '30px'}}/>
              <p style={{margin: '10px'}}>Share or export this report</p>
            </button>}


            {/*<button className='form-button' style= {{maxWidth: '250px'}} onClick={handleColourCodingToggle}>Toggle colour coding vs. end location</button>*/}
            {!isExporting && <TipsDropdown
              autoExpand={newUserGuideVersion}
              title="Creating Location Reports"
              content={<div>
                        <p>Create reports with up to 12 separate metrics for multiple locations. Just pick your locations above and click the '+' signs opposite to choose your metrics.</p>
                        <p>List your locations above to set the order in which they appear on the metric panels. To rearrange the display of the metric panels, use the up and down arrows on the top right of each.</p>
                      </div>}/>}
          </div>

          {!isExporting && <Modal
            isOpen={isShareModalOpen}
            onClose={handleCloseShareModal}
            title={'Share or export'}>
              <h3>Share a link to this report:</h3>
              <SocialMediaShare/>
              <br/>
              <h3>Export to your preferred format:</h3>
              <div style ={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' , textAlign: 'center'}}>
                {!isExporting && <button
                                  className='export-share-button'
                                  onClick={() => handlePDFExportClick()}>
                                    <p style = {{ margin: '1px'}}>Export this report to .pdf</p>
                                    <p style = {{fontSize: '11px', margin: '1px'}}>(10 tokens)</p>
                                    <img src={pdfSymbol} alt="PDF symbol" className="social-share-image" style = {{ height: '52px', width: '52px'}}/>
                                  </button>}
                {!isExporting && <button
                                  className='export-share-button'
                                  onClick={() => handleCSVExportClick()}>
                                    <p style = {{ margin: '1px'}}>Export report data to .csv</p>
                                    <p style = {{fontSize: '11px', margin: '1px'}}>(10 tokens)</p>
                                    <img src={csvSymbol} alt="CSV symbol" className="social-share-image" style = {{ height: '52px', width: '52px'}}/>
                                </button>}
              </div>
          </Modal>}

        </GridCell>
        


        {metricsToDisplay.map((metricName, displayIndex) => {
          const locations = [];

          inputData.forEach(dataSet => {
            const metricIndexInDataSet = dataSet.labels.MetricLabels.indexOf(metricName);
            if (metricIndexInDataSet !== -1) {
              dataSet.labels.LocationLabels.forEach((location, locationIndex) => {
                const locationInfo = {
                  name: location,
                  value: dataSet.values[metricIndexInDataSet],
                  isPrimary: locationIndex === 0 // Mark the first location as primary
                };
                locations.push(locationInfo);
              });
            }
          });
  
          if (locations.length === 0) return null;

          if (reportMetricsLength !== metricsToDisplay.length) {
            return (
              <GridCell key={metricName}>
                <LoadingWheel/>
              </GridCell>
            )
          }

          return (
            <GridCell key={metricName}>
              <KeyStatWindow
                 metricName={metricName}
                 locations={locations}
                 metricIndex={displayIndex}
                 lastMetric={displayIndex===(reportMetricsLength - 1)}/>
            </GridCell>
          );
        })}

        {(reportMetricsLength < 3) && (width > horizontalLayoutBreakpoint) && <KeyStatWindowAddition/>}
        {(reportMetricsLength < 4) && (width > horizontalLayoutBreakpoint) && <KeyStatWindowAddition/>}
        {(reportMetricsLength < 5) && (width > horizontalLayoutBreakpoint) && <KeyStatWindowAddition/>}
        {(reportMetricsLength < 12) && <KeyStatWindowAddition/>}

      </Grid>
  );
}

export default ReportsPage;