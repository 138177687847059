import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

const Modal = ({ isOpen, onClose, title, children }) => {
  // Close modal with Escape key
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
      document.body.style.overflow = 'hidden'; // Prevent background scroll when modal is open
    } else {
      window.removeEventListener('keydown', handleKeyDown);
      document.body.style.overflow = 'unset'; // Re-enable background scroll when modal is closed
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      document.body.style.overflow = 'unset';
    };
  }, [isOpen, onClose]);

  // Do not render the modal if it's not open
  if (!isOpen) return null;

  // Render the modal but with React portal to top of DOM tree
  const modalContent = (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-box" onClick={(e) => e.stopPropagation()}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'start' }}>
            {title && <h2 className="modal-title">{title}</h2>}
            <button className="XCloseButton" onClick={onClose}>
            &times;
            </button>
        </div>
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(modalContent, document.body);
};

export default Modal;