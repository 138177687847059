import React, { useEffect, useState } from  'react';
import { useSelector, useDispatch } from    'react-redux';
import LoadingWheel from                    '../Assets/LoadingSystem/LoadingWheel';
import ErrorBox from                        '../Components/InfoComponents/ErrorBox';
import fetchDataMenu from                   './FetchDataMenu';
import { useUpdateDataMenuState } from      '../redux/DataMenuAvailability/useUpdateDataMenuState';
import useFetchMapDataOnSelectionChange from    './FetchMapDataOnSelectionChange';
import useFetchChartDataOnSelectionChange from  './FetchChartDataOnSelectionChange';
import useFetchReportDataOnSelectionChange from './FetchReportDataOnSelectionChange';
import {
  updateMapMetric,
  updateMapMetricDescription,
  updateMapDate
} from '../redux/DataMenuSelections/mapsSlice';
import {
  updateChartLocations,
  updateChartMetric,
  updateChartMetricDescription
} from '../redux/DataMenuSelections/chartsSlice';
import {
  updateReportLocations,
  updateReportDate,
  updateAddressSpecificTitle
} from '../redux/DataMenuSelections/reportsSlice';
import Select from 'react-select';
import useURLQuery from '../NetworkSharing/useURLQuery';
import dropdownStyles from '../DisplaySystem/DropdownsStyles/CustomDropdownStyles';
import exportDropdownStyles from '../DisplaySystem/DropdownsStyles/CustomDropdownStylesExport';
import { reverseTranslateDate, translateAndSortDates, translateDate, parseYYYYMM } from './DataDateFormattingFunctions';
import { setLoading } from '../redux/AppearancePersistence/appearancePersistenceSlice';
import isEqual from 'lodash/isEqual'; // lets us check if two arrays are equal

// Custom MultiValueLabel component to insert a custom separator while keeping the default 'x' button
const CustomMultiValueLabel = ({ data, index, selectProps }) => {
  return (
    <>
      {index >0 && <span style={{ marginRight: '10px', fontSize: '16px', marginTop: '5px' }}>{selectProps.customSeparator}</span>}
      <span>{data.label}</span>
    </>
  );
};

const DataMenu = ({
  //SECTION 1: SOURCE SETTINGS, MENU STATE AND FUNCTIONALITY
  settingChartMetric = false,
  settingChartLocations = false,
  settingMapDate = false,
  settingMapMetric = false,
  settingMapGeographyType = false,
  settingReportDate = false,
  settingReportLocations = false}) => {
  // Redux dispatch
  const dispatch = useDispatch();
  // Set loading and error states for the DataMenu
  const [menuLoading, setMenuLoading] = useState(false);
  const [menuError, setMenuError] = useState('');
  // Sync universal application loading state with DataMenu loading state when true
  useEffect(() => {
    if (menuLoading) {
      // Whenever local menu loading state is true, also set universal loading state to true
      dispatch(setLoading(true));
    }
  }, [menuLoading, dispatch]);

  // Get export styling state from redux
  const optimizedForExport = useSelector(state => state.appearancePersistence.optimizedForExport);

  // Get universal loading state from redux
  const universalLoading = useSelector(state => state.appearancePersistence.isLoading);

  // Fetch data menu on component mount
  useEffect(() => {
    // This sources the actual available data menu options from the backend
    fetchDataMenu(setMenuLoading, setMenuError, updateAvailableLocations, updateAvailableMetrics, updateAvailableDates, updateAvailableGeographyTypes, dispatch);
    // Also always set universal loading state to true whenever the fetchDataMenu function is called
    dispatch(setLoading(true))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Ignore any lint warning here about missing dependencies, this empty array is on purpose for running only on first component mount
  // Custom hook to dispatch actions to update Redux storage
  const {
    updateAvailableLocations,
    updateAvailableMetrics,
    updateAvailableDates,
    updateAvailableGeographyTypes
  } = useUpdateDataMenuState();
  // Import resulting data menu options from Redux
  const { availableLocations, availableMetrics, availableDates } = useSelector(state => state.dataMenu);
  // Extract simple arrays as lists for validation of URL parameters later
  const [simpleAvailableLocations, setSimpleAvailableLocations] = useState(availableLocations ? availableLocations.map(location => location.Location) : []);
  const [simpleAvailableMetrics, setSimpleAvailableMetrics] = useState(availableMetrics ? availableMetrics.map(metric => metric.Metric) : []);
  const [simpleAvailableDates, setSimpleAvailableDates] = useState(availableDates ? availableDates.map(date => date.Date) : []);
  // Update simple available menu arrays when Redux changes
  useEffect(() => {
    setSimpleAvailableLocations(availableLocations ? availableLocations.map(location => location.Location) : []);
    setSimpleAvailableMetrics(availableMetrics ? availableMetrics.map(metric => metric.Metric) : []);
    setSimpleAvailableDates(availableDates ? availableDates.map(date => date.Date) : []);
  }, [availableLocations, availableMetrics, availableDates]);

  // Update default date selection to latest available date in Redux if it is blank
  const mapDefaultDate = useSelector(state => state.maps.selectedDate);
  const reportDefaultDate = useSelector(state => state.reports.selectedDate);
  useEffect(() => {
      if (mapDefaultDate && mapDefaultDate === '') {
        if (simpleAvailableDates && simpleAvailableDates.length > 0) {
          const latestAvailableDate = simpleAvailableDates[simpleAvailableDates.length - 1];
          dispatch(updateMapDate(latestAvailableDate));
        }
      }
      if (reportDefaultDate && reportDefaultDate === '') {
        if (simpleAvailableDates && simpleAvailableDates.length > 0) {
          const latestAvailableDate = simpleAvailableDates[simpleAvailableDates.length - 1];
          dispatch(updateReportDate(latestAvailableDate));
        }
      }
  }, [simpleAvailableDates, mapDefaultDate, reportDefaultDate, dispatch]);





  // Source core Redux state for source of truth on overall menu selections
  const { selectedMetric: reduxMapMetric, selectedDate: reduxMapDate } = useSelector(state => state.maps);
  const { selectedLocations: reduxChartLocations, selectedMetric: reduxChartMetric } = useSelector(state => state.charts);
  const { selectedLocations: reduxReportLocations, selectedDate: reduxReportDate } = useSelector(state => state.reports);



  //SECTION 2: CREATE STATE AND HANDLERS FOR USER SELECTIONS
  const [userSelectedMapMetric, setUserSelectedMapMetric] = useState(null);
  const [userSelectedMapDate, setUserSelectedMapDate] = useState(null);
  const [userSelectedChartLocations, setUserSelectedChartLocations] = useState([]);
  const [userSelectedChartMetric, setUserSelectedChartMetric] = useState(null);
  const [userSelectedReportLocations, setUserSelectedReportLocations] = useState([]);
  const [userSelectedReportDate, setUserSelectedReportDate] = useState(null);

  // Handler for location change (used only by the UI component i.e. user input)
  const areArraysEqual = (a, b) => a.length === b.length && a.every((v, i) => v === b[i]);
  const handleLocationChange = selectedOptions => {
    // selectedOptions is an array of selections with each having a value property
    const locationNames = selectedOptions.map(option => option.value);

    // Set menu loading state to true if there is at least one option selected (no selection is not loading state)
    if (settingChartLocations && !areArraysEqual(locationNames, userSelectedChartLocations)) {
      dispatch(setLoading(locationNames.length > 0));
      // A change to empty location array is still updated, just not a true loading state for any data
      setUserSelectedChartLocations(locationNames);
    } 
    if (settingReportLocations && !areArraysEqual(locationNames, userSelectedReportLocations)) {
      dispatch(setLoading(locationNames.length > 0));
      // A change to empty location array is still updated, just not a true loading state for any data
      setUserSelectedReportLocations(locationNames);
      // In this specific case, also clear the address specific title for reports 
      if (locationNames.length === 0) {
        dispatch(updateAddressSpecificTitle(''));
      }
    }
  };

  // Handler for metric change (used only by the UI component i.e. user input)
  const handleMetricChange = selectedOption => {
    // Set menu loading state to true if there is at least one option selected (no selection is not loading state)
    if (selectedOption.value) {
      if (settingChartMetric && userSelectedChartLocations.length > 0 && selectedOption.value!==userSelectedChartMetric) { // Note cannot change chart metric if no chart locations selected
        dispatch(setLoading(true));
        setUserSelectedChartMetric(selectedOption.value);
      } else if (settingMapMetric && selectedOption.value!==userSelectedMapMetric) {
        dispatch(setLoading(true));
        setUserSelectedMapMetric(selectedOption.value);
      }
    }
  };
  // Handler for date change (used only by the UI component i.e. user input)
  const handleDateChange = (selectedOption) => {
    // Set menu loading state to true if there is at least one option selected (no selection is not loading state)
    if (selectedOption.label) {
      const originalDate = reverseTranslateDate(selectedOption.label);
      if (settingMapDate && originalDate!==userSelectedMapDate) {
        dispatch(setLoading(true));
        setUserSelectedMapDate(originalDate);
      } else if (settingReportDate && userSelectedReportLocations.length > 0 && originalDate!==userSelectedReportDate) {  // Note cannot change report date if no report locations selected
        dispatch(setLoading(true));
        setUserSelectedReportDate(originalDate);
      }
    }
  };
  // **Only changes to the user selection in the interface trigger these handler functions**




  //SECTION 3: COMPARE ANY INITIAL URL PARAMETERS AND INITIALISE SELECTION STATE
  // Set up query parameters and setting capacity for them, using custom hook
  const [dataMenuParams, setDataMenuParams] = useURLQuery();
  // Function to extract all parameters back from URL parameter format
  const extractDataMenuParams = (params) => {
    return {
      mapDate: params.get('mapDate') ?? null,
      mapMetric: params.get('mapMetric') ?? null,
      chartMetric: params.get('chartMetric') ?? null,
      chartLocations: params.get('chartLocations')?.split(',') ?? [],
      reportDate: params.get('reportDate') ?? null,
      reportLocations: params.get('reportLocations')?.split(',') ?? [],
    };
  };

  const [initialParamsChecked, setInitialParamsChecked] = useState(false);
  // Effect to capture initial URL extension params and update state as required
  useEffect(() => {
    // Only run this if the initial URL parameters have *not* already been processed for initialisation of selection
    if (!initialParamsChecked) {
      // Object to store the current extracted URL data menu parameters, separate for now from those from the user interface
      const initialURLDataMenuParams = extractDataMenuParams(dataMenuParams);
  
      // Map Metric Selection Initialisation
      if (settingMapMetric && initialURLDataMenuParams.mapMetric) {
        setUserSelectedMapMetric(initialURLDataMenuParams.mapMetric);
      } else if (!initialURLDataMenuParams.mapMetric && !userSelectedMapMetric && reduxMapMetric) {
        setUserSelectedMapMetric(reduxMapMetric);
      }
      // Map Date Selection Initialisation
      if (settingMapDate && initialURLDataMenuParams.mapDate) {
        setUserSelectedMapDate(initialURLDataMenuParams.mapDate);
      } else if (!initialURLDataMenuParams.mapDate && !userSelectedMapDate && reduxMapDate) {
        setUserSelectedMapDate(reduxMapDate);
      }
      // Chart Metric Selection Initialisation
      if (settingChartMetric && initialURLDataMenuParams.chartMetric && !userSelectedChartMetric) {
        setUserSelectedChartMetric(initialURLDataMenuParams.chartMetric);
      } else if (!initialURLDataMenuParams.chartMetric && !userSelectedChartMetric && reduxChartMetric) {
        setUserSelectedChartMetric(reduxChartMetric);
      }
      // Chart Locations Selection Initialisation
      if (settingChartLocations && initialURLDataMenuParams.chartLocations.length>0 && !(userSelectedChartLocations.length>0)) {
        setUserSelectedChartLocations(initialURLDataMenuParams.chartLocations);
      } else if (!(initialURLDataMenuParams.chartLocations.length>0) && !(userSelectedChartLocations.length>0) && reduxChartLocations.length>0) {
        setUserSelectedChartLocations(reduxChartLocations);
      }
      // Report Locations Selection Initialisation
      if (settingReportLocations && initialURLDataMenuParams.reportLocations.length>0 && !(userSelectedReportLocations.length>0)) {
        setUserSelectedReportLocations(initialURLDataMenuParams.reportLocations);
      } else if (!(initialURLDataMenuParams.reportLocations.length>0) && !(userSelectedReportLocations.length>0) && reduxReportLocations.length>0) {
        setUserSelectedReportLocations(reduxReportLocations);
      }
      // Report Date Selection Initialisation
      if (settingReportDate && initialURLDataMenuParams.reportDate && !userSelectedReportDate) {
        setUserSelectedReportDate(initialURLDataMenuParams.reportDate);
      } else if (!initialURLDataMenuParams.reportDate && !userSelectedReportDate && reduxReportDate) {
        setUserSelectedReportDate(reduxReportDate);
      }
      // Set the initial params processed flag to true without delay - as the info from the initial params has been captured
      setInitialParamsChecked(true);
    }
    // **Only runs once on initial load, when the initial URL params can trigger the effect of this on user selection settings and Redux state**
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialParamsChecked]);

 

  //SECTION 4: REFLECT ANY USER SELECTION (POTENTIALLY INITIALISED BY URL PARAMS) IN REDUX STATE
  const [userSelectionUpdated, setUserSelectionUpdated] = useState(false);
  useEffect(() => {
    let awaitReduxUpdate = false;
    // Only run this if the initial URL parameters *have* already been checked for correct initialisation of selection
    if (initialParamsChecked) {
      if (userSelectedMapMetric && userSelectedMapMetric !== reduxMapMetric) {
        dispatch(updateMapMetric(userSelectedMapMetric));
        awaitReduxUpdate = true;
      }
      if (userSelectedMapDate && userSelectedMapDate !== reduxMapDate) {
        dispatch(updateMapDate(userSelectedMapDate));
        awaitReduxUpdate = true;
      }
      if (userSelectedChartLocations.length>0 && userSelectedChartMetric && userSelectedChartMetric !== reduxChartMetric) { //Note also check for chart locations non zero
        dispatch(updateChartMetric(userSelectedChartMetric));
        awaitReduxUpdate = true;
      }
      if (!isEqual(userSelectedChartLocations, reduxChartLocations)) {
        dispatch(updateChartLocations(userSelectedChartLocations));
        awaitReduxUpdate = true;
      }
      if (!isEqual(userSelectedReportLocations, reduxReportLocations)) {
        dispatch(updateReportLocations(userSelectedReportLocations));
        awaitReduxUpdate = true;
      }
      if (userSelectedReportDate && userSelectedReportDate !== reduxReportDate) {
        dispatch(updateReportDate(userSelectedReportDate));
        awaitReduxUpdate = true;
      }
      if (!awaitReduxUpdate) {
        // Case where no redux update needed at comparison stage
        dispatch(setLoading(false));
      }
      // Once this logic has run with any outcome, set the flag that the initial selection has been processed
      setUserSelectionUpdated(true);
    }
    // **Only the correct changes to the selection state (From URL params, or user selection) triggers the redux state update and only where needed**
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialParamsChecked, simpleAvailableDates, simpleAvailableMetrics, simpleAvailableLocations, userSelectedMapDate, userSelectedMapMetric, userSelectedChartMetric, userSelectedChartLocations, userSelectedReportLocations, userSelectedReportDate]);



  //SECTION 5: CHECK STATE SELECTION AGAINST AVAILABLE MENU OPTIONS ONCE THIS BECOMES AVAILABLE
  useEffect(() => {
    if (initialParamsChecked && userSelectionUpdated) { //Checks that the initial params are loaded and selection updated 
      // Checking against available metrics
      if (simpleAvailableMetrics.length>0) { //Checks that the available metrics are loaded
        if (!simpleAvailableMetrics.includes(userSelectedMapMetric)) { //Checks that the selection is actually available once menu loaded
          dispatch(updateMapMetric(simpleAvailableMetrics[0])); // If not then default to the first option
        }
        if (!simpleAvailableMetrics.includes(userSelectedChartMetric)) { //Checks that the selection is actually available once menu loaded
          dispatch(updateChartMetric(simpleAvailableMetrics[0])); // If not then default to the first option
        }
      }
      // Checking against available dates
      if (simpleAvailableDates.length>0){
        if (!simpleAvailableDates.includes(userSelectedMapDate)) {
          dispatch(updateMapDate(simpleAvailableDates[simpleAvailableDates.length-1]));
        }
        if (!simpleAvailableDates.includes(userSelectedReportDate)) {
          dispatch(updateReportDate(simpleAvailableDates[simpleAvailableDates.length-1]));
        }
      }
      // Checking against available locations
      if (simpleAvailableLocations.length>0) {
        if (!userSelectedChartLocations.every(location => simpleAvailableLocations.includes(location))) {
          // Remove the elements of userSelectedChartLocations that are not in simpleAvailableLocations
          dispatch(updateChartLocations(userSelectedChartLocations.filter(location => simpleAvailableLocations.includes(location))));
        }
        if (!userSelectedReportLocations.every(location => simpleAvailableLocations.includes(location))) {
          // Remove the elements of userSelectedReportLocations that are not in simpleAvailableLocations
          dispatch(updateReportLocations(userSelectedReportLocations.filter(location => simpleAvailableLocations.includes(location))));
        }
      }
    }
  }, [initialParamsChecked, userSelectionUpdated, simpleAvailableDates, simpleAvailableMetrics, simpleAvailableLocations, userSelectedMapDate, userSelectedMapMetric, userSelectedChartMetric, userSelectedChartLocations, userSelectedReportLocations, userSelectedReportDate, dispatch]);




  //SECTION 6: WHEN REDUX STATE CHANGES (DUE TO INITIALISATION OR USER SELECTION) UPDATE THE URL PARAMETERS IN TURN FOR ONWARDS SHARING
  useEffect(() => {
    if (initialParamsChecked && userSelectionUpdated) {
      const paramsToUpdate = {};
      const currentParams = dataMenuParams;
 
      // Wrap this entire section in a timeout to debounce the updating of the URL parameters
      setTimeout(() => {
        // Map Parameters
        if (reduxMapDate !== undefined) {
          if (settingMapDate) {
            paramsToUpdate['mapDate'] = reduxMapDate;
          } else if (currentParams.has('mapDate')) {
            paramsToUpdate['mapDate'] = null; // Remove parameters from URL info if they exist but they are not being set on the current page
          }
        }
        if (reduxMapMetric !== undefined) {
          if (settingMapMetric) {
            paramsToUpdate['mapMetric'] = reduxMapMetric;
          } else if (currentParams.has('mapMetric')) {
            paramsToUpdate['mapMetric'] = null;
          }
        }
        // Chart Parameters
        if (reduxChartMetric !== undefined) {
          if (settingChartMetric) {
            if (currentParams.get('chartMetric') !== reduxChartMetric) {
              paramsToUpdate['chartMetric'] = reduxChartMetric;
            }
          } else if (currentParams.has('chartMetric')) {
            paramsToUpdate['chartMetric'] = null;
          }
        }
        if (reduxChartLocations !== undefined && Array.isArray(reduxChartLocations)) {
          const reduxChartLocationsString = reduxChartLocations.join(','); // Convert array to string for comparison
          if (settingChartLocations) {
            if (currentParams.get('chartLocations') !== reduxChartLocationsString) {
              paramsToUpdate['chartLocations'] = reduxChartLocationsString;
            }
          } else if (currentParams.has('chartLocations')) {
            paramsToUpdate['chartLocations'] = null;
          }
        }
        // Report Parameters
        if (reduxReportDate !== undefined) {
          if (settingReportDate) {
            if (currentParams.get('reportDate') !== reduxReportDate) {
              paramsToUpdate['reportDate'] = reduxReportDate;
            }
          } else if (currentParams.has('reportDate')) {
            paramsToUpdate['reportDate'] = null;
          }
        }
        if (reduxReportLocations !== undefined && Array.isArray(reduxReportLocations)) {
          const reduxReportLocationsString = reduxReportLocations.join(','); // Convert array to string for comparison
          if (settingReportLocations) {
            if (currentParams.get('reportLocations') !== reduxReportLocationsString) {
              paramsToUpdate['reportLocations'] = reduxReportLocationsString;
            }
          } else if (currentParams.has('reportLocations')) {
            paramsToUpdate['reportLocations'] = null;
          }
        }
        // Apply changes if there are any
        if (Object.keys(paramsToUpdate).length > 0) {
          // Actually apply the changes to the URL using custom hook
          setDataMenuParams(paramsToUpdate);
        }
      }, 100); // Short timeout to debounce the updating of the URL. Too long and can interfere with route navigation after a user choice
    }
    // Only changes to Redux state trigger updates to the URL parameters
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSelectionUpdated, reduxMapDate, reduxMapMetric, reduxChartMetric, reduxChartLocations, reduxReportDate, reduxReportLocations]);




  //SECTION 7: LOAD REQUESTED DATA CONTENT ON ANY REDUX STATE CHANGE
  useFetchMapDataOnSelectionChange();
  useFetchChartDataOnSelectionChange();
  useFetchReportDataOnSelectionChange();
  // Translate selected date for display
  const getTranslatedDateValue = (date) => {
    return date ? { value: date, label: translateDate(parseYYYYMM(date)) } : null;
  };




  //SECTION 8: GET THE METRIC DESCRIPTION
  // Supporting function to get the metric description from the availableMetrics array, given a metric name
  const getMetricDescription = (metricName) => {
    const metric = availableMetrics.find(m => m.Metric === metricName);
    return metric ? metric.MetricDescription : 'No description available';
  };
  // Effect to monitor for changes in the metric and update the description to match
  useEffect(() => {
    if (!universalLoading) {
      let newMetricDescription = null;
      if (settingMapMetric && reduxMapMetric !== undefined) {
        newMetricDescription = getMetricDescription(reduxMapMetric);
        dispatch(updateMapMetricDescription(newMetricDescription));
      } else if (settingChartMetric && reduxChartMetric !== undefined) {
        newMetricDescription = getMetricDescription(reduxChartMetric);
        dispatch(updateChartMetricDescription(newMetricDescription));
      }
    }
    // Only new metric names or a change in universal loading state trigger updates to the metric description
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxMapMetric, reduxChartMetric, universalLoading, settingChartMetric, settingMapMetric, dispatch, availableMetrics]);




  //SECTION 9: Return the component using the above features
  return (
    <div className="form-container top-start menu-title-container-offset" style={{ zIndex: 9000, height: 'auto', minHeight: '60px'}}>
      {(menuError) && <ErrorBox message={`Data Menu Error: ${menuError}`} />}

      {/* Universal loading state overlay loading wheel to indicate application overall loading state for requested data */}
      {universalLoading && (
      <div className='loading-overlay overlay-dark'>
        <br/><br/><br/>
        <LoadingWheel />
        <p> Updating data menu... </p>
        <br/><br/><br/>
      </div>
      )}

      {/* Note the conditions for the below are not the exact inverse of above. They can load behind in parallel if more efficient */}  
      {(!menuLoading) && <div className="dropdown-condensed-box">
         {/* Metric Dropdown */}
         {(settingChartMetric || settingMapMetric) && (
          <div className='dropdown'>
            <Select
              options={availableMetrics.map(metric => ({ value: metric.Metric, label: metric.Metric }))}
              onChange={handleMetricChange}
              value={{ value: settingChartMetric ? reduxChartMetric : reduxMapMetric, label: settingChartMetric ? reduxChartMetric : reduxMapMetric }}
              styles={optimizedForExport ? exportDropdownStyles : dropdownStyles}
              placeholder="Choose a metric..."
              menuPortalTarget={document.body}
            />
          </div>
        )}
          
        {/* Date Dropdown */}
        {(settingMapDate || settingReportDate) && (
          <div className='dropdown'>
            <Select
              options={translateAndSortDates(availableDates.map(date => date.Date))}
              onChange={handleDateChange}
              value={getTranslatedDateValue(settingMapDate ? reduxMapDate : reduxReportDate)}
              styles={optimizedForExport ? exportDropdownStyles : dropdownStyles}
              placeholder="Choose a date..."
              menuPortalTarget={document.body}
            />
          </div>
        )}
        
        {/* Location Dropdown */}
        {(settingChartLocations || settingReportLocations) && (
          <div className='dropdown'>
            <Select
              isMulti
              options={availableLocations.map(location => ({ value: location.Location, label: location.Location }))}
              onChange={handleLocationChange}
              value={(settingChartLocations && settingReportLocations)
                ? [...reduxChartLocations, ...reduxReportLocations].map(loc => ({ value: loc, label: loc }))
                : settingChartLocations
                ? reduxChartLocations.map(loc => ({ value: loc, label: loc }))
                : reduxReportLocations.map(loc => ({ value: loc, label: loc }))}
              styles={optimizedForExport ? exportDropdownStyles : dropdownStyles}
              placeholder="Choose area(s) or search an address on 'maps'..."
              menuPortalTarget={document.body}
              customSeparator={' vs. '}
              components={{ MultiValueLabel: (props) => (
                <CustomMultiValueLabel
                  {...props}
                  index={props.selectProps.value.findIndex(v => v.value === props.data.value)}
                />
              )}}
              />
          </div>
        )}
  
        {/* Geography Type Dropdown */}
        {settingMapGeographyType && (
          <div className='dropdown geography-type-dropdown'>
            <h3>Postal Districts</h3>
            {/*
            <Select
              options={availableGeographyTypes.map(type => ({ value: type, label: type }))}
              options={['Postal Districts']}
              onChange={handleGeographyTypeChange}
              value={{ value: selectedGeographyType, label: selectedGeographyType }}
              styles={optimizedForExport ? exportDropdownStyles : dropdownStyles}
              placeholder="Choose types of area to map..."
              menuPortalTarget={document.body}
              />*/}
          </div>
        )}

      </div>}
    </div>
  );
};

export default DataMenu;