import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  percentiles: [0, 0.05, 0.15, 0.5, 0.85, 0.95, 1],
  colours: ['#602424', '#B43030', '#D30000', '#FFB400', '#18AF15', '#0B7100', '#006444'],
  legendPercentiles: []
};

const mapScaleSlice = createSlice({
    name: 'scale',
    initialState,
    reducers: {
      setPercentiles: (state, action) => {
        state.percentiles = action.payload;
      },
      setColours: (state, action) => {
        state.colours = action.payload;
      },
      // If needed to set both at the same time
      setScale: (state, action) => {
        state.percentiles = action.payload.percentiles;
        state.colours = action.payload.colours;
      },
      // Scale legend percentiles 0-1 for colour baraccuracy
      setLegendColourPercentiles: (state, action) => {
        state.legendPercentiles = action.payload.legendPercentiles;
      }
    },
  });
  
  export const { setPercentiles, setColours, setScale, setLegendColourPercentiles } = mapScaleSlice.actions;
  export default mapScaleSlice.reducer;