import React from 'react';
import Grid, { GridCell } from '../DisplaySystem/Grid';
import LoadingWheel from '../Assets/LoadingSystem/LoadingWheel';


function LocationSelector() {
    return (
        <Grid>
          <GridCell>
            <h1>Investment Selector Tool</h1>
          </GridCell>
          <GridCell>
            <LoadingWheel/>
          </GridCell>
        </Grid>
    );
}

export default LocationSelector