// Parses a string in the format 'YYYYMM' to a Date object
export function parseYYYYMM(yyyymm) {
    if (typeof yyyymm !== 'string') {
        console.error('Invalid date format:', yyyymm);
        return new Date(); // Return the current date or handle the error as needed
    }
    const year = parseInt(yyyymm.substring(0, 4), 10);
    const month = parseInt(yyyymm.substring(4, 6), 10) - 1; // Convert to 0-based month
    return new Date(year, month);
}

// Reverses the above, creating a 'YYYYMM' date from a human readable month year string
export const reverseTranslateDate = (translatedDate) => {
    const [month, year] = translatedDate.split(' ');
    const monthNumber = new Date(Date.parse(`${month} 1, ${year}`)).getMonth() + 1;
    return `${year}${monthNumber.toString().padStart(2, '0')}`;
};

// Formats any js Date object to a string in the format 'YYYYMM'
export const ConvertDateToYYYYMM = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    return `${year}${month}`;
};

// Formats a JavaScript Date object to a string based on the provided format
export function formatDate(date, format) {
    const options = {
        year: 'numeric',
        month: 'name',
    };
    switch(format) {
        case 'year':
        options.month = undefined;
        break;
        case 'monthYear':
        options.month = 'short';
        break;
        default:
        // defaults to 'YYYY-MM'
        break;
    }
    return new Intl.DateTimeFormat('en-GB', options).format(date);
    }

// Determines the format based on the range of data
export function determineFormat(monthsNumber) {
    if (monthsNumber > 18) {
      return 'year'; // If the range covers more than two years, show just the year
    }
    return 'monthYear'; // Else show month and year
  }


// Date organisation functions just for the Data Menu
export const translateDate = (date) => {
    const options = { year: 'numeric', month: 'long' };
    return date.toLocaleDateString('en-GB', options);
  };
export const sortDatesDescending = (dates) => {
    return dates.sort((a, b) => parseYYYYMM(b) - parseYYYYMM(a));
};
export const translateAndSortDates = (dates) => {
    const sortedDates = sortDatesDescending(dates);
    return sortedDates.map(date => ({
        value: date,
        label: translateDate(parseYYYYMM(date))
    }));
};

// Supporting rounding function
export const roundToThreeSignificantFigures = (num) => {
    if (num === 0) return 0; // Directly handle the zero case.
    // Calculate the number of digits before the decimal point
    const digitsBeforeDecimal = Math.floor(Math.log10(Math.abs(num))) + 1;
    // Calculate the power to scale the number to around two significant digits
    const power = 3 - digitsBeforeDecimal;
    // Scale the number, round it, and then scale back
    const scaledNum = Math.round(num * Math.pow(10, power));
    const roundedNum = scaledNum / Math.pow(10, power);
    // Return the number ensuring it maintains up to two significant digits
    return parseFloat(roundedNum.toPrecision(3));
  };