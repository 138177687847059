import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import fetchDataFromAPI from './FetchDataAPIFunction';
import { APP_LOCAL_STORAGE_TIMEOUT_MAX_AGE } from '../config';
import { storeReportData } from '../redux/DataRequestSelections/fetchedDataSlice';
import { updateReportDataRefs } from '../redux/DataMenuSelections/reportsSlice'
import generateUniqueRequestRef from '../redux/DataRequestSelections/UniqueRequestReference';
import { useNavigate } from 'react-router-dom';
import { setReportPdfFileName } from '../redux/AppearancePersistence/appearancePersistenceSlice';
import { translateDate, parseYYYYMM } from './DataDateFormattingFunctions';


const useFetchReportDataOnSelectionChange = () => {
  const dispatch = useDispatch();
  const reportSelectedDate      = useSelector((state) => state.reports.selectedDate);
  const reportSelectedLocations = useSelector((state) => state.reports.selectedLocations);
  const fetchedReportData = useSelector(state => state.fetchedData.reportData);

  // Credits exhausted check system
  const [noCredits, setNoCredits] = useState(false);
  const navigate = useNavigate();
  useEffect (() => {
    if (noCredits) {
      navigate('/account');
    }
  }, [noCredits, navigate]);


  // Create simple functionality to update PDF export file name
  const UpdatePDFExportFileName = (fileName) => {
    dispatch(setReportPdfFileName(fileName));
  }
  // Supporting function to translate selected date for file name
  const simplifyDate = (date) => {
    return date ? translateDate(parseYYYYMM(date))  : null;
  };
  
 
  // Effect to fetch data on selection change
  useEffect(() => {
    const fetchAllReportData = async () => {
      let referencesToUpdate = [];

      for (const location of reportSelectedLocations) {

        // Generate a UniqueRequestRef for the current selection
        // This function takes params in the order of location, metric, date.
        const uniqueRequestRef = generateUniqueRequestRef([location], 'ReturnAll', reportSelectedDate);
        const currentTime = new Date().getTime();

        const dataEntry = fetchedReportData[uniqueRequestRef];
        // Skip fetching if data is fresh
        if (dataEntry && currentTime - new Date(dataEntry.timestamp).getTime() < APP_LOCAL_STORAGE_TIMEOUT_MAX_AGE) {
          //console.log('Reports data is fresh for:', location, 'Skipping API call.');
          referencesToUpdate.push(uniqueRequestRef); // Still add the reference to list of those checked because it is valid and fresh
          continue;
        }

        // Fetch and store data for this location
        try {
        // This function also takes params in the order of location, metric, date.
        // One of which can be set to 'ReturnAll' to return an array along that dimension.
        const reportData = await fetchDataFromAPI(location, 'ReturnAll', reportSelectedDate, setNoCredits);
        
        // Dispatch the action to store the fetched data in the Redux store
        dispatch(storeReportData({
          reference: uniqueRequestRef,
          data: reportData,
          timestamp: new Date().toISOString()
        }));
        // Update the current reference to the list of those checked
        referencesToUpdate.push(uniqueRequestRef);
        } catch (error) {
          console.error('Error fetching or storing requested report data for location:', location, error);
        }
      }

      // Update the array of current data references for charts
      if (referencesToUpdate.length > 0) {
        dispatch(updateReportDataRefs(referencesToUpdate));
      }
    };

    // Only run the fetch if the selected dependencies are truthy and report selected locations of greater length than zero
    if (reportSelectedDate && reportSelectedLocations.length > 0) {
      fetchAllReportData();
    }

    // Always update the PDF export file name
    UpdatePDFExportFileName('EvaluateLocate Report '+reportSelectedLocations[0]+' '+simplifyDate(reportSelectedDate)+'.pdf');

  // fetchedReportData is defined by other dependencies and its inclusion would also cause infinite loop. Hence next warning disable note.
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [reportSelectedDate, reportSelectedLocations, dispatch]);
};

export default useFetchReportDataOnSelectionChange;