const generateProbabilityMatrix = async (imagePath, rows, cols) => {
    const img = new Image();
    img.src = imagePath;

    // Wait for the image to load
    await new Promise((resolve, reject) => {
        img.onload = () => {
            if (img.width === 0 || img.height === 0) {
                reject(new Error('Image dimensions are zero.'));
            } else {
                resolve();
            }
        };
        img.onerror = () => reject(new Error('Image failed to load.'));
    });

    // Calculate aspect ratios
    const imgAspectRatio = img.width / img.height;
    const targetAspectRatio = cols / rows;

    let canvasWidth, canvasHeight;
    let offsetX = 0, offsetY = 0;

    if (imgAspectRatio > targetAspectRatio) {
        // Image is wider than the target aspect ratio
        canvasWidth = cols;
        canvasHeight = Math.floor(cols / imgAspectRatio);
        offsetY = Math.floor((rows - canvasHeight) / 2);
    } else {
        // Image is taller than the target aspect ratio
        canvasHeight = rows;
        canvasWidth = Math.floor(rows * imgAspectRatio);
        offsetX = Math.floor((cols - canvasWidth) / 2);
    }

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d', { willReadFrequently: true });

    canvas.width = cols;
    canvas.height = rows;

    // Ensure dimensions are integers and non-zero
    if (canvas.width <= 0 || canvas.height <= 0) {
        throw new Error('Canvas dimensions are zero.');
    }

    // Fill the canvas with white before drawing the image
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Draw the image centered on the canvas
    ctx.drawImage(img, offsetX, offsetY, canvasWidth, canvasHeight);

    // Ensure the image is fully drawn onto the canvas before proceeding
    await new Promise((resolve) => setTimeout(resolve, 50));

    try {
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const averageDarknessWholeImage = getAverageDarkness(imageData);

        const matrix = [];
        const sectorWidth = Math.max(1, Math.floor(canvas.width / cols));
        const sectorHeight = Math.max(1, Math.floor(canvas.height / rows));

        for (let y = 0; y < rows; y++) {
            const row = [];
            for (let x = 0; x < cols; x++) {
                const sectorImageData = ctx.getImageData(x * sectorWidth, y * sectorHeight, sectorWidth, sectorHeight);
                const averageDarknessSector = getAverageDarkness(sectorImageData);
                // This next line is the key bit which decides the probability function
                let probability = 0.9 + ((averageDarknessSector -averageDarknessWholeImage) / averageDarknessWholeImage)^0.5;
                probability = Math.max(0, Math.min(1, probability)); // Clamp probability between 0 and 1
                probability = probability +0.02
                probability = Math.max(0, Math.min(1, probability)); // Clamp probability between 0 and 1
                row.push(probability);
            }
            matrix.push(row);
        }

        return matrix;
    } catch (error) {
        console.error('Error retrieving image data from canvas:', error);
        throw error;
    }
};

const getAverageDarkness = (imageData) => {
    const data = imageData.data;
    let totalDarkness = 0;

    for (let i = 0; i < data.length; i += 4) {
        const r = data[i];
        const g = data[i + 1];
        const b = data[i + 2];
        const darkness = 255 - ((r + g + b) / 3);
        totalDarkness += darkness;
    }

    return totalDarkness / (data.length / 4);
};

export default generateProbabilityMatrix;