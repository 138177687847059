import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  recentLocations: []
};

const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    addLocation(state, action) {
      const isAddressUnique = !state.recentLocations.some(location => 
        location.formatted_address === action.payload.formatted_address
      );
      if (isAddressUnique) {
        state.recentLocations.push(action.payload);
      }
    },
    removeLocation(state, action) {
      state.recentLocations = state.recentLocations.filter(
        location => location.place_id !== action.payload.place_id
      );
    },
    clearLocations(state) {
      state.recentLocations = [];
    },
    addUniqueNewAvailableDataMenuAreas(state, action) {
      const { formatted_address, newAreas } = action.payload;
      const location = state.recentLocations.find(loc => loc.formatted_address === formatted_address);
      if (location) {
        // Ensure availableDataMenuAreas is initialized as an array if undefined
        if (!location.availableDataMenuAreas) {
          location.availableDataMenuAreas = [];
        }
        newAreas.forEach(area => {
          if (!location.availableDataMenuAreas.includes(area)) {
            location.availableDataMenuAreas.push(area);
          }
        });
      }
    }
    
  }
});

export const { addLocation, removeLocation, clearLocations, addUniqueNewAvailableDataMenuAreas } = locationsSlice.actions;
export default locationsSlice.reducer;