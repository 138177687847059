import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import UseWindowSize from '../../DisplaySystem/WindowSize';
import { setAdditionalGridCellHeightAllowanceCount } from '../../redux/AppearancePersistence/appearancePersistenceSlice';

const MetricDetailDropdown = ({ title, content }) => {

  // Get current additional grid cell allowance count in height management from Redux, and creat function to enable to set it
  const currentAdditionalGridCellHeightAllowanceCount = useSelector(state => state.appearancePersistence.additionalGridCellHeightAllowanceCount);
  const dispatch = useDispatch();

  // Open and close state and toggle function
  const [isOpen, setIsOpen] = useState(false);
  const [hasBeenToggled, setHasBeenToggled] = useState(false);
  const toggleDropdown = () => {
    // Add one to additionalGridCellHeightAllowanceCount if dropdown is currently closed and about to be opened, and subtract one if dropdown is open and about to be closed
    dispatch(setAdditionalGridCellHeightAllowanceCount(!isOpen? currentAdditionalGridCellHeightAllowanceCount + 1 : currentAdditionalGridCellHeightAllowanceCount -1));
    setIsOpen(!isOpen);
    setHasBeenToggled(true);  // User has manually interacted
  };

  // Window width and layout breakpoint
  const { width } = UseWindowSize();
  const horizontalLayoutBreakpoint = 1200;
  if (width > horizontalLayoutBreakpoint && !hasBeenToggled) {
    setTimeout(() => {
      setIsOpen(true);
      if(currentAdditionalGridCellHeightAllowanceCount<2 && !hasBeenToggled){
        dispatch(setAdditionalGridCellHeightAllowanceCount(currentAdditionalGridCellHeightAllowanceCount + 1));
      }
    }, 1000);
  }

  // Import universal state to double check if in exporting mode
  const isExporting = useSelector(state => state.appearancePersistence.exportingState);

  return (
    !isExporting && <div className="tipsDropdown">
      <button className="dropdownTitleSection" onClick={toggleDropdown} style={{opacity: isOpen? '0.5' : '1'}}>
        <div className="dropdownTitle">
          <p style={{margin: '0px'}}>{title}</p>
        </div>
        <div className="dropdownArrow">
          {!isOpen && <h4>&#x25BC;</h4>}
          {isOpen && <h4>&#x25B2;</h4>}
        </div>
      </button>
      <div className="dropdownContent" onClick={toggleDropdown} style={{ maxHeight: isOpen ? '50vh' : '0px' }}>
        {isOpen && <div>{content}</div>}
      </div>
    </div>
  );
};

export default MetricDetailDropdown;