export const RoutePermissions = {
    '/register':         { roles: ['Unknown'], requiresAuth: false },
    '/home':             { roles: ['SuperAdmin', 'Admin', 'PaidUser', 'User', 'NewRegistration', 'Unknown'], requiresAuth: false },
    '/map':              { roles: ['SuperAdmin', 'Admin', 'PaidUser', 'User', 'NewRegistration'], requiresAuth: true },
    '/time-series':      { roles: ['SuperAdmin', 'Admin', 'PaidUser', 'User', 'NewRegistration'], requiresAuth: true },
    '/location-reports': { roles: ['SuperAdmin', 'Admin', 'PaidUser', 'User', 'NewRegistration'], requiresAuth: true },
    '/account':          { roles: ['SuperAdmin', 'Admin', 'PaidUser', 'User', 'NewRegistration'], requiresAuth: true },
    '/pricing' :         { roles: ['SuperAdmin', 'Admin', 'PaidUser', 'User', 'NewRegistration'], requiresAuth: true },
    '/passwordreset':    { roles: [], requiresAuth: true },
    '/location-selector':{ roles: [], requiresAuth: true },
    '/portfolio':        { roles: [], requiresAuth: true },
    '/admin-page-secure':{ roles: [], requiresAuth: true }
};