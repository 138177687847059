import React, { useMemo, useEffect } from 'react';
import UseWindowSize from './WindowSize';
import GridCell from './GridCell';
import { useSelector, useDispatch } from 'react-redux';
import { ExportHeaderContent, ExportFooterContent } from '../ExportSystem/ExportHeaderFooter';
import { setAdditionalGridCellHeightAllowanceCount } from '../redux/AppearancePersistence/appearancePersistenceSlice';



const phi = (1 + Math.sqrt(5)) / 2;

const Grid = ({ children }) => {
    const { width } = UseWindowSize();
    const horizontalLayoutBreakpoint = 1200;

    // Additional allowance count can be set to an integer above zero by other parts of teh UI to allow for effectively more space to be avaialble in vertical layout
    // The main purpose of this is to allow scrolling in vertical layout when it may be needed but otherwise not allowed according to strict grid cell count
    const currentAdditionalGridCellHeightAllowanceCount = useSelector(state => state.appearancePersistence.additionalGridCellHeightAllowanceCount);
    
    // Monitor for if currentAdditionalGridCellHeightAllowanceCount has reached a level higher than the defined maximum below
    const maxAdditionalGridCellHeightAllowanceCount = 2;
    const dispatch = useDispatch();
    useEffect(() => {
        if (currentAdditionalGridCellHeightAllowanceCount > maxAdditionalGridCellHeightAllowanceCount) {
            dispatch(setAdditionalGridCellHeightAllowanceCount(maxAdditionalGridCellHeightAllowanceCount));
        }
    }, [currentAdditionalGridCellHeightAllowanceCount, maxAdditionalGridCellHeightAllowanceCount, dispatch]);



    const optimizedForExport = useSelector(state => state.appearancePersistence.optimizedForExport);


    // Convert children into an array and filter out non-GridCell children
    const gridCellChildren = useMemo(() => {
        let childrenArray = React.Children.toArray(children);
        return childrenArray;
        //return childrenArray.filter(child => child.type.name === 'GridCell'); <- Note this version not compatible with build process but the intended logic
    }, [children]);

    // Define the mapping for the order of the cells as they should appear on the page
    const cellOrderMapping = useMemo(() => {
        if (optimizedForExport) {
            // Optimized for export pdf grid layout has grid cells in the same *order* as for narrow screen layout
            return Array.from({ length: gridCellChildren.length }, (_, i) => i);
        }
        if (width < horizontalLayoutBreakpoint) {
            // Narrow screen layout has grid cells in simple order (no swapping of 0 and 1)
            return Array.from({ length: gridCellChildren.length }, (_, i) => i);
        }
        return [1, 0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
    }, [width, gridCellChildren.length, optimizedForExport]);

    // Ordered children based on the cellOrderMapping
    const orderedGridCellChildren = useMemo(() => {
        return gridCellChildren.map((child, index) => {
            const mappedPosition = cellOrderMapping[index];
            return gridCellChildren[mappedPosition];
        });
    }, [gridCellChildren, cellOrderMapping]);
    

    

    // EXPORT GRID VERSION - - - Separate style logic for optimized export pdf grid layout
    if (optimizedForExport) {
        const numChildren = orderedGridCellChildren.length;

            if (numChildren === 1) {
                return(
                    <div>
                        <ExportHeaderContent />
                        <div className='Grid' style={{ display: 'flex', flexDirection: 'column', width: '100%', height : '100%', overflowX: 'hidden', overflowY: 'hidden' }}>
                            <div className='GridCellContainer' style={{ display: 'flex', flexDirection: 'column', width: '100%', height : '100%', overflowX: 'hidden', overflowY: 'hidden' }}>
                                {orderedGridCellChildren[0]}
                            </div>
                        </div>
                        <ExportFooterContent />
                    </div>
                )
    
            } else if (numChildren === 2) {
                // This is the scenario for exporting to pdf with 2 grid cells, common for maps and charts currently
                return (
                    <div>
                        <ExportHeaderContent />
                        <div className='Grid' style={{ display: 'flex', flexDirection: 'column', alignItems: 'top', width: '100%', height : '1140px', overflowX: 'hidden', overflowY: 'hidden' }}>
                            <div className='GridCellContainer' style={{ zIndex: 9000, width: '1000px', height: '200px', overflowX: 'hidden', overflowY: 'hidden'}}>
                                {orderedGridCellChildren[0]}
                            </div>
                            <div className='GridCellContainer' style={{ zIndex: 8999,  width: '100%', height: '940px', overflowX: 'hidden', overflowY: 'hidden' }}>
                                {orderedGridCellChildren[1]}
                            </div>
                        </div>
                        <ExportFooterContent />
                    </div>
                )

            } else if (numChildren > 2) {
                // This is the scenario for exporting to pdf with more than 2 grid cells, i.e. for most reports
                // Calculate the number of rows and columns for the sub grid
                let subGridChildren = orderedGridCellChildren.slice(1, -1);
                if (orderedGridCellChildren.slice(1).length === 12) {
                    subGridChildren = orderedGridCellChildren.slice(1)
                }
                const numSubGridChildren = subGridChildren.length;
                const maxRows = 4;
                const minRows = 1;
                // Calculate the number of rows based on the number of relevant grid children
                let rows = Math.min(maxRows, numSubGridChildren/2);
                // Special case adjustment to rows number for 4 sub grid children
                if (numSubGridChildren===4) {rows=2;}
                // General case calculate the number of columns as ceiling to fit the relevant grid children with number of rows
                let columns = Math.ceil(numSubGridChildren / rows);
                // Special case adjustment next for handling if it gets down to only one column and if this is appropriate
                if (columns === 1 && numSubGridChildren > 1 && numSubGridChildren < 4) {
                    // Adjust columns to 2 only if doing so would not compress content unduly
                    rows = 1;
                    columns = numSubGridChildren;
                }
            
                // If the number of cells is not enough to fill the last row of the sub grid entirely, adjust the rows
                // Alternatively drop the number of rows if the effective number of columns for these rows would be one sole column
                while (columns > 1 && numSubGridChildren % rows === 1 && rows > minRows) {
                    rows--;
                    columns = Math.ceil(numSubGridChildren / rows);
                }

                // Prepare dynamic styling for rows and columns
                const rowHeight = 100 / rows; // Percent height per row
                const columnWidth = 100 / columns; // Percent width per column
            
                return (
                    <div style={{ marginLeft: '2px', marginRight: '2px'}}>
                        <div>
                            <ExportHeaderContent />
                            <div className='Grid' style={{width: '100%', height : '1140px', overflowX: 'hidden', overflowY: 'hidden' }}>
                                <div className='GridCellContainer' style={{ zIndex: 9000, width: '1000px', height: '200px', overflowX: 'hidden', overflowY: 'hidden'}}>
                                    {orderedGridCellChildren[0]}
                                </div>
                                <div className='GridCellContainer' style={{ zIndex: 8999,  width: '1000px', height: '940px', overflowX: 'hidden', overflowY: 'hidden'}}>
                                {Array.from({ length: rows }).map((_, rowIndex) => (
                                    <div key={rowIndex} className='Row' style={{ display: 'flex', flexDirection: 'row', width: '100%', height: `${rowHeight}%`, overflowX: 'hidden', overflowY: 'hidden'}}>
                                        {subGridChildren.slice(rowIndex * columns, (rowIndex + 1) * columns).map((child, index) => (
                                            <div key={index} className='GridCellContainer' style={{ flex: 1, zIndex: 9000 - index, minWidth: `${columnWidth}%`, overflowX: 'hidden', overflowY: 'hidden' }}>
                                                {child}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                                </div>
                            </div>
                            <ExportFooterContent />
                        </div>
                    </div>
                );
            }
    // End of scenario for exporting to pdf





    } else {
    // Main grid display version for use in application (not optimized for export pdf)
    // Determine kind of columns appropriate for screen size
    if (width < horizontalLayoutBreakpoint) {
        // Single column display version

        // Also evaluate number of grid cell children in this case to cosider if scrolling behaviour required
        const numChildren = orderedGridCellChildren.length + currentAdditionalGridCellHeightAllowanceCount;
        // Note that for the purposes of deciding the appropriate layout for narrow screens, the additional height allowance count is added
        if (numChildren < 3) {
            // Under two grid cell children will fit to screen not scroll, even on smaller screens
            return (
                <div className='Grid' style={{ display: 'flex', flexDirection: 'column', width: '100%',  height: 'calc(100vh - 55px)'}}>
                    {orderedGridCellChildren.map((child, i) => (
                        <div className='GridCellContainer' key={i} style={{ flex: 'auto', zIndex: 9000 - i, minHeight: '100px', overflowY: 'hidden'}}>
                            {child}
                        </div>
                    ))}
                </div>
            );
            }
        else {
            return (
                <div className='Grid' style={{ display: 'flex', flexDirection: 'column', width: '100%'}}>
                    {orderedGridCellChildren.map((child, i) => (
                        <div className='GridCellContainer' key={i} style={{ flex: 'auto', zIndex: 9000 - i, minHeight: '100px', overflowY: 'hidden'}}>
                            {child}
                        </div>
                    ))}
                </div>
            );
            }
        } else {
            // Multi column display version
            // Determine appropriate number of columns for right hand side
            const determineRowsAndColumns = (count) => {
                let columns = 1;
                let rows = count;
                const maxColumns = Math.ceil(count/2); // Set your desired maximum columns

                while (rows > columns && columns < maxColumns) {
                    columns++;
                    rows = Math.ceil(count / columns);
                }
            return { rows, columns };
            };
            
            // Extract children for right grid starting from index 2
            const rightGridChildren = [orderedGridCellChildren[0]].concat(orderedGridCellChildren.slice(2));
            const numChildren = orderedGridCellChildren.length;

            if (numChildren <= 1) {
                // Directly return the single child for wide screens
                const singleChild = gridCellChildren[0];
                return (
                    <div  className='Grid' style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', maxHeight: 'calc(100vh -55px)'}}>
                        <div className='GridCellContainer' style={{ flex: 1 }}>
                            {singleChild}
                        </div>
                    </div>
                );
            } else if (numChildren === 2) {
                //Main Grid logic with numChildren 2
                return (
                    <div  className='Grid' style={{ display: 'flex', flexDirection: 'row', width: '100%', maxHeight: 'calc(100vh -55px)'}}>
                        <div className='GridCellContainer' style={{ flex: 1/phi, zIndex: 9000, minHeight: '100%', display: 'flex', flexDirection: 'column', maxHeight: '100%'}}>
                            {orderedGridCellChildren[1]}
                        </div>
                        <div className='GridCellContainer' style={{ flex: 1 }}>
                            {orderedGridCellChildren[0]}
                        </div>
                    </div>
                );
            } else {
                    // Calculations for rightGridChildren                
                    const { rows, columns } = determineRowsAndColumns(rightGridChildren.length);
                    //const lastRowCells = rightGridChildren.length - (columns * (rows - 1)); //Optional logic for separate column count for last row

                return (
                    <div  className='Grid' style={{ display: 'flex', flexDirection: 'row', width: '100%', maxHeight: 'calc(100vh -55px)'}}>
                        <div className='GridCellContainer' style={{ flex: 1/phi, zIndex: 9000, minHeight: '100%', display: 'flex', flexDirection: 'column', maxHeight: '100%'}}>
                            {orderedGridCellChildren[1]}
                        </div>
                        <div style={{ flex: 1, minHeight: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                            {rightGridChildren.map((child, index) => {
                                    //const isLastRow = index >= (columns * (rows - 1));
                                    return (
                                        <div className='GridCellContainer' key={index} 
                                        style={{ 
                                                flexBasis: `${100 / columns}%`, 
                                                flexGrow: 0, 
                                                flexShrink: 0, 
                                                height: `${100 / rows}%`, 
                                                display: 'flex', 
                                                flexDirection: 'column' 
                                        }}>
                                        {child}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                );
            }
        }
    }
}

export { GridCell };
export default Grid;