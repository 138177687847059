const plusButtonDropdownStyles = {
  container: (provided, state) => ({
    ...provided,
    width: '100%',
    margin: '0px',
    padding: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  control: (provided, state) => ({
    ...provided,
    fontSize: state.isFocused ? 18: 100,
    color: '#ffffff',
    backgroundColor: state.isSelected ? '#006b73': '#012f3e',
    borderColor: state.isSelected ? '#ffffff' : '#012f3e',
    opacity: '100%',
    minHeight: '20px',
    minWidth: '200px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px',
    margin: '0px',
    overflowY: 'auto',
    flexWrap: 'wrap',
    borderRadius: '7px',
    borderWidth: '2px',
    border: 'none',
    ':hover': {
      borderColor: '#008B95',
      borderWidth: '3px',
      borderStyle: 'solid'
    }
  }),
  option: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? '#008B95' : '#006b73',
    color: state.isSelected ? '#73C8CE' : '#ffffff',
    backgroundColor: state.isFocused ? '#008B95' : '#012F3E',
    padding: '7px 5px',
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    minWidth: '200px',
    display: 'flex',
    whiteSpace: 'normal', // Allows text to wrap within options
    alignItems: 'center',
    justifyContent: 'flex-start'
  }),
  menu: (provided, state) => ({
    ...provided,
    border: state.isFocused ? '1px solid #ffffff': '#008b958a',
    backgroundColor: state.isSelected ? '#008B95' : '#012F3E', // Change background color for selected options
    borderColor: state.isSelected ? '#008B95' : '#012F3E',
    marginTop: '0px',
    width: '100%',
    minWidth: '250px',
    alignItems: 'flex-start',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: '8px'
  }),
  menuList: (provided, state) => ({
    ...provided,
    fontSize: 16,
    border: state.isFocused ? '1px solid #ccc': '#08b958a',
    maxHeight: '250px',
    width: '100%',
    paddingTop: 0,
    paddingBottom: 0,
    alignItems: 'left',
    justifyContent: 'left',
    textAlign: 'left',
    borderRadius: '8px'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#012F3E',
    alignItems: 'left',
    justifyContent: 'left',
    textAlign: 'left'
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    fontSize: 20,
    color: '#ffffff',
    transition: 'opacity 300ms ease',
    alignItems: 'center',
    justifyContent: 'center'
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    color: '#bcbcbc',
    height: '40px',
    width: '40px',
    alignItems: 'center',
    justifyContent: 'center',
    ':hover': {
      backgroundColor: '#4C0805',
      color: '#ffffff',
    },
    ':click': {
      backgroundColor: '#4C0805',
      color: '#ffffff',
    },
  }),
  input: (provided, state) => ({
    ...provided,
    color: '#008B95',
    margin: '0px',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    marginLeft: '30px',
    marginBottom: '10px',
    color: '#ccc',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#ffffff',
    transition: 'opacity 300ms ease',
    opacity: state.isDisabled ? 0.5 : 1,
    alignItems: 'left',
    justifyContent: 'left',
    width: '100%',
    whiteSpace: 'normal', // Allows text to wrap within selected value
    ':hover': {
      backgroundColor: '#008B95',
      borderWidth: '1px',
      borderColor: '#ffffff',
    },
  }),
  valueContainer: (provided, state) => ({
  ...provided,
  padding: '0 8px',
  alignItems: 'center',
  justifyContent: 'center',
  ':hover': {
      borderWidth: '1px',
      borderColor: '#ffffff',
    },
  }),
  indicatorsContainer: (provided, state) => ({
  ...provided,
  height: '40px',
  color: '#008B95',
  ':hover': {
  color: '#008B95',
  },
  }),
  indicatorSeparator: (provided, state) => ({
  ...provided,
  marginLeft: '10px',
  backgroundColor: state.isSelected ? '#008B95' : '#012f3e',
  width: 2,
  ':hover': {
  color: '#008B95',
  },
  }),
  dropdownIndicator: (provided, state) => ({
  ...provided,
  color: state.isSelected ? '#008B95' : '#012f3e',
  ':hover': {
  color: '#008B95'
  },
  }),
  loadingIndicator: (provided, state) => ({
  ...provided,
  color: '#008B95',
  }),
  loadingMessage: (provided, state) => ({
  ...provided,
  color: '#ffffff',
  fontSize: '16px',
  }),
  noOptionsMessage: (provided, state) => ({
  ...provided,
  text: 'No options available, check connection',
  color: '#ffffff',
  fontSize: '16px',
  }),
  group: (provided, state) => ({
  ...provided,
  paddingBottom: '10px',
  paddingTop: '10px',
  ':hover': {
      backgroundColor: '#008B95',
      border: '2px',
      borderColor: '#ffffff',
    },
  }),
  groupHeading: (provided, state) => ({
  ...provided,
  color: '#ffffff',
  fontSize: '18px',
  }),
  clearIndicator: (provided, state) => ({
  ...provided,
  color: '#bcbcbc',
    ':hover': {
      backgroundColor: '#4C0805',
      color: '#ffffff',
      borderColor: '#ffffff',
    },
    ':click': {
      backgroundColor: '#4C0805',
      color: '#ffffff',
    },
  }),
  menuPortal: (provided, state) => ({
  ...provided,
  zIndex: 9999
  }),
};

export default plusButtonDropdownStyles;