import React, { useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Layout               from './DisplaySystem/Layout';
import AccountPage          from './Pages/AccountPage';
import Home                 from './Pages/Home';
import Register             from './Pages/RegisterPage';
import Pricing              from './Pages/PricingPage';
import MapsPage             from './Pages/MapsPage';
import ChartsPage           from './Pages/ChartsPage';
import ReportsPage          from './Pages/ReportsPage';
import LocationSelectorPage from './Pages/LocationSelectorPage';
import PortfolioPage        from './Pages/PortfolioPage';
import AccessDenied         from './Pages/AccessDenied';
import AdminPageSecure      from './Pages/AdminPageSecure';
import { WithPermissions }  from './Authentication/PermissionLevels/WithPermissions';
import { AuthProvider }     from './Authentication/PermissionLevels/AuthContext';
import AuthContext          from './Authentication/PermissionLevels/AuthContext';
import { getAuthStateFromLocalStorage } from './Authentication/AccountManagement/LoginForm';
import { RoutePermissions } from './Authentication/PermissionLevels/RoutePermissions';
import { InstallPromptProvider } from './Authentication/Installation/InstallPromptContext';


function App() {
  const { dispatch } = useContext(AuthContext); // Get the dispatch function from the AuthContext

  useEffect(() => {
    const storedAuthState = getAuthStateFromLocalStorage();
    if (storedAuthState) {
      // Here we dispatch an action to update the state in our AuthProvider
      dispatch({ type: 'LOG_IN', payload: storedAuthState });
    }
  }, [dispatch]); // Add dispatch to the dependency array

    // Function to reset the data menu refresh timer in local storage to ensure it loads on opening the app
    const resetDataMenuTimer = () => {
      localStorage.setItem('dataMenuLastUpdated', new Date(0).toISOString()); // Set to a past date
    };
    // Call the function when the App component mounts
    resetDataMenuTimer();

  // Set access to pages by Permission and Authentication levels
  const RegisterWithPermissions = WithPermissions(RoutePermissions['/register'])(Register);
  const PricingWithPermissions = WithPermissions(RoutePermissions['/pricing'])(Pricing);
  const AccountPageWithPermissions = WithPermissions(RoutePermissions['/account'])(AccountPage);
  const MapsPageWithPermissions = WithPermissions(RoutePermissions['/map'])(MapsPage);
  const ChartsPageWithPermissions = WithPermissions(RoutePermissions['/time-series'])(ChartsPage);
  const ReportsPageWithPermissions = WithPermissions(RoutePermissions['/location-reports'])(ReportsPage);
  const LocationSelectorPageWithPermissions = WithPermissions(RoutePermissions['/location-selector'])(LocationSelectorPage);
  const PortfolioPageWithPermissions = WithPermissions(RoutePermissions['/portfolio'])(PortfolioPage);
  const AdminPageSecureWithPermissions = WithPermissions(RoutePermissions['/admin-page-secure'])(AdminPageSecure);

  return (
    <InstallPromptProvider>
      <AuthProvider>
        <Router>
            <Layout>
                <Routes>
                  <Route path="/" element={<Navigate to="/home" replace />} />
                  <Route path="/home" element={<Home />} />
                  <Route path="/register" element={<RegisterWithPermissions />} />
                  <Route path="/pricing" element={<PricingWithPermissions />} />
                  <Route path="/account" element={<AccountPageWithPermissions />} />
                  <Route path="/map" element={<MapsPageWithPermissions />} />
                  <Route path="/time-series" element={<ChartsPageWithPermissions />} />
                  <Route path="/location-reports" element={<ReportsPageWithPermissions />} />
                  <Route path="/location-selector" element={<LocationSelectorPageWithPermissions />} />
                  <Route path="/portfolio" element={<PortfolioPageWithPermissions />} />
                  <Route path="/access-denied" element={<AccessDenied />} />
                  <Route path="/admin-page-secure" element={<AdminPageSecureWithPermissions />} />
                </Routes>
            </Layout>
        </Router>
      </AuthProvider>
    </InstallPromptProvider>
  );
}

export default App;