import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect, useCallback, useRef } from 'react';

function useURLQuery(debounceDelay = 100) {
  const currentLocation = useLocation();
  const navigate = useNavigate();
  const debounceTimeout = useRef(null); // Track the debounce timeout

  // Use state to manage all params
  const [params, setParams] = useState(() => new URLSearchParams(currentLocation.search));

  // Update params whenever the location changes
  useEffect(() => {
    setParams(new URLSearchParams(currentLocation.search));
  }, [currentLocation.search]);

  // Function to update the params (debounced)
  const updateParams = useCallback(
    (paramsToUpdate) => {
      // Ensure paramsToUpdate is a valid object with changes
      if (!paramsToUpdate || typeof paramsToUpdate !== 'object' || Object.keys(paramsToUpdate).length === 0) {
        //console.log("No parameters to update. Skipping updateParams.");
        return; // Exit early if no valid updates
      }

      // Clear the existing debounce timeout if there is one
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(() => {
        const updatedParams = new URLSearchParams(currentLocation.search);

        // Loop through each parameter and update/delete it accordingly
        Object.entries(paramsToUpdate).forEach(([key, value]) => {
          if (typeof key !== 'string' || key.trim() === "") {
            console.error('Invalid parameter key:', key);
            return; // Ensure keys are valid strings
          }

          if (value === null || value === undefined) {
            updatedParams.delete(key);
          } else {
            updatedParams.set(key, value);
          }
        });

        // Generate the new URL to navigate to
        const newUrl = `${currentLocation.pathname}?${updatedParams.toString()}`;

        // Check if the new URL is different from the current one
        if (newUrl !== `${currentLocation.pathname}${currentLocation.search}`) {
          navigate(newUrl, { replace: true });
        }
      }, debounceDelay);
    },
    [currentLocation.pathname, currentLocation.search, navigate, debounceDelay]
  );

  // Return the current params and the function to update them
  return [params, updateParams];
}

export default useURLQuery;