import React from 'react';
import Grid, { GridCell } from '../DisplaySystem/Grid';

function Portfolio() {
    return (
        <Grid>
            <GridCell>
                <p>This is the Portfolio page placeholder</p>
            </GridCell>
       </Grid>
    );
}

export default Portfolio;