import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  availableLocations: [],
  availableMetrics: [],
  availableDates: [],
  availableGeographyTypes: [],
};

const dataMenuSlice = createSlice({
  name: 'dataMenu',
  initialState,
  reducers: {
    setAvailableLocations: (state, action) => {
      state.availableLocations = action.payload;
    },
    setAvailableMetrics: (state, action) => {
      state.availableMetrics = action.payload;
    },
    setAvailableDates: (state, action) => {
      state.availableDates = action.payload;
    },
    setAvailableGeographyTypes: (state, action) => {
      state.availableGeographyTypes = action.payload;
    },
  },
});

export const { 
  setAvailableLocations, 
  setAvailableMetrics, 
  setAvailableDates, 
  setAvailableGeographyTypes,
} = dataMenuSlice.actions;

export default dataMenuSlice.reducer;
