import { createSelector } from 'reselect';

// Base selectors
const getCurrentDataRef = (state) => state.maps.currentDataRef;
const getAllMapData = (state) => state.fetchedData.mapData;

// Selectors created
export const selectMapData = createSelector(
  [getCurrentDataRef, getAllMapData],
  (currentDataRef, mapData) => mapData[currentDataRef]
);