import { createSlice } from '@reduxjs/toolkit';

const appearancePersistenceSlice = createSlice({
  name: 'appearancePersistence',
  initialState: {
    optimizedForExport: false,
    exportingState: false,
    isLoading: false,
    additionalGridCellHeightAllowanceCount: 0,
    mapPdfFileName: 'EvaluateLocate Map.pdf',
    chartPdfFileName: 'EvaluateLocate Chart.pdf',
    reportPdfFileName: 'EvaluateLocate Report.pdf',
    defaultPdfFileName: 'EvaluateLocate Exported Report.pdf',
    newUserGuideVersion: false,
    chartDateRangePersist: ['201401', '202512'],
  },
  reducers: {
    setExportOptimization: (state, action) => {
      state.optimizedForExport = action.payload;
    },
    setExportingState: (state, action) => {
      state.exportingState = action.payload;
    },
    setLoading: (state, action) => {
        state.isLoading = action.payload;
    },
    setAdditionalGridCellHeightAllowanceCount: (state, action) => {
      state.additionalGridCellHeightAllowanceCount = action.payload;
    },
    setMapPdfFileName: (state, action) => {
      state.mapPdfFileName = action.payload;
    },
    setChartPdfFileName: (state, action) => {
      state.chartPdfFileName = action.payload;
    },
    setReportPdfFileName: (state, action) => {
      state.reportPdfFileName = action.payload;
    },
    setDefaultPdfFileName: (state, action) => {
      state.defaultPdfFileName = action.payload;
    },
    setNewUserGuideVersion: (state, action) => {
      state.newUserGuideVersion = action.payload;
    },
    setChartDateRangePersist: (state, action) => {
      state.chartDateRangePersist = action.payload;
    }
}
});

export const { setExportOptimization, setExportingState, setLoading, setAdditionalGridCellHeightAllowanceCount, setMapPdfFileName, setChartPdfFileName, setReportPdfFileName, setDefaultPdfFileName, setNewUserGuideVersion, setChartDateRangePersist } = appearancePersistenceSlice.actions;
export default appearancePersistenceSlice.reducer;