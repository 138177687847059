import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from     '../../config';
import ErrorBox from        '../../Components/InfoComponents/ErrorBox';
import LoadingWheel from    '../../Assets/LoadingSystem/LoadingWheel';


function PasswordChangeForm({onPasswordChange}) {
    const [passwordFormData, setPasswordFormData] = useState({
        OldPassword: '',
        NewPassword: '',
        NewPasswordConfirm: '',
    });

    const [error, setError] = useState('');
    // Loading state for submitting info
    const [isLoading, setIsLoading] = useState(false);


    const handlePasswordFormChange = (event) => {
        setPasswordFormData({
            ...passwordFormData,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmitPasswordChange = async (event) => {
        event.preventDefault();
        
        setError('');
        setIsLoading(true);

        if (passwordFormData.NewPassword !== passwordFormData.NewPasswordConfirm) {
            setError('New password does not match confirmation');
            setIsLoading(false);
            return;
        }

        if (passwordFormData.NewPassword.length < 8 || passwordFormData.NewPassword.length > 30) {
            setError('Password must be between 8 and 30 characters.');
            setIsLoading(false);
            return;
        }
        try {
            const token = localStorage.getItem('userToken');
            const response = await axios.put(
                `${API_URL}/UpdatePassword`, 
                {
                    OldPassword: passwordFormData.OldPassword,
                    NewPassword: passwordFormData.NewPassword
                }, 
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            
            //console.log(response);

            if (response.status === 200) {
                setError('Password updated successfully!');
                onPasswordChange();
            } else {
                setError('Failed to update password. Status: ' + response.status);
            }
        } catch (error) {
            //console.error(error);
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setError('Validation error: ' + error.response.data.message);
                        break;
                    case 404:
                        setError('User not found');
                        break;
                    case 401:
                        setError('Old password is incorrect');
                        break;
                    case 429:
                        setError('Too many requests. Try again later for security reasons.');
                        break;
                    case 500:
                        setError('Server error: ' + error.response.data.message);
                        break;
                    default:
                        setError('An error occurred. Please try again.');
                        break;
                }
                //console.error('Server Response:', error.response);
            } else {
                setError('Failed to update password. Please check your network connection, sign in again and try again.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="form-box">
            <h3>Update Password</h3>
            {isLoading && <LoadingWheel/>}
            {!isLoading && <div>
                <ErrorBox message={error} />
                <form onSubmit={handleSubmitPasswordChange}>
                    <input
                        className="form-password-input"
                        type="password"
                        name="OldPassword"
                        placeholder="Current password"
                        onChange={handlePasswordFormChange}
                    />
                    <input
                        className="form-password-input"
                        type="password"
                        name="NewPassword"
                        placeholder="New password"
                        onChange={handlePasswordFormChange}
                    />
                    <input
                        className="form-password-input"
                        type="password"
                        name="NewPasswordConfirm"
                        placeholder="Confirm new password"
                        onChange={handlePasswordFormChange}
                    />
                    <br/>
                    <p>After changes you will need to log in again</p>
                    <button className="form-button muted-border" type="submit"><p>Submit</p></button>
                </form>
            </div>}

        </div>
    );
}

export default PasswordChangeForm;