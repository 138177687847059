import React from 'react';
import './LoadingWheel.css';

function LoadingWheel({isExportVersion=false}) {
  return (
    <div className="loader" aria-label="Loading content">
      <div className="loader-inner" style={{top: isExportVersion ? '25%' : '50%'}}>
        {[...Array(7)].map((_, index) => (
          <div key={index} className="loader-line-wrap">
            <div className="loader-line"></div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default LoadingWheel;