import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import fetchDataFromAPI from './FetchDataAPIFunction';
import { APP_LOCAL_STORAGE_TIMEOUT_MAX_AGE } from '../config';
import { storeChartData } from '../redux/DataRequestSelections/fetchedDataSlice';
import { updateChartDataRefs } from '../redux/DataMenuSelections/chartsSlice'
import generateUniqueRequestRef from '../redux/DataRequestSelections/UniqueRequestReference';
import { useNavigate } from 'react-router-dom';
import { setChartPdfFileName } from '../redux/AppearancePersistence/appearancePersistenceSlice';

const useFetchChartDataOnSelectionChange = () => {
  const dispatch = useDispatch();
  const chartSelectedMetric = useSelector((state) => state.charts.selectedMetric);
  const chartSelectedLocations = useSelector((state) => state.charts.selectedLocations);
  const fetchedChartData = useSelector(state => state.fetchedData.chartData);

  // Credits exhausted check system
  const [noCredits, setNoCredits] = useState(false);
  const navigate = useNavigate();
  useEffect (() => {
    if (noCredits) {
      navigate('/account');
    }
  }, [noCredits, navigate]);

  // Create simple functionality to update PDF export file name
  const UpdatePDFExportFileName = (fileName) => {
    dispatch(setChartPdfFileName(fileName));
  }


  useEffect(() => {
    const fetchAllChartData = async () => {
      let referencesToUpdate = [];

      for (const location of chartSelectedLocations) {

        // Generate a UniqueRequestRef for the current selection
        // This function takes params in the order of location, metric, date.
        const uniqueRequestRef = generateUniqueRequestRef([location], chartSelectedMetric, 'ReturnAll');
        const currentTime = new Date().getTime();

        const dataEntry = fetchedChartData[uniqueRequestRef];
        // Skip fetching if data is fresh
        if (dataEntry && currentTime - new Date(dataEntry.timestamp).getTime() < APP_LOCAL_STORAGE_TIMEOUT_MAX_AGE) {
          //console.log('Charts data is fresh for:', location, 'Skipping API call.');
          referencesToUpdate.push(uniqueRequestRef); // Still add the reference to list of those checked because it is valid and fresh
          continue;
        }

        // Fetch and store data for this location
        try {
        // This function also takes params in the order of location, metric, date.
        // One of which can be set to 'ReturnAll' to return an array along that dimension.
        const chartData = await fetchDataFromAPI(location, chartSelectedMetric, 'ReturnAll', setNoCredits);
        // Dispatch the action to store the fetched data in the Redux store
        dispatch(storeChartData({
          reference: uniqueRequestRef,
          data: chartData,
          timestamp: new Date().toISOString()
        }));
        // Update the current reference to the list of those checked
        referencesToUpdate.push(uniqueRequestRef);
        } catch (error) {
          console.error('Error fetching or storing requested chart data for location:', location, error);
        }
      }

      // Update the array of current data references for charts
      if (referencesToUpdate.length > 0) {
        dispatch(updateChartDataRefs(referencesToUpdate));
      }
    };

    // Only run the fetch if the selected dependencies are truthy and chart selected locations of greater length than zero
    if (chartSelectedMetric && chartSelectedLocations.length > 0) {
      fetchAllChartData();
    }

  // Always update the PDF export file name
  UpdatePDFExportFileName('EvaluateLocate Chart Export '+chartSelectedMetric+' '+chartSelectedLocations[0]+'.pdf');

  // fetchedChartData is defined by other dependencies and its inclusion would also cause infinite loop. Hence next warning disable note.
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [chartSelectedMetric, chartSelectedLocations, dispatch]);

};

export default useFetchChartDataOnSelectionChange;