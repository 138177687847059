import React from 'react';
import linkedinLogo from '../Assets/SocialShareLogos/linkedin.png';
import whatsappLogo from '../Assets/SocialShareLogos/whatsapp.png';
import facebookLogo from '../Assets/SocialShareLogos/facebook.png';
import twitterLogo from '../Assets/SocialShareLogos/x.png';
import emailSymbol from '../Assets/SocialShareLogos/email.png';

const SocialMediaShare = ({shareUrlInput, suggestedTextInput}) => {

  //Takes params, or sets defaults below
  const currentUrl = window.location.href;
  const shareUrl = shareUrlInput ? shareUrlInput : currentUrl;
  const suggestedText = suggestedTextInput ? suggestedTextInput : "EvaluateLocate - You need to see what I'm looking at";

  return (
    <div className="social-media-share-container">
        <div className="social-media-share-banner">
                <button className="social-share-button"
                    onClick={() => window.open(`mailto:?subject=${encodeURIComponent(suggestedText)}&body=${encodeURIComponent(suggestedText + ' ' + shareUrl)}`, '_blank', 'noopener noreferrer')}
                    >
                    <img src={emailSymbol} alt="Email symbol" className="social-share-image" /> 
                </button>
                <button className="social-share-button"
                    onClick={() => window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(suggestedText + ' ' + shareUrl)}`, '_blank', 'noopener noreferrer')}
                    >
                    <img src={whatsappLogo} alt="WhatsApp logo" className="social-share-image" />
                </button>
                <button className="social-share-button"
                    onClick={() => window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(shareUrl)}&summary=${encodeURIComponent(suggestedText)}`, '_blank', 'noopener noreferrer')}
                    >
                    <img src={linkedinLogo} alt="LinkedIn logo" className="social-share-image" />
                </button>
                <button className="social-share-button"
                    onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}&quote=${encodeURIComponent(suggestedText)}`, '_blank', 'noopener noreferrer')}
                    >
                    <img src={facebookLogo} alt="Facebook logo" className="social-share-image" />
                </button>
                <button className="social-share-button"
                    onClick={() => window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(suggestedText)}`, '_blank', 'noopener noreferrer')}
                    >
                    <img src={twitterLogo} alt="X logo" className="social-share-image" />
                </button>

        </div>
    </div>

    
  );
};

export default SocialMediaShare;