import {React, useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { STRINGS_TO_REVERSE_SCALE } from '../../config';
import {hexToHsl, interpolateHsl} from '../../DisplaySystem/ColourSupportFunctions';
import {determineStatPrefix, determineStatSuffix} from '../../RequestDataSystem/DataMetricFormatFunctions';
import { formatDate, parseYYYYMM } from '../../RequestDataSystem/DataDateFormattingFunctions';
import Select from 'react-select';
import dropdownStyles from '../../DisplaySystem/DropdownsStyles/KeyStatsCustomDropdownStyles';
import exportDropdownStyles from '../../DisplaySystem/DropdownsStyles/KeyStatsCustomDropdownStylesExport';
import { updateReportMetric, removeReportMetric, shiftUpReportMetric, shiftDownReportMetric } from '../../redux/DataMenuSelections/reportsSlice';
import Modal from '../InfoComponents/ModalBox';
import {
    updateMapDate,
    updateMapMetric,
  } from '../../redux/DataMenuSelections/mapsSlice';
  import {
    updateChartLocations,
    updateChartMetric,
} from '../../redux/DataMenuSelections/chartsSlice';
import { useNavigate } from 'react-router-dom';

// Supporting rounding function
const roundToThreeSignificantFigures = (num) => {
    if (num === 0) return 0; // Directly handle the zero case.
    // Calculate the number of digits before the decimal point
    const digitsBeforeDecimal = Math.floor(Math.log10(Math.abs(num))) + 1;
    // Calculate the power to scale the number to around two significant digits
    const power = 3 - digitsBeforeDecimal;
    // Scale the number, round it, and then scale back
    const scaledNum = Math.round(num * Math.pow(10, power));
    const roundedNum = scaledNum / Math.pow(10, power);
    // Return the number ensuring it maintains up to two significant digits
    return parseFloat(roundedNum.toPrecision(3));
  };

// Define universal colour array
const coloursArray =['#591919', '#881212', '#C43200', '#D29400', '#169813', '#096323', '#08563D'];


// Translate colour array into a colour scheme linked to an input ratio, locationMetricRatio
function sigmoid(x) {
    return 1 / (1 + Math.exp(-x));
  }

  function calculateContinuousColour(ratio) {
    const colourHslPairs = coloursArray.map(hexToHsl);

    // Normalize the ratio between 0 and 1
    let boundedRatio = ratio;
    if (ratio > 10) {boundedRatio =10};

    const finalRatio = sigmoid(boundedRatio);

    // Find the segment of the ratio
    const segment = Math.floor(finalRatio * (colourHslPairs.length - 1));
    const segmentFraction = (finalRatio * (colourHslPairs.length - 1)) - segment;

    // Get the interpolated colour
    if (segment < colourHslPairs.length - 1) {
        return interpolateHsl(colourHslPairs[segment], colourHslPairs[segment + 1], segmentFraction);
    }

    // Return the last colour if ratio is exactly 1
    return `hsl(${colourHslPairs[colourHslPairs.length - 1].join(", ")})`;
}

function findMinValue(locations) {
    // Check if locations array is empty or undefined, return undefined in such a case to indicate no data is present
    if (!locations || locations.length === 0) {
        return undefined;
    }
    // Use the reduce method to find and return the maximum value among all locations
    return locations.reduce((max, location) => {
        return Math.min(max, location.value);
    }, +Infinity); // Start with +Infinity to ensure any negative value is higher
}




// Main return function
function KeyStatWindow({ metricName, locations, metricIndex, lastMetric }) {

    // Get universal pdf export optimisation state
    const optimizedForExport = useSelector(state => state.appearancePersistence.optimizedForExport);
    // Adapt dropdown styles for export mode if required
    const [dropDownStylesInUse, setDropDownStylesInUse] = useState(dropdownStyles);
    useEffect (() => {
        if (optimizedForExport) {
            setDropDownStylesInUse(exportDropdownStyles);
        } else {
            setDropDownStylesInUse(dropdownStyles);
        }
    }, [optimizedForExport])


    // Extract the primary location and secondary locations separately
    const primaryLocation = locations[0];
    const secondaryLocations = locations.slice(1); // All locations except the first

    // Define background colours, initially empty
    const [backgroundColours, setBackgroundColours] = useState([]);
    // Define dynamic border colour alongside background colour
    const [dynamicBorderColour, setDynamicBorderColour] = useState('#008B95');

    // Get state for colour coding toggle
    const colourCodingSetting = useSelector(state => state.reports.reportColourCoding);

    // Set up true/false logic for reversing scale if certain strings appear in the metric name
    const [reverseScaleSetting, setReverseScaleSetting] = useState(false);

    // Import available metrics
    const availableMetrics = useSelector(state => state.dataMenu.availableMetrics);

    // Initialise actual list of metrics for selector to display, initially just the available metrics
    const metricsForSelector = availableMetrics.map(metric => ({ value: metric.Metric, label: metric.Metric }));
    

    // Watch metric name, to check if it includes any of the strings that require reversing the scale
    useEffect(() => {
        if (typeof metricName === 'string') {
            const stringsToReverseScale = STRINGS_TO_REVERSE_SCALE;
            const lowerCaseDataRef = metricName.toLowerCase();
            setReverseScaleSetting(stringsToReverseScale.some(str => lowerCaseDataRef.includes(str)));
        }
    },[metricName])

    // Redux dispatch
    const dispatch = useDispatch();
    // Create handler for metric selection, dispatching this to redux at the correct index value in the selected metrics array
    const handleKeyStatMetricSelection = selectedOption => {
        dispatch(updateReportMetric({ index: metricIndex, newValue: selectedOption.value }));
    };

    // Handler for removing the metric
    const handleKeyStatMetricRemoval = () => {
        dispatch(removeReportMetric({ index: metricIndex }));
    };
    // Handler for shifting up the metric in the array
    const handleKeyStatShiftUp = () => {
        dispatch(shiftUpReportMetric({ index: metricIndex }));
    };
    // Handler for shifting down the metric in the array
    const handleKeyStatShiftDown = () => {
        dispatch(shiftDownReportMetric({ index: metricIndex }));
    };


    // Use effect to set background colours, also considering when colour coding is toggled
    useEffect(() => {
        // Check for valid locations of non zero length before main logic
        if (locations && locations.length > 0) {
            const colours = locations.map((location, index) => {
                if (colourCodingSetting) {
                    // Check if the current location is the last in the array
                    if (index === locations.length - 1) {
                        return '#4F4F4F';
                    } else {
                        // Define locationMetricRatio within this scope just for calculating each individual colour
                        let referenceValue = locations[locations.length - 1].value; // The last value in sequence is reference
                        let comparisonValue = location.value;
                        
                        // Update values if required so that comparing ratios of positive numbers
                        const locationsValueLowerBound = findMinValue(locations);
                        if(locationsValueLowerBound<0) {
                            referenceValue  = referenceValue  - 2* locationsValueLowerBound;
                            comparisonValue = comparisonValue - 2* locationsValueLowerBound;
                        }
                        let locationMetricRatio = (comparisonValue-referenceValue)/referenceValue;
            
                        if(reverseScaleSetting) {
                            locationMetricRatio = -locationMetricRatio;
                        }

                        const newColour = calculateContinuousColour(locationMetricRatio);
                        return newColour;
                    }
                } else  {
                    if (index === 0) {
                        return '#006b73';
                    } else {
                        return '#012F3E';
                    }
                }
            
            });
            setBackgroundColours(colours);
        }
    }, [locations, metricName, reverseScaleSetting, colourCodingSetting]);
    // Similar for dynamic border colour
    useEffect(() => {
        if (colourCodingSetting) {
            setDynamicBorderColour('#4F4F4F');
        } else {
            setDynamicBorderColour('#008B95');
        }
    }, [colourCodingSetting]);


    // Supporting function to get the metric description from the availableMetrics array, given a metric name
    const getMetricDescription = (metricName) => {
        try {
            const metric = availableMetrics.find(m => m.Metric === metricName);
            return metric ? metric.MetricDescription : 'No description available';
        } catch (error) {
            return 'No description available';
        }
    };
    // Get metric description
    const metricDescription = getMetricDescription(metricName);
    const simpleLocationsArray = locations.map(location => location.name);


    // Metric description modal system
    const [isMetricDescriptionModalOpen, setIsMetricDescriptionModalOpen] = useState(false);
    const handleOpenMetricDescriptionModal = () => setIsMetricDescriptionModalOpen(true);
    const handleCloseMetricDescriptionModal = () => setIsMetricDescriptionModalOpen(false);
    const reportCurrentDate = useSelector(state => state.reports.selectedDate);
    const simpleCurrentDate = reportCurrentDate ? formatDate(parseYYYYMM(reportCurrentDate), 'monthYear') : null;

    // Set up of keystat info navigation functions
    const handleMapChoiceButton = () => {
        // Sync map date with relevant date selection
        dispatch(updateMapDate(reportCurrentDate));
        // Sync map metric with this keystat metric
        dispatch(updateMapMetric(metricName));
        // Navigate after state update
        handleNavigate('/map');
    }
    const handleChartChoiceButton = () => {
        // Sync chart areas with selected areas in the report / this keystatbox
        dispatch(updateChartLocations(simpleLocationsArray));
        // Sync chart metric with this keystat metric
        dispatch(updateChartMetric(metricName));
        handleNavigate('/time-series');
    }
    // Route navigation import for navigation from tooltip
    const navigate = useNavigate();
    const handleNavigate = (url) => {
        navigate(url);
    };




    // Return statement
    if (!locations || locations.length === 0) {
        return <div>Loading...</div>;  // Handles cases where locations are not provided
    }
   
    return (
        <div className="content-container">
            <div className="comparator">
                <div className="comparator-primary-and-metric-wrapper"
                key={primaryLocation.name}
                style={{ backgroundColor: backgroundColours[0] || '#4F4F4F', borderColor: dynamicBorderColour }}>
                    <div className='comparator-selector-and-first-location-wrapper'>
                        <Select
                            options={metricsForSelector}
                            onChange={handleKeyStatMetricSelection}
                            value={{ value: metricName, label: metricName }}
                            styles={dropDownStylesInUse}
                            placeholder="Choose a metric for comparison..."
                            menuPortalTarget={document.body}
                        />
                        <div className="comparator-stat-and-first-location">
                            <div className= "comparator-value">
                                <h2 style={{marginLeft: 0}}>{determineStatPrefix(metricName)}{roundToThreeSignificantFigures(primaryLocation.value).toLocaleString()}{determineStatSuffix(metricName)}</h2>
                            </div>
                            <div className= "comparator-location-label">
                                <p style={{paddingTop: 0}}><b>{primaryLocation.name}</b></p>
                            </div>
                        </div>
                    </div>

                    <div style = {{justifyContent: 'center', alignItems: 'center', padding: '0px', marginTop: '0px', height: '95%'}}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'right', alignItems: 'start', padding: '0px', paddingRight: '4px', margin: '2px' }}>
                            <button
                                className="XCloseButton"
                                onClick={handleKeyStatMetricRemoval}
                            >x</button>

                            {metricDescription && (<button
                                className="XCloseButton"
                                style = {{fontWeight: '600', margin: '0px', padding: '0px', alignItems: 'center', justifyContent: 'center'}}
                                onClick={handleOpenMetricDescriptionModal}
                            >
                                <p style = {{padding: '0px', margin: '1px', fontSize: '26px'}}> &#9432; </p>
                            </button>)}

                            {metricIndex===0 && (<div style={{height: '33px'}}/>)}
                            {metricIndex >0 && (<button
                                className="XCloseButton"
                                style = {{fontWeight: '800'}}
                                onClick={handleKeyStatShiftUp}
                            >&#x25B2;</button>)}

                            {!lastMetric &&(<button
                                className="XCloseButton"
                                style = {{fontWeight: 'bold'}}
                                onClick={handleKeyStatShiftDown}
                            >&#x25BC;</button>)}
                            {lastMetric && (<div style={{height: '33px'}}/>)}

                        </div>
                    </div>

                </div>

            {secondaryLocations.map((location, index) => (
                <div className="comparator-stat-and-location"
                key={location.name || index}
                style={{ backgroundColor: backgroundColours[index + 1] || '#4F4F4F' }}>
                    <div className= "comparator-value">
                        <p><b>{determineStatPrefix(metricName)}{roundToThreeSignificantFigures(location.value).toLocaleString()}{determineStatSuffix(metricName)}</b></p>
                    </div>
                    <div className= "comparator-location-label">
                        <p>{location.name}</p>
                    </div>
                </div>
                ))}
            </div>

            <Modal isOpen={isMetricDescriptionModalOpen}
                onClose={handleCloseMetricDescriptionModal}
                title={metricName+', '+simpleCurrentDate}>

                <h3 style={{marginBottom: '8px'}}>Explore this metric:</h3>
                <button className='form-button'
                    onClick={() => handleMapChoiceButton()}>
                    Map this metric at this date
                </button>
                <button className='form-button'
                    onClick={() => handleChartChoiceButton()}>
                    Chart this metric for current areas
                </button>
                <br/>
                <br/>

                <h3 style={{marginBottom: '8px'}}>About this metric:</h3>
                <p style={{marginBottom: '4px'}}><b>{metricName}</b></p>
                <p>{metricDescription}</p>
            </Modal>

        </div>
    );
}

export default KeyStatWindow;