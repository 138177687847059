import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { API_URL } from '../../config';
import ErrorBox from '../../Components/InfoComponents/ErrorBox';
import LoadingWheel from '../../Assets/LoadingSystem/LoadingWheel';
import TipsDropdown from '../../Components/InfoComponents/TipsDropdown';
import { useNavigate } from 'react-router-dom';

/* Note that 'tokens' externally mean credits for the use of the system, refered to here and throughout the code and database as 'credits' */
/* This is important to distinguish between the authentication Token as referenced below */
/* In code, 'credits' is always the name for access tokens as described externally */

function UserCreditsInfo({displayCredits}) {
    const [creditsDisplay, setCreditsDisplay] = useState(null);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // Get the new user guide state from redux to allow extra guidance for newer users
    const newUserGuideVersion = useSelector(state => state.appearancePersistence.newUserGuideVersion);


    
    
    // Handler for clicks to the pricing page
    const navigate = useNavigate();
    const handleLinkToPricing = () => {
        navigate('/pricing'); // Navigate after state update
    }

    useEffect(() => {
        const fetchCredits = async () => {
            setIsLoading(true);
            setError('');

            try {
                const token = localStorage.getItem('userToken');
                const response = await axios.get(`${API_URL}/GetProductCredits`, {
                    headers: { Authorization: `Bearer ${token}` }
                });

                if (response.status === 200 && response.data.status === 'Success') {
                    setCreditsDisplay(response.data.ProductCreditsRemaining);
                } else {
                    setError('Failed to fetch user credits.');
                }
            } catch (err) {
                if (err.response) {
                    setError(err.response.data.message || 'An error occurred.');
                } else {
                    setError('Network error fetching credits balance. Check connection.');
                }
            } finally {
                setIsLoading(false);
            }
        };

        fetchCredits();
    }, []);

    return (
        <div className='form-container top-start'>
            {displayCredits && <div className="form-box top-start">
                <h2>Your Usage</h2>
                {isLoading ? <LoadingWheel /> : (
                    error ? <ErrorBox message={error} /> : (
                        <div className="credits-display">
                            <div  style={{textAlign: 'center'}}>
                                <h1 style={{marginBottom: '5px'}}><strong>{creditsDisplay !== null ? creditsDisplay.toLocaleString() : '...loading...'}</strong> </h1>
                                <p style={{marginTop: '0px'}}>tokens remaining</p>
                            </div>
                        </div>
                    )
                )}
                <TipsDropdown
                    autoExpand={newUserGuideVersion}
                    fullWidth={true}
                    title='About tokens'
                    content={<div>
                                <p><b>Depending on the level of your subscription, you have a set number of data tokens and also a specific number of chart and report downloads.</b></p>
                                <p>If you need more, you can buy new data credits and if you feel you need to change your subscription package, just&nbsp;
                                <a style = {{color: '#00b0bc', textDecoration: 'none', fontWeight: 'bold'}} href = "mailto:Duncan@EvaluateLocate.com?subject=EvaluateLocate subscription upgrade&body=Hi Duncan, I have been using EvaluateLocate and would like to upgrade my subscription package ">contact us</a>
                                </p>
                            </div>}
                />
                <button className='form-button' style={{textAlign: 'left'}} onClick={handleLinkToPricing}>
                    <p>View pricing and subscription tiers</p>
                </button>

            </div>}
        </div>
    );
}

export default UserCreditsInfo;
