import React, { useState, useContext } from 'react';
import { API_URL } from   '../../config';
import ErrorBox from      '../../Components/InfoComponents/ErrorBox';
import { jwtDecode } from 'jwt-decode';
import AuthContext from   '../PermissionLevels/AuthContext';
import LoadingWheel from  '../../Assets/LoadingSystem/LoadingWheel';
import { NEW_USER_GUIDE_VERSION_CUTOFF_DAYS_SINCE_LAST_LOGIN } from '../../config';
import { setNewUserGuideVersion } from '../../redux/AppearancePersistence/appearancePersistenceSlice';
import { useDispatch } from 'react-redux';

function LoginForm() {
  const [Username, setUsername] = useState('');
  const [Password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { dispatch } = useContext(AuthContext);

  // Note the Redux dispatch is named explicitly in this component
  const dispatchRedux = useDispatch();


  const currentTime = Date.now() / 1000;

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Check if username or password fields are empty and set the error state
    if (!Username || !Password) {
      setError('Username and password cannot be empty');
      setIsLoading(false);
      return;
    }

    // Check is either a valid user name or email is entered in the Username field
    if (Username.includes('@') ? !/^[^@]+@[^@]+\.[^@]+$/.test(Username) : Username.length < 5 || Username.length > 20 || !/^[a-zA-Z0-9]+$/.test(Username)) {
      setError('Please enter a valid email or username');
      setIsLoading(false);
      return;
    }

    // Ensure email is sent in lowercase to the server
    const loginUsername = Username.includes('@') ? Username.toLowerCase() : Username;

    // Send login request to the server
    fetch(`${API_URL}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username: loginUsername,
        Password: Password,
      }),
    })
    .then(response => {
      if (!response.ok) {
        if (response.status === 401) {
          throw new Error('Incorrect username or password.');
        } else if (response.status === 429) {
          throw new Error('Too many log in requests. Please try again later.');
        } else {
          throw new Error('Input not valid. Please try again.');
        }
      }
      return response.json();
    })
    .then(data => {
      if (data.token) {
        const decodedToken = jwtDecode(data.token);
        // Ensure that decodedToken.Permissions is an array
        const Permissions = Array.isArray(decodedToken.Permissions) ? decodedToken.Permissions : [decodedToken.Permissions];
        localStorage.setItem('userToken', data.token);

        // Dispatch login action to update the global state
        dispatch({ 
          type: 'LOG_IN', 
          payload: { 
            FirstName: decodedToken.FirstName,
            SecondName: decodedToken.SecondName,
            Username: decodedToken.Username,
            Company: decodedToken.Company,
            Email: decodedToken.Email,
            AlternativeEmail: decodedToken.AlternativeEmail,
            PhoneNumber: decodedToken.PhoneNumber,
            LastLoginDate: decodedToken.LastLoginDateISO,
            Permissions: Permissions 
          } 
        });

        // Set the newUserGuideVersion state to true if the last log in date is more than a set number of days ago (newUserCuttoffDays from config.js)
        if (decodedToken.LastLoginDateISO) {
          const lastLoginDate = new Date(decodedToken.LastLoginDateISO);
          const daysSinceLastLogin = (currentTime - lastLoginDate.getTime()/1000) / (60 * 60 * 24);
          // console.log('Days since last login:', daysSinceLastLogin);
          if (daysSinceLastLogin > NEW_USER_GUIDE_VERSION_CUTOFF_DAYS_SINCE_LAST_LOGIN) {
            dispatchRedux(setNewUserGuideVersion(true));
          } else {
            dispatchRedux(setNewUserGuideVersion(false));
          }
        }


      } else {
        // Handle case when token is not received
        throw new Error('No response received. Please check connection');
      }
    })
    .catch((error) => {
      if (error.message.includes('Failed to fetch')) {
        setError('Server is unresponsive. Please check connection.');
      } else if (error.errors) {
        const errorMessage = error.errors.map(e => e.msg).join(' ');
        setError(errorMessage);
      } else {
        setError(error.toString());
      }
    })
    .finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <div>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      { isLoading && <LoadingWheel/>}
      {!isLoading && <div className='form-container top-start'
                          style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        {<div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
          <div className = "form-box fadeInEffect">
            <ErrorBox message={error} />
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                placeholder="Username or email"
                value={Username}
                onChange={e => setUsername(e.target.value)}
                className="form-input"
              />
              <input
                type="Password"
                placeholder="Password"
                value={Password}
                onChange={e => setPassword(e.target.value)}
                className="form-password-input"
              />
              <button type="submit" className="form-button">Log in</button>
            </form>
          </div>
          <button className='form-button register-button fadeInEffectDelayed'
                  onClick={() => {window.location.href = '/register';}}>Register for an account</button>
        </div>}
      </div>}
    </div>
  );
}
export default LoginForm;

// Function to get auth state from local storage, for later authenticated steps
const getAuthStateFromLocalStorage = () => {
  const token = localStorage.getItem('userToken');
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      // Ensure that decodedToken.Permissions is an array
      const Permissions = Array.isArray(decodedToken.Permissions) ? decodedToken.Permissions : [decodedToken.Permissions];
      return { Permissions: Permissions };
    } catch (e) {
      // Handle invalid token
      //console.error(e);
      return null;
    }
  }
  // Return null if no token found
  return null;
};
export { getAuthStateFromLocalStorage };