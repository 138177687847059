import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';

// Custom hook for debouncing Redux action dispatches

export const useDebouncedDispatch = (actionCreator, wait = 100) => {
  const dispatch = useDispatch();

  // Creates a memoized debounced function that dispatches the action
  // eslint warning disabled purposefully
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedDispatch = useCallback(
    debounce((...args) => dispatch(actionCreator(...args)), wait),
    [dispatch, actionCreator, wait]
  );

  return debouncedDispatch;
}