export function determineStatPrefix(metricDescription) {
    let statPrefix = '';

    // Return early with no result if the description indicates a rate of change of a named unit - i.e. if units cancel in a ratio or percentage
    if (metricDescription.includes('%') || metricDescription.toLowerCase().includes('growth') || metricDescription.toLowerCase().includes('momentum') || metricDescription.toLowerCase().includes('rate') || metricDescription.toLowerCase().includes('ratio') || metricDescription.toLowerCase().includes('12 month change')) {
        statPrefix = '';
    } else {
        // Note this section only checks for currency prefixes if it is in the else i.e. not a rate of change etc
        // Check for presence of '£', 'GBP', or 'value' to determine the prefix
        if (metricDescription.includes('£') || metricDescription.toUpperCase().includes('GBP') || metricDescription.toLowerCase().includes('value') || metricDescription.toLowerCase().includes('earnings')) {
            statPrefix = '£';
        }
    }
    return statPrefix;
}

export function determineStatSuffix(metricDescription) {
    let statSuffix = '';

    // Check for presence of '%' or 'percent' to determine the suffix
    if (metricDescription.includes('%') || metricDescription.toLowerCase().includes('growth') || metricDescription.toLowerCase().includes('momentum') || metricDescription.toLowerCase().includes('rate') || metricDescription.toLowerCase().includes('ratio') || metricDescription.toLowerCase().includes('12 month change')) {
        statSuffix = '%';
    }  else {
        // Note this else only applies if it is not a rate of change as specified above
        if (metricDescription.toLowerCase().includes('density')) {
            statSuffix = ' / km²';
        }
    }


    return statSuffix;
}
