import React, { useState, useContext } from 'react';
import axios from 'axios';
import { API_URL } from '../../config';
import ErrorBox from     '../../Components/InfoComponents/ErrorBox';
import LoadingWheel from '../../Assets/LoadingSystem/LoadingWheel';
import AuthContext from  '../PermissionLevels/AuthContext';


function DetailsChangeForm({onDetailsChange}) {

    const { authState } = useContext(AuthContext);
    const currentCompanyName = authState.Company;
    const currentFirstName = authState.FirstName;
    const currentSecondName = authState.SecondName;
    const currentEmail = authState.Email;
    const currentAlternativeEmail = authState.AlternativeEmail;
    const currentPhoneNumber = authState.PhoneNumber;

    
    // State for contents of the form
    const [detailsFormData, setDetailsFormData] = useState({
        ConfirmPassword: '',
        NewFirstName: '',
        NewSecondName: '',
        NewCompany: '',
        NewEmail: '',
        NewAlternativeEmail: '',
        NewPhoneNumber: '',
    });

    // State for error status
    const [error, setError] = useState('');
    // Loading state for submitting info
    const [isLoading, setIsLoading] = useState(false);

    // Handler for whenever any contents of the form changes (i.e. user types)
    const handleDetailsFormChange = (event) => {
        // Passes this to the js object
        setDetailsFormData({
            ...detailsFormData,
            [event.target.name]: event.target.value,
        });
    };

    // Combined logic to verify that all inputs are valid format to extent tested front-end here before submission
    const validateInputs = () => {
        const { NewEmail, NewAlternativeEmail, NewPhoneNumber, ConfirmPassword } = detailsFormData;

        if (!ConfirmPassword) {
            return 'Please confirm your password to make changes.';
        }
        if (NewEmail && !/\S+@\S+\.\S+/.test(NewEmail)) {
            return 'Please enter a valid primary email address.';
        }
        if (NewAlternativeEmail && !/\S+@\S+\.\S+/.test(NewAlternativeEmail)) {
            return 'Please enter a valid alternative email address.';
        }
        if (NewPhoneNumber && !/^\d{10,15}$/.test(NewPhoneNumber)) {
            console.log('New phone number: ' + NewPhoneNumber);
            return 'Phone number must be between 10 and 15 digits.';
        }
        return null;
    };


    const handleSubmitDetailsChange = async (event) => {
        // Prevent default here stops the form submission from just reloading the page
        event.preventDefault();
    
        // Start with error empty, set loading true
        setError('');
        setIsLoading(true);

        // Validate inputs and return early after setting any suitable error status
        const validationError = validateInputs();
        if (validationError) {
            setError(validationError);
            setIsLoading(false);
            return;
        }

        // Construct payload with only non-empty fields
        const payload = Object.entries(detailsFormData).reduce((acc, [key, value]) => {
            // Trim values to remove leading/trailing whitespace and check for non-empty
            let trimmedValue = value.trim(); // Trim the value here
            if (trimmedValue !== '') {
                acc[key] = trimmedValue;
                // Special handling to ensure email fields are in lower case
                if (key.includes('Email')) {
                    acc[key] = trimmedValue.toLowerCase();
                }
            }
            console.log(`Key: ${key}, Value: ${trimmedValue}`);
            return acc;
        }, {});
        console.log(payload);

        // Ensure at least one field is filled (other than password confirmation)
        if (Object.keys(payload).length <= 1 && payload.hasOwnProperty('ConfirmPassword')) {
            setError('No details to update.');
            setIsLoading(false);
            return;
        }

        // If no validation errors, send the request to the server
        try {
            const token = localStorage.getItem('userToken');
            const response = await axios.put(`${API_URL}/UpdateDetails`, payload, {
                headers: { Authorization: `Bearer ${token}` },
            });
            
            //console.log(response);
            if (response.status === 200) {
                setError('Details updated successfully');
                onDetailsChange();
            } else {
                setError('Failed to update details. Status: ' + response.status);
            }
        } catch (error) {
            //console.error(error);
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setError('Validation error: ' + error.response.data.message);
                        break;
                    case 404:
                        setError('Validation error');
                        break;
                    case 401:
                        setError('Password confirmation is incorrect');
                        break;
                    case 429:
                        setError('Too many requests. Try again later for security reasons.');
                        break;
                    case 500:
                        setError('Server error: ' + error.response.data.message);
                        break;
                    default:
                        setError('An error occurred. Please try again.');
                        break;
                }
                //console.error('Server Response:', error.response);
            } else {
                setError('Failed to update details. Please check your network connection, sign in again and try again.');
            } 
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="form-box">
            <h3>Update Account Details</h3>
            {isLoading && <LoadingWheel />}
            {!isLoading && <div>
                <p> Change one or more of your account details. </p>
                <ErrorBox message={error} />
                <form onSubmit={handleSubmitDetailsChange}>
                    <input
                        className="form-input"
                        type="text"
                        name="NewFirstName"
                        placeholder={"New first name. (Currently: " + currentFirstName + ")"}
                        onChange={handleDetailsFormChange}
                        autoComplete='new-password'
                    />
                    <input
                        className="form-input"
                        type="text"
                        name="NewSecondName"
                        placeholder={"New second name. (Currently: " + currentSecondName + ")"}
                        onChange={handleDetailsFormChange}
                        autoComplete='new-password'
                    />
                    <input
                        className="form-input"
                        type="text"
                        name="NewCompany"
                        placeholder={"New company. (Currently: " + currentCompanyName + ")"}
                        onChange={handleDetailsFormChange}
                        autoComplete='new-password'
                    />
                    <input
                        className="form-input"
                        type="text"
                        name="NewEmail"
                        placeholder={"New primary email address. (Currently: " + currentEmail + ")"}
                        onChange={handleDetailsFormChange}
                        autoComplete='new-password'
                    />
                    <input
                        className="form-input"
                        type="text"
                        name="NewAlternativeEmail"
                        placeholder={"New alternative email address. (Currently: " + currentAlternativeEmail + ")"}
                        onChange={handleDetailsFormChange}
                        autoComplete='new-password'
                    />
                    <input
                        className="form-input"
                        type="text"
                        name="NewPhoneNumber"
                        placeholder={"New phone number. (Currently: " + currentPhoneNumber + ")"}
                        onChange={handleDetailsFormChange}
                        autoComplete='new-password'
                    />
                    <input
                        className="form-password-input"
                        type="password"
                        name="ConfirmPassword"
                        placeholder="Confirm current password to submit changes"
                        onChange={handleDetailsFormChange}
                        autoComplete='new-password'
                    />
                    <br/>
                    <p>After changes you will need to log in again</p>
                    <button className="form-button muted-border" type="submit"><p>Submit</p></button>
                </form>
            </div>}
        </div>
    );
}

export default DetailsChangeForm;