import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    updateChartLocations,
    updateChartMetric,
} from '../../redux/DataMenuSelections/chartsSlice';

import {
    updateReportLocations,
    updateReportDate,
} from '../../redux/DataMenuSelections/reportsSlice';

function MapAreaPopup({ locationName, value, metric, date, onNavigate }) {

    // Get map active selected date
    const { selectedDate : mapSelectedDate } = useSelector(state => state.maps);
    const { selectedMetric: mapMetric } = useSelector(state => state.maps);

    // Funcctionality to dispatch actions to update Redux storage
    const dispatch = useDispatch();

    const handleReportChoiceButton = (reportAreas) => {
        // Sync report areas with selected areas
        dispatch(updateReportLocations(reportAreas));
        // Sync report date
        dispatch(updateReportDate(mapSelectedDate));
        // Navigate after state update
        onNavigate('/location-reports');
    }
    const handleChartChoiceButton = (chartAreas) => {
        // Sync chart areas with selected areas
        dispatch(updateChartLocations(chartAreas));
        // Sync chart metric with current map metric
        dispatch(updateChartMetric(mapMetric));
        // Navigate after state update
        onNavigate('/time-series');
    }


  return (
    <div className="map-popup-contents" style={{margin: '0px'}}>
        <div className="map-area-popup-stats">
            <h1 style={{margin: '1px', marginBottom: '5px', textAlign: 'center'}}>{locationName}</h1>
            <h2 style={{margin: '2px', textAlign: 'center'}}>{value.toLocaleString()}</h2>
            <p style={{margin: '1px', textAlign: 'center'}}>{metric}</p>
            <p style={{margin: '1px', textAlign: 'center'}}>{date}</p>
        </div>
        <div className='map-popup-area-options'>
            <button className='form-button'
                onClick={() => handleReportChoiceButton([locationName])}>
                Report on this area
            </button>
            <button className='form-button'
                onClick={() => handleChartChoiceButton([locationName])}>
                Chart this metric here
            </button>
        </div>
    </div>
  );
}

export default MapAreaPopup;