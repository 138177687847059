const checkLocationMatches = function(addressNames, availableLocations) {
    const normalizeText = text => {
      if (typeof text !== 'string') {
        console.error('Expected a string but received:', text);
        return '';  // To handle any undefined or incorrect inputs safely
      }
      return text.toLowerCase().replace(/[\s.,/#!$%^&*;:{}=_`~()-]/g, "");
    };

    const matchResults = addressNames.map(name => {
        const isMatch = availableLocations.some(location => {
            if (!location || !location.Location) {
                console.error('Invalid location entry:', location);
                return false;
            }
            return normalizeText(name) === normalizeText(location.Location);
        });
        return { component: name, isMatch };
    });
    return matchResults;
};

export default checkLocationMatches;