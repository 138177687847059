import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import NavBar from './NavBar';
import { ExportProvider } from '../ExportSystem/ExportPDFContext';
import LoadingWheel from '../Assets/LoadingSystem/LoadingWheel';

const Layout = ({ children }) => {
    const contentRef = useRef(null);

    const layoutForExport = useSelector(state => state.appearancePersistence.exportingState);

    return (
        <ExportProvider contentRef={contentRef}>
            {layoutForExport && <LoadingWheel isExportVersion={true}/>}
            <div className="layout-top-level">
                <NavBar />
                <div ref={contentRef} className="content">
                    {children}
                </div>
            </div>
        </ExportProvider>
    );
};

export default Layout;