import React, { useEffect, useRef, useState } from 'react';
import './MatrixBackground.css';
import generateProbabilityMatrix from './GenerateProbabilityMatrix';

// Debounce function to limit the rate at which a function can fire.
const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), delay);
    };
};

const MatrixBackground = () => {
    const containerRef = useRef(null);
    const [probabilityMatrix, setProbabilityMatrix] = useState([]);
    const [gridDimensions, setGridDimensions] = useState({ rows: 0, cols: 0 });
    const intervalIds = useRef([]);
    const speedGroups = useRef(new Map());

    
    const minLength = 10;
    const maxLength = 22;
    const minSpeed = 1;
    const maxSpeed = 4;
    const minSize = 6;
    const maxSize = 24;
    const streamDensity = 15;
    const creationInterval = 5;
    const updateInterval = 350;

    const removalFrequency = 100; // Frequency of batch removal in milliseconds (5 seconds)
    let removalProbability = 0.04; // Probability of removing each stream during batch removal
    // Change the value to much higher removal chance after a specified delay
    setTimeout(() => {
        removalProbability = 0.001;
    }, 5000);
    setTimeout(() => {
        removalProbability = 0.005;
    }, 20000);
    setTimeout(() => {
        removalProbability = 0.05;
    }, 30000);
    setTimeout(() => {
        removalProbability = 0.2;
    }, 39000);
    setTimeout(() => {
        removalProbability = 0.05;
    }, 42000);

    const updateGridDimensions = async () => {
        if (!containerRef.current) {
            console.error('Container ref is not set');
            return;
        }

        const containerWidth = containerRef.current.clientWidth;
        const containerHeight = window.innerHeight;

        if (containerWidth === 0 || containerHeight === 0) {
            console.error('Invalid container dimensions', { containerWidth, containerHeight });
            return;
        }

        const streamAvgWidth = 8;
        const cols = Math.round(containerWidth / streamAvgWidth);
        const rows = Math.round((containerHeight / containerWidth) * cols);

        //console.log('Updating grid dimensions', { rows, cols });

        setGridDimensions({ rows, cols });

        try {
            const matrix = await generateProbabilityMatrix('./binary-map.svg', rows, cols);
            setProbabilityMatrix(matrix);
        } catch (error) {
            console.error('Error loading probability matrix:', error);
        }
    };

    useEffect(() => {
        const debouncedUpdateGridDimensions = debounce(updateGridDimensions, 300);
        const handleResize = () => {
            debouncedUpdateGridDimensions();
        };

        // Call once initially
        updateGridDimensions();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (probabilityMatrix.length === 0) return;

        const container = containerRef.current;
        if (!container) return;

        let isComponentMounted = true;

        // Initialize speed groups
        for (let speed = minSpeed; speed <= maxSpeed; speed++) {
            speedGroups.current.set(speed, []);
        }

        const streamInterval = setInterval(() => {
            const totalStreams = Array.from(speedGroups.current.values()).reduce((acc, group) => acc + group.length, 0);
            const maxNumStreams = streamDensity * gridDimensions.cols;

            if (totalStreams < maxNumStreams) {
                createStream(container);
            }
        }, creationInterval);

        intervalIds.current.push(streamInterval);

        function createStream(container) {
            const streamLength = getRandomInt(minLength, maxLength);
            const xPosition = getRandomInt(0, container.clientWidth);
            const fontSize = getRandomInt(minSize, maxSize);
            const zIndex = fontSize - 10;
            const speed = getRandomInt(minSpeed, maxSpeed);

            let stream = document.createElement('div');
            stream.className = 'MatrixStream';
            stream.style.left = `${xPosition}px`;
            stream.style.zIndex = zIndex;
            stream.style.fontSize = `${fontSize}px`;
            stream.style.lineHeight = `${fontSize * 1.5}px`;

            const characters = [];
            for (let j = 0; j < streamLength; j++) {
                let character = document.createElement('span');
                character.className = 'MatrixCharacter';
                const value = Math.random() > 0.5 ? '1' : '0';
                character.textContent = value;
                character.classList.add(value === '1' ? 'MatrixCharacter1' : 'MatrixCharacter0');
                stream.appendChild(character);
                characters.push(character);
            }

            container.appendChild(stream);
            speedGroups.current.get(speed).push({ stream, characters, fontSize, yPosition: -stream.offsetHeight });

            const intervalId = setInterval(() => {
                if (probabilityMatrix.length > 0) {
                    updateCharactersInStream(stream, fontSize, probabilityMatrix, characters);
                }
            }, updateInterval);

            intervalIds.current.push(intervalId);

            //console.log(`Stream created with speed ${speed} and timeoutId ${intervalId}`);
        }

        function animateStreams() {
            speedGroups.current.forEach((group, speed) => {
                group.forEach(streamData => {
                    let { stream, characters, yPosition } = streamData;

                    yPosition += speed/2;
                    if (yPosition > window.innerHeight) {
                        yPosition = -stream.offsetHeight;
                        const xPosition = getRandomInt(0, containerRef.current?.clientWidth || 0);
                        stream.style.left = `${xPosition}px`;

                        //console.log(`Stream with speed ${speed} reset to top at yPosition: ${yPosition}`);

                        characters.forEach(character => {
                            const value = Math.random() > 0.5 ? '1' : '0';
                            character.textContent = value;
                            character.className = 'MatrixCharacter';
                            character.classList.add(value === '1' ? 'MatrixCharacter1' : 'MatrixCharacter0');
                        });
                    }

                    stream.style.transform = `translateY(${yPosition}px)`;
                    streamData.yPosition = yPosition;
                });
            });

            if (isComponentMounted) {
                requestAnimationFrame(animateStreams);
            }
        }

        requestAnimationFrame(animateStreams);

        // Batch removal of streams based on probability
        function removeStreamsInBatch() {
            speedGroups.current.forEach((group, speed) => {
                const removalBatch = group.filter(() => Math.random() < removalProbability);
                removalBatch.forEach(({ stream }) => {
                    const index = group.findIndex(item => item.stream === stream);
                    if (index !== -1) {
                        group.splice(index, 1);
                    }
                    try {
                        container.removeChild(stream);
                        //console.log('Stream removed:', stream);
                    } catch (e) {
                        //console.error('Error removing stream:', e);
                    }
                });
            });
        }

        const batchRemovalInterval = setInterval(removeStreamsInBatch, removalFrequency);

        intervalIds.current.push(batchRemovalInterval);

        function updateCharactersInStream(stream, fontSize, probabilityMatrix, characters) {
            const container = containerRef.current;
            if (!container) return;

            const numRows = probabilityMatrix.length;
            const numCols = probabilityMatrix[0].length;
            const sectorWidth = container.clientWidth / numCols;
            const sectorHeight = window.innerHeight / numRows;

            const yPosition = parseFloat(stream.style.transform.split('(')[1]);
            const xPosition = parseInt(stream.style.left);

            characters.forEach((character, index) => {
                const charYPosition = yPosition + index * fontSize * 1.5;
                const col = Math.floor(xPosition / sectorWidth);
                const row = Math.floor(charYPosition / sectorHeight);

                if (col >= 0 && col < numCols && row >= 0 && row < numRows) {
                    const probability = probabilityMatrix[row][col];
                    const value = Math.random() < probability ? '1' : '0';
                    character.textContent = value;
                    character.className = 'MatrixCharacter';
                    character.classList.add(value === '1' ? 'MatrixCharacter1' : 'MatrixCharacter0');
                }
            });
        }

        function getRandomInt(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        }

        return () => {
            isComponentMounted = false;
            const currentIntervalIds = intervalIds.current.slice();
            currentIntervalIds.forEach(clearInterval);
            intervalIds.current = [];
            container.innerHTML = '';
        };
    }, [probabilityMatrix, gridDimensions, removalProbability, maxSpeed]);

    return (
        <div className="MatrixContainer">
            <div className="MatrixBackgroundGradient" />
            <div ref={containerRef} className="MatrixStreams" />
        </div>
    );
};

export default MatrixBackground;