import React, { useState, useContext, useCallback } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../Authentication/PermissionLevels/AuthContext';
import { RoutePermissions } from '../Authentication/PermissionLevels/RoutePermissions';
import logo from '../Assets/EvaluateLocateFullLogo.svg';
import { setAdditionalGridCellHeightAllowanceCount } from '../redux/AppearancePersistence/appearancePersistenceSlice';
import { useDispatch } from 'react-redux';
import UseWindowSize from '../DisplaySystem/WindowSize';

const NavBar = () => {
  // AuthState, Permissions, Navigations
  const { authState } = useContext(AuthContext);
  const userPermissions = authState.Permissions;
  const userIsLoggedIn = authState.isAuthenticated;


  // Display setup and page size
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const { width } = UseWindowSize();
  const horizontalLayoutBreakpoint = 1200;
  const wideScreen = width > horizontalLayoutBreakpoint;

  const linkListMain = [
    {to: "/home", text: "Home"},
    {to: "/map", text: "Maps"},
    {to: "/time-series", text: "Charts"},
    {to: "/location-reports", text: "Reports"},
    {to: "/location-selector", text: "Investment Selector"},
    {to: "/portfolio", text: "Portfolio"},
  ];

  const linkListAdmin = [
    {to: "/account", text: "Account"},
    {to: "/access-denied", text: "Access Denied"},
    {to: "/admin-page-secure", text: "Admin Page Secure"},
  ];

  const linkListComplete = linkListMain.concat(linkListAdmin);

  // Function to toggle menu visibility
  const toggleMenu = (event) => {
    event.preventDefault(); // Prevent default action (navigation or submission)
    event.stopPropagation(); // Stop the event from bubbling up the DOM tree
    setIsMenuVisible(prevState => {
      //console.log("Menu visibility before toggle:", prevState);
      //console.log("Menu visibility after toggle:", !prevState);
      return !prevState;
    });
  };

  const dispatch = useDispatch(); // Initialise dispatch hook

  // Function to close the menu when a link is clicked
  const closeMenu = useCallback(() => {
    setIsMenuVisible(false);
    // Also reset the additional grid cell height allowance count to zero for a new page
    dispatch(setAdditionalGridCellHeightAllowanceCount(0));
  }, [dispatch]);

  // Supporting logic for log out button
  const handleLogout = () => {
    localStorage.removeItem('userToken');
    dispatch({ type: 'LOG_OUT' });
    setTimeout(() => window.location.reload(), 50);
  };


  return (
    <nav className="navbar">
      <Link to="/home" className="navbar-brand">
        <img src={logo} alt="EvaluateLocate logo" className="navbar-logo" />
      </Link>
      <button className="menu-icon" onClick={(event) => toggleMenu(event)}>☰</button>

      {/* Narrow screen case of complete list in one column, using linkListComplete */}
      {!wideScreen && <ul className={`navbar-menu ${isMenuVisible ? 'visible' : ''}`}>
        {linkListComplete.map((link, index) => {
            const routeInfo = RoutePermissions[link.to];
            if (!routeInfo) return null;

            const { roles, requiresAuth } = routeInfo;
            // Check if there's an overlap between user's permissions and required roles for the route
            const userHasPermission = roles.some(role => userPermissions.includes(role));

            // Return main list of routes if the user has sufficient permissions
            if (userHasPermission && (!requiresAuth || userIsLoggedIn)) {
              return (
                <li key={index} className="navbar-menu li">
                  {/* Close menu on link click */}
                  <Link to={link.to} onClick={closeMenu}>{link.text}</Link>
                </li>
              );
            }
        return null;
        })}
        {/* Logout button */}
        {userIsLoggedIn && <li className="navbar-menu li">
          <button style={{border: 'none', background: 'none', margin: '0px', padding: '0px'}} onClick={handleLogout}>
            <p>Log out</p>
          </button>
        </li>}

      </ul>}
      


      {/* Wide screen case of list in two sections, using linkListMain and linkListAdmin separately */}
      {wideScreen && <div className={`navbar-menu-top-level ${isMenuVisible ? 'visible' : ''}`}>
        {/* Left side linkListMain */}
        <ul className={`navbar-menu ${isMenuVisible ? 'visible' : ''}`}>
          {userIsLoggedIn && linkListMain.map((link, index) => {
              const routeInfo = RoutePermissions[link.to];
              if (!routeInfo) return null;

              const { roles, requiresAuth } = routeInfo;
              // Check if there's an overlap between user's permissions and required roles for the route
              const userHasPermission = roles.some(role => userPermissions.includes(role));

              // Return main list of routes if the user has sufficient permissions
              if (userHasPermission && (!requiresAuth || userIsLoggedIn)) {
                return (
                  <li key={index} className="navbar-menu li">
                    {/* Close menu on link click */}
                    <Link to={link.to} onClick={closeMenu}>{link.text}</Link>
                  </li>
                );
              }
          return null;
          })}
        </ul>
        {/* Left side linkListAdmin */}
        <ul className={`navbar-menu ${isMenuVisible ? 'visible' : ''}`}>
          {userIsLoggedIn && linkListAdmin.map((link, index) => {
              const routeInfo = RoutePermissions[link.to];
              if (!routeInfo) return null;

              const { roles, requiresAuth } = routeInfo;
              // Check if there's an overlap between user's permissions and required roles for the route
              const userHasPermission = roles.some(role => userPermissions.includes(role));

              // Return main list of routes if the user has sufficient permissions
              if (userHasPermission && (!requiresAuth || userIsLoggedIn)) {
                return (
                  <li key={index} className="navbar-menu li">
                    {/* Close menu on link click */}
                    <Link to={link.to} onClick={closeMenu}>{link.text}</Link>
                  </li>
                );
              }
          return null;
          })}
          {/* Logout button */}
          {userIsLoggedIn && <li className="navbar-menu li">
              <p onClick={handleLogout}>Log out</p>
          </li>}

        </ul>


      </div>}

    </nav>
    );
};

export default NavBar;