import { configureStore } from '@reduxjs/toolkit';
import mapsReducer from './DataMenuSelections/mapsSlice';
import chartsReducer from './DataMenuSelections/chartsSlice';
import reportsReducer from './DataMenuSelections/reportsSlice';
import dataMenuReducer from './DataMenuAvailability/dataMenuAvailableSlice';
import fetchedDataReducer from './DataRequestSelections/fetchedDataSlice';
import mapPersistenceReducer from './MapPersistence/mapPersistenceSlice';
import mapScaleSlice from './MapPersistence/mapScaleSlice';
import locationsReducer from './DataRequestSelections/locationsSlice';
import appearancePersistenceReducer from './AppearancePersistence/appearancePersistenceSlice';

export const store = configureStore({
  reducer: {
    dataMenu: dataMenuReducer,
    maps: mapsReducer,
    charts: chartsReducer,
    reports: reportsReducer,
    fetchedData: fetchedDataReducer,
    mapPersistence: mapPersistenceReducer,
    mapScale: mapScaleSlice,
    locations: locationsReducer,
    appearancePersistence: appearancePersistenceReducer
  },
});