export const initializeGA = (measurementId) => {
  const script = document.createElement('script');
  script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
  script.async = true;
  document.head.appendChild(script);

  script.onload = () => {
    // Ensure dataLayer is initialized on the window object before using it
    window.dataLayer = window.dataLayer || [];
    // Define the gtag function using window.dataLayer to ensure it is recognized
    window.gtag = function() {
      window.dataLayer.push(arguments);
    };

    // Initialize GA4 with the measurement ID
    window.gtag('js', new Date());
    window.gtag('config', measurementId);
  };

  script.onerror = () => {
    console.error("Failed to load Google Analytics script.");
  };
};

const classifyPerformance = (name, value) => {
  const thresholds = {
    CLS: [0.1, 0.25], // Good if < 0.1, needs improvement if < 0.25, poor otherwise
    LCP: [2500, 4000], // Times in milliseconds
    INP: [100, 300]   // Times in milliseconds
  };
  const [good, poor] = thresholds[name];
  if (value <= good) return 'good';
  if (value <= poor) return 'needs improvement';
  return 'poor';
};

export const sendWebVitals = () => {
  import('web-vitals').then(({ onCLS, onINP, onLCP }) => {
    const sendToGoogleAnalytics = ({ name, value, id, delta }) => {
      const performanceRating = classifyPerformance(name, value);
      window.gtag('event', name, {
        event_category: 'Web Vitals',
        metric_value: Math.round(name === 'CLS' ? value * 1000 : value),
        metric_delta: Math.round(name === 'CLS' ? delta * 1000 : delta),
        metric_id: id,
        performance_rating: performanceRating, // Add this to track performance classification
        non_interaction: true,
      });
    };
  
    onCLS(sendToGoogleAnalytics);
    onINP(sendToGoogleAnalytics);
    onLCP(sendToGoogleAnalytics);
  }).catch(error => {
    console.error("Failed to load the web-vitals library:", error);
  });
};