import React from 'react';
import logoImage from '../Assets/EL_Logo_White.png'; // Adjust path as needed

export const ExportHeaderContent = () => {
    return (
        <div className="ExportDocHeader">
            <div style={{
                float: 'right', // keeps the logo to the right
                marginRight: '20px', // distance from the right edge
                marginTop: '20px', // distance from the top
                height: '60px',
                width: '180px',
                overflow: 'hidden' // prevents the image from overflowing its div
            }}>
                <img src={logoImage} alt="Logo" style={{
                    height: '60px',
                    width: '180px',
                }} />
            </div>
        </div>
    );
};

export const ExportFooterContent = () => {
    return (
        <div className="ExportDocFooter">
            <p style={{ fontSize: '14px'}}><b>Any public reproduction or distribution of this data must be done with the agreement of EvaluateLocate Ltd and must credit EvaluateLocate</b></p>
            <p>EvaluateLocate provides comparable, consistent, 'nowcast' metrics using standard economic definitions and incorporating source data from a number of official sources. This is for information purposes only and does not constitute any form of investment or financial advice. We make no guarantee for the future availability of such estimates, which are provided on a best-endeavours basis. Within its internal model, EvaluateLocate contains original data sourced from the Office for National Statistics, Scottish Government, NISRA and HM Land Registry licensed under the Open Government Licence, in addition to public register information via Companies House. This research is based on current public information that we consider to be reliable, but we do not guarantee it as accurate or complete, and it should not be relied on as such. The EvaluateLocate Vitality Index (or “EVI”) is powered by a proprietary model, protected under UK copyright law. No part of this material or any research report may be (i) copied, photocopied or duplicated in any form by any means or (ii) redistributed without the prior written consent of EvaluateLocate. </p>
            <p>“EvaluateLocate” is a registered trademark, number UK00003449764, held by EvaluateLocate Ltd</p>
            <p>Content and software <span>&copy;</span> EvaluateLocate Ltd 2024</p>
        </div>
    );
};