import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from   'react-router-dom';
import { API_URL } from       '../../config';
import { jwtDecode } from     'jwt-decode';
import AuthContext from       '../PermissionLevels/AuthContext';
import ErrorBox from          '../../Components/InfoComponents/ErrorBox';
import Modal from '../../Components/InfoComponents/ModalBox';
import TermsConditionsBox from './RegTermsConditions';
import { setNewUserGuideVersion } from '../../redux/AppearancePersistence/appearancePersistenceSlice';
import { useDispatch } from 'react-redux';

function RemoveNonAlphanumeric(inputString) {
  // Use regular expression to replace non-alphanumeric characters with an empty string
  return inputString.replace(/[^a-zA-Z0-9]/g, '');
}

function RegistrationForm() {
  // System to ensure no momentary blip in permissions between Unknown and NewRegistration
  const { authState } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (authState.isAuthenticated && authState.Permissions.includes('NewRegistration')) {
      navigate('/home');
    }
  }, [authState, navigate]);
  
  // Set up required registration information
  const [FirstName, setFirstName] = useState('');
  const [SecondName, setSecondName] = useState('');
  const [Company, setCompany] = useState('');
  const [Email, setEmail] = useState('');
  const [PhoneNumber, setPhoneNumber] = useState('');
  const [Password, setPassword] = useState('');
  const [PasswordConfirm, setPasswordConfirm] =useState('');

  //Alt email not used in current setup, submitted blank
  const AlternativeEmail = '';

  // Similarly, username is also simplified in current setup, is defaulted just to email
  const [Username, setUsername] = useState('');
  // Auto populate the Username field from other alphanumeric fields
  useEffect (() => {
    if (FirstName && (SecondName || Company)) {
      try {
        let AutoUserName = FirstName + SecondName + Company;
        // Remove all space characters from the AutoUserName
        AutoUserName = AutoUserName.replace(/\s/g, '');
        AutoUserName =RemoveNonAlphanumeric(AutoUserName);
        // Trim the AutoUserName to a maximum of 20 characters
        AutoUserName = AutoUserName.substring(0, 20);
        // Capitalise the first character
        AutoUserName = AutoUserName.charAt(0).toUpperCase() + AutoUserName.slice(1);
        setUsername(AutoUserName);
      } catch {
        setUsername('Choose a unique username');
      }
    }
  }, [FirstName, SecondName, Company]);


  // Set up error and dispatch handling
  const [error, setError] = useState('');
  const { dispatch } = useContext(AuthContext);

  // Note the Redux dispatch is named explicitly in this component
  const dispatchRedux = useDispatch();

  // Registration terms modal system
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const handleOpenTermsModal = () => setIsTermsModalOpen(true);
  const handleCloseTermsModal = () => setIsTermsModalOpen(false);
 

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if username or password fields are empty and set the error state
    if (Password !== PasswordConfirm) {
      setError('Password and password confirmation are not consistent');
      return;
    }

    // Send registration request to the server
    fetch(`${API_URL}/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
        Password,
        FirstName,
        SecondName,
        Company,
        Email,
        AlternativeEmail,
        PhoneNumber
      }),
    })
    .then(response => {
      if (response.status === 200) {
        return response.json(); // Successful response, parse the JSON
      } else {
        return response.json().then(err => Promise.reject(err)); // Error response, parse the JSON and then reject the promise
      }
    })
    .then(response => {
      // Upon successful registration, call the login endpoint
      return fetch(`${API_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ Username, Password }),
      });
    })  
    .then(response => response.json())
    

    .then(data => {
      //console.log('New registration log in success:', data);
      if (data.token) {
        const decodedToken = jwtDecode(data.token);
        const Permissions = Array.isArray(decodedToken.Permissions) ? decodedToken.Permissions : [decodedToken.Permissions];
        localStorage.setItem('userToken', data.token);
        dispatch({ 
          type: 'LOG_IN', 
          payload: { 
            FirstName: decodedToken.FirstName,
            SecondName: decodedToken.SecondName,
            Username: decodedToken.Username,
            Company: decodedToken.Company,
            Email: decodedToken.Email,
            AlternativeEmail: decodedToken.AlternativeEmail,
            PhoneNumber: decodedToken.PhoneNumber,
            LastLoginDate: decodedToken.LastLoginDateISO,
            Permissions: Permissions 
          } 
        });

        // Set the newUserGuideVersion state to true without any conditions, in this case, since it's a new registration
        dispatchRedux(setNewUserGuideVersion(true));

        // Redirect to desired page if the user has NewRegistration permission
        if (Permissions.includes('NewRegistration')) {
          navigate('/home');
        }
      } else {
        throw new Error('No log in token received');
      }
    })
    .catch((error) => {
      //console.error('Error:', error);
      if (error.errorType && error.message) {
        setError(`${error.message}`);
      } else {
        setError(error.toString());
      }
    });
  };


  return (
    <div className='form-container top-start'>
      <br/>
      <Modal
              isOpen={isTermsModalOpen}
              onClose={handleCloseTermsModal}
              title={'Terms & Conditions'}>
              <TermsConditionsBox onClose={handleCloseTermsModal}/>
      </Modal>
      <div className = "form-box"  style={{ maxWidth: '400px' }}>
        <h1 style = {{marginBottom: '3px', textAlign: 'center'}}>Register to explore</h1>
        <p  style = {{marginBottom: '5px', textAlign: 'center'}}>Enter your details below to create an account</p>

       <ErrorBox message={error} />
       <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="First name"
          value={FirstName}
          onChange={e => setFirstName(e.target.value)}
          className="form-input"
        />
        <input
          type="text"
          placeholder="Second name"
          value={SecondName}
          onChange={e => setSecondName(e.target.value)}
          className="form-input"
        />
        <input
          type="text"
          placeholder="Company"
          value={Company}
          onChange={e => setCompany(e.target.value)}
          className="form-input"
        />
        <input
          autoComplete='off'
          type="text"
          placeholder="Email"
          value={Email}
          onChange={e => setEmail(e.target.value)}
          className="form-input"
        />
        {/*<input
          type="text"
          placeholder="Alternative email (useful for back up)"
          value={AlternativeEmail}
          onChange={e => setAlternativeEmail(e.target.value)}
          className="form-input"
        />*/}
        <input
          type="text"
          placeholder="Phone number (for future support)"
          value={PhoneNumber}
          onChange={e => setPhoneNumber(e.target.value)}
          className="form-input"
        />
        {/*<input
          autoComplete='off'
          type="text"
          placeholder="Choose username (no spaces)"
          value={Username}
          onChange={e => setUsername(e.target.value)}
          className="form-input"
        />*/}
        <br/>
        <input
          style ={{marginTop: '14px'}}

          autoComplete='new-password'
          type="Password"
          placeholder="Choose password"
          value={Password}
          onChange={e => setPassword(e.target.value)}
          className="form-password-input"
        />
        <input
          autoComplete='new-password'
          type="Password"
          placeholder="Confirm password"
          value={PasswordConfirm}
          onChange={e => setPasswordConfirm(e.target.value)}
          className="form-password-input"

          style ={{marginBottom: '10px'}}
        />
        <div style ={{display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center'}}>
          <input 
              type="checkbox" 
              required
              style = {{width: '16px', height: '16px', padding: '5px', margin: '5px'}}
            />
          <label htmlFor="termsCheckbox" style={{ color: 'white', padding: '5px', margin: '5px'}} >I accept the terms and conditions</label>
        </div>

        <button className="form-button muted-border" onClick={handleOpenTermsModal}>View Terms & Conditions</button>


        <button type="submit" className="form-button">Register now</button>
        <p  style = {{marginBottom: '8px', textAlign: 'center'}}>No card details required or commitment to purchase</p>

        </form>
      </div>
    </div>
  );
}
export default RegistrationForm;