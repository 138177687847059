import React, { useContext, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import AuthContext from './AuthContext';
import LoadingWheel from '../../Assets/LoadingSystem/LoadingWheel';

// Higher Order Component for controlling access based on permissions
export const WithPermissions = (allowedPermissions) => (Component) => {
  return function WrappedComponent(props) {
    const { authState, isLoadingInitialPermissions } = useContext(AuthContext);

    const location = useLocation();
    const [isAdminRoute] = useState(location.pathname.includes('admin'));

    // If authentication is not required, we can render the component directly.
    if (!allowedPermissions.requiresAuth) {
      return <Component {...props} />;
    }

    // Wait until the auth state is fully initialised
    if (isLoadingInitialPermissions) {
      return <LoadingWheel />;
    }

    // Check if the user has the required role and is authenticated (if authentication required)
    const userRoleAllowed = authState.Permissions.some((role) => allowedPermissions.roles.includes(role));
    const userIsAuthenticated = allowedPermissions.requiresAuth ? authState.isAuthenticated : true;

    // If the user has sufficient permissions and is authenticated, allow access
    if (userRoleAllowed && userIsAuthenticated) {
      return <Component {...props} />;
    } else {
      // Handle cases where user does not have required permissions
      if (isAdminRoute) {
        return <Navigate to="/access-denied" replace />;
      }
      return <Navigate to="/home" replace />;
    }
  };
};