import { removeLocation } from '../../redux/DataRequestSelections/locationsSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
    updateChartLocations,
    updateChartMetric,
} from '../../redux/DataMenuSelections/chartsSlice';

import {
    updateReportLocations,
    updateReportDate,
} from '../../redux/DataMenuSelections/reportsSlice';



// Supporting function to sort locations based on their types
const sortLocationsByType = (locationsWithTypes, typeOrder) => {
    // Sort locations based on the predefined order in typeOrder
    return locationsWithTypes.sort((a, b) => {
        let indexA = typeOrder.indexOf(a.type);
        let indexB = typeOrder.indexOf(b.type);
        return indexA - indexB;
    });
};



const MarkerPopup = ({ location, onNavigate }) => {

    // Get map active selected date
    const { selectedDate : mapSelectedDate } = useSelector(state => state.maps);
    const { selectedMetric: mapMetric } = useSelector(state => state.maps);

    // Get available locations to be able to match location types
    const availableLocations = useSelector(state => state.dataMenu.availableLocations);

    // Sort reportAreas to sortedreportAreas, where the order follows this pattern
    const areaTypeOrder =["Postal Districts", "Local Authorities", "Regions", "Countries"]

    // Fucntion to retrieve the type of each area in reportAreas, to compare with the order above
    const getLocationsWithTypes = (reportAreas, availableLocations) => {
        // Create a map from location names to their respective types for quick lookup
        const locationTypeMap = new Map(availableLocations.map(loc => [loc.Location, loc.Type]));
    
        // Map each reportArea to its type using the map created above
        return reportAreas.map(area => {
            return {
                location: area,
                type: locationTypeMap.get(area) || 'Unknown' // Default to 'Unknown' if no type is found
            };
        });
    };


    // Functionality to dispatch actions to update Redux storage
    const dispatch = useDispatch();

    const handleDeletePin = () => {
        dispatch(removeLocation(location));
    }

    const handleReportChoiceButton = (reportAreas) => {
        // Retrieve and sort report areas by type
        const reportAreasWithTypes = getLocationsWithTypes(reportAreas, availableLocations);
        // console.log(reportAreasWithTypes);
        const sortedReportAreas = sortLocationsByType(reportAreasWithTypes, areaTypeOrder);
        // console.log(sortedReportAreas);
    
        // Dispatch the sorted report areas
        dispatch(updateReportLocations(sortedReportAreas.map(loc => loc.location)));
        dispatch(updateReportDate(mapSelectedDate));
        onNavigate('/location-reports');
    }
    
    const handleChartChoiceButton = (reportAreas) => {
        // Retrieve and sort report areas by type
        const reportAreasWithTypes = getLocationsWithTypes(reportAreas, availableLocations);
        // console.log(reportAreasWithTypes);
        const sortedReportAreas = sortLocationsByType(reportAreasWithTypes, areaTypeOrder);
        // console.log(sortedReportAreas);
    
        // Dispatch the sorted report areas
        dispatch(updateChartLocations(sortedReportAreas.map(loc => loc.location)));
        dispatch(updateChartMetric(mapMetric));
        onNavigate('/time-series');
    }


    // MAIN return statement for component structure
    return (
      <div className="map-popup-contents">

        <h4>{location.formatted_address}</h4>

        <div className='map-popup-area-options'>
            <button className='form-button'
                onClick={() => handleReportChoiceButton(location.availableDataMenuAreas)}>
                Report on nearby areas
            </button>
            <button className='form-button'
                onClick={() => handleChartChoiceButton(location.availableDataMenuAreas)}>
                Chart this metric for nearby areas
            </button>
        </div>


        <button className='form-button' style={{marginTop: '20px'}}
            onClick={handleDeletePin}
        >(Remove this pin)</button>
      </div>
    );
  };

export default MarkerPopup