import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import Grid, { GridCell } from '../DisplaySystem/Grid';
import InstallButton from '../Authentication/Installation/InstallButton';
import LoginForm from '../Authentication/AccountManagement/LoginForm';
import TipsDropdown from '../Components/InfoComponents/TipsDropdown';
import AuthContext from '../Authentication/PermissionLevels/AuthContext';
import MatrixBackground from '../Assets/BackgroundAnimation/MatrixBackground';
import { Link } from 'react-router-dom';
import UseWindowSize from '../DisplaySystem/WindowSize';
import SocialMediaShare from '../NetworkSharing/SocialMediaShare';
import shareGeneralSymbol from '../Assets/SocialShareLogos/shareGeneralSymbol.png';
import Modal from                 '../Components/InfoComponents/ModalBox';

function Home() {
  const { authState } = useContext(AuthContext);
  const { width } = UseWindowSize();
  const horizontalLayoutBreakpoint = 1200;
  const wideScreen = width > horizontalLayoutBreakpoint;

  // Get the new user guide state from redux to allow extra guidance for newer users
  const newUserGuideVersion = useSelector(state => state.appearancePersistence.newUserGuideVersion);

  // Share Modal system
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const handleOpenShareModal = () => setIsShareModalOpen(true);
  const handleCloseShareModal = () => setIsShareModalOpen(false);
  



  return (
    <div>
      {authState.isAuthenticated ? (
        <div className='content binary-map-background'>
          <Grid>
            <GridCell>
              <div style={{marginTop: wideScreen ? '40px' : '0px', marginLeft: '0px', width: '100%', height: '100%', overflowY: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                  <div style={{ width: wideScreen ? '80%' : 'auto', maxWidth: '650px', margin: '10px', marginLeft: wideScreen ? '10%' : '10px', marginRight: '10px', padding: '0px', marginTop: '25px', textAlign: wideScreen ? 'left' : 'center'}}>

                    <h2 style={{ marginBottom: '1px', fontSize: '25px'}}>Welcome to EvaluateLocate</h2>
                    <h3 style={{ marginLeft: '6px', marginTop: '1px', color: '#d2e7e9', marginBottom: wideScreen ? '20px' : '15px'}}  >Spatial intelligence at your fingertips</h3>

                    <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: wideScreen? 'left': 'center'}}>

                      <TipsDropdown
                        autoExpand={newUserGuideVersion}
                        title='Get started in seconds'
                        content={<div>
                            <p><b>EvaluateLocate is a spatial intelligence app that superpowers your location decision-making</b></p> 
                            <p style={{marginBottom: '4px'}}>Today's signal, not yesterday's noise, is just a click away:</p>
                            <ul>
                              <li><p style={{padding: '2px', margin: '0px'}}><b>Looking for a location?</b> Use our interactive <Link style = {{color: '#00b0bc', textDecoration: 'none', fontWeight: 'bold'}} to="/map">map</Link> to visualise 100+ economic and demographic metrics across the UK and the last 15 years</p></li>
                              <li><p style={{padding: '2px', margin: '0px'}}><b>Have a metric in mind?</b> Use our <Link style = {{color: '#00b0bc', textDecoration: 'none', fontWeight: 'bold'}} to="/time-series">charts</Link> and <Link style = {{color: '#00b0bc', textDecoration: 'none', fontWeight: 'bold'}} to="/location-reports">reports</Link> to track stats over time and build reports</p></li>
                              <li><p style={{padding: '2px', margin: '0px'}}><b>Want immediate outputs?</b> Create pdf reports, extract data to csv, or share on this platform... in seconds</p></li>
                            </ul>
                          </div>}
                      />
                      <TipsDropdown
                        autoExpand={false} // Note that for this case the autoExpand is set to false, not linked here to the newUserGuideVersion true/false object
                        title='Learn about the EvaluateLocate Economic Vitality Index (EVI)'
                        content={<div>
                          <p><b>Constantly-updated for the entire UK, the EVI lets you directly compare economic momentum and prosperity – for any location, at any point in time, in seconds.</b></p>
                          <p>Trained on millions of data points over fifteeen years, the EVI combines the latest in machine learning with impeccable sources.</p>
                          <p>The EVI makes it possible to compare and contrast how locations are faring in a fast-changing world.</p>
                          <p>Exploring locations starting with this headline superpowers your exploration of individual metrics such as business density, earnings, residential values, population proﬁle and employment.</p>
                          </div>}
                      />

                      <button
                        className='form-button'
                        style={{ display: 'flex', flexDirection: 'row', width: '85%', maxWidth: '500px', height: '45px', justifyContent: 'space-between', alignItems: 'center', marginTop: '15px', marginBottom: '5px', padding: '0px'}}
                        onClick={handleOpenShareModal}>
                          <img src={shareGeneralSymbol} alt="share symbol" className="social-share-image" style = {{ height: '30px', width: '30px', marginLeft: '15px'}}/>
                          <div style={{width: '100%', justifyContent: 'left', textAlign: 'left'}}>
                            <p style={{margin: '10px'}}>Superpower your team – share a registration link</p>
                          </div>
                      </button>
                      <Modal
                        isOpen={isShareModalOpen}
                        onClose={handleCloseShareModal}
                        title={'Invite your team & contacts to explore'}>
                          <p style={{marginTop: '0px', marginBottom: '12px'}}><b>Share a registration link in two clicks:</b></p>
                          <SocialMediaShare
                                      shareUrlInput={"https://app.EvaluateLocate.com/register"}
                                      suggestedTextInput={"EvaluateLocate - Registration link"}/>
                          <p style={{marginTop: '15px'}}>Your contacts can register in under a minute and you can share insights directly in the app</p>
                      </Modal>

                      <InstallButton/>

                    </div>
                  </div>

                <div className='version-footer'>
                  <p>EvaluateLocate application version 1.1.0 released 18th November 2024</p>
                  <p>Running EvaluateLocate GeoSpatial Predictor model version 1.0</p>
                  <p><span>&copy;</span> EvaluateLocate Ltd 2024</p>
                </div>

              </div>
            </GridCell>
          </Grid>
        </div>
       ) :(
        <div>
        <Grid>
          <GridCell>
            <LoginForm />
            <MatrixBackground/>
          </GridCell>
        </Grid>
        </div>
      )}
    </div>
  );
}

export default Home;