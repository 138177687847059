import React, { useState } from 'react';
import { useSelector } from       'react-redux';
import Grid, { GridCell } from    '../DisplaySystem/Grid';
import DataMenu from              '../RequestDataSystem/DataMenu';
import MetricDetailDropdown from  '../Components/InfoComponents/MetricDetailDropdown';
import TipsDropdown from          '../Components/InfoComponents/TipsDropdown';
import ChartWindow from           '../Components/ChartComponents/Chart';
import Modal from                 '../Components/InfoComponents/ModalBox';
import shareGeneralSymbol from '../Assets/SocialShareLogos/shareGeneralSymbol.png';
import { useExportPDF } from      '../ExportSystem/ExportPDFContext';
import SocialMediaShare from '../NetworkSharing/SocialMediaShare';
import pdfSymbol from '../Assets/SocialShareLogos/pdf.png';
import csvSymbol from '../Assets/SocialShareLogos/csv.png';
import { handleChartCSVExport } from '../ExportSystem/ChartCSVExport';



function ChartsPage() {
  const metricDescriptionDisplay = useSelector(state => state.charts.selectedMetricDescription);

  // Import universal state to double check if in exporting mode
  const isExporting = useSelector(state => state.appearancePersistence.exportingState);

  // Get the new user guide state from redux to allow extra guidance for newer users
  const newUserGuideVersion = useSelector(state => state.appearancePersistence.newUserGuideVersion);

  // Hold current filtered data to pass to csv export when needed
  const [chartFilteredData, setChartFilteredData] = useState([]);
  // Name imported pdf export function
  const { exportToPDF } = useExportPDF();

  // Get report date code
  const chartMetricName = useSelector(state => state.charts.selectedMetric);
  // Get headline list of selected locations
  const selectedLocations = useSelector(state => state.charts.selectedLocations);
  // Combine key info for filename
  const fileNameCSV = `EvaluateLocate_Exported_Chart_Data_${chartMetricName}_${selectedLocations}.csv`;
  const fileNamePDF = `EvaluateLocate_Exported_Chart_${chartMetricName}_${selectedLocations}.pdf`;

  // Share & Export Modal system
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const handleOpenShareModal = () => setIsShareModalOpen(true);
  const handleCloseShareModal = () => setIsShareModalOpen(false);

  // Combined handlers for export clicks
  const handlePDFExportClick = () => {
    exportToPDF({exportType: 'chart', fileName: fileNamePDF});
    setIsShareModalOpen(false);
  };
  const handleCSVExportClick = () => {
    handleChartCSVExport({ data: chartFilteredData, filename: fileNameCSV });
    setIsShareModalOpen(false);
  };


  return (
    <Grid>
      <GridCell>
        <div className='content-container top-start' style={{ marginTop: '5px', marginBottom: '5px'}}>

          <DataMenu
            settingChartMetric={true}
            settingChartLocations={true}
          />

          {!isExporting && <button
            className='form-button'
            style={{ display: 'flex', flexDirection: 'row', width: '85%', maxWidth: '500px', height: '45px', justifyContent: 'center', alignItems: 'center', marginTop: '15px', marginBottom: '5px', padding: '0px'}}
            onClick={handleOpenShareModal}>
              <img src={shareGeneralSymbol} alt="share symbol" className="social-share-image" style = {{ height: '30px', width: '30px'}}/>
              <p style={{margin: '10px'}}>Share or export this chart</p>
          </button>}
          {!isExporting && <Modal
            isOpen={isShareModalOpen}
            onClose={handleCloseShareModal}
            title={'Share or export'}>
              <h3>Share a link to this chart:</h3>
              <SocialMediaShare/>
              <br/>
              <h3>Export to your preferred format:</h3>
              <div style ={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' , textAlign: 'center'}}>
                {!isExporting && <button
                                  className='export-share-button'
                                  onClick={() => handlePDFExportClick()}>
                                    <p style = {{ margin: '1px'}}>Export this chart to .pdf</p>
                                    <p style = {{fontSize: '11px', margin: '1px'}}>(10 tokens)</p>
                                    <img src={pdfSymbol} alt="PDF symbol" className="social-share-image" style = {{ height: '52px', width: '55px'}}/>
                                  </button>}
                {!isExporting && <button
                                  className='export-share-button'
                                  onClick={() => handleCSVExportClick()}>
                                    <p style = {{ margin: '1px'}}>Export chart data to .csv</p>
                                    <p style = {{fontSize: '11px', margin: '1px'}}>(10 tokens)</p>
                                    <img src={csvSymbol} alt="CSV symbol" className="social-share-image" style = {{ height: '52px', width: '55px'}}/>
                                </button>}
              </div>
          </Modal>}

          {!isExporting && <MetricDetailDropdown
            title="About this metric"
            content={<div>
                       <p>{metricDescriptionDisplay}</p>
                     </div>}/>}

          {!isExporting && <TipsDropdown
            autoExpand={newUserGuideVersion}
            title="Generating Your Charts"
            content={ <div>
                        <p>Choose the metric you want to chart, and then select a location – you can do this by typing it in or using the location drop down menu.</p>
                        <p>To select a period of time to track, use the slider under chart.</p>
                        <p>Pick multiple locations ranging from a postcode district through to a local authority, city or region to create your chart.</p>
                     </div>}/>}
        </div>
      </GridCell>
      <GridCell>
        <ChartWindow
         setChartFilteredData={setChartFilteredData}/>
      </GridCell>
    </Grid>
  );
}
export default ChartsPage;