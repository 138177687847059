import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import fetchDataFromAPI from './FetchDataAPIFunction';
import { APP_LOCAL_STORAGE_TIMEOUT_MAX_AGE } from '../config';
import { storeMapData } from '../redux/DataRequestSelections/fetchedDataSlice';
import { updateMapDataRef } from '../redux/DataMenuSelections/mapsSlice'
import generateUniqueRequestRef from '../redux/DataRequestSelections/UniqueRequestReference';
import { useNavigate } from 'react-router-dom';
import { setMapPdfFileName } from '../redux/AppearancePersistence/appearancePersistenceSlice';
import { translateDate, parseYYYYMM } from './DataDateFormattingFunctions';

const useFetchMapDataOnSelectionChange = () => {
  const dispatch = useDispatch();
  const mapSelectedDate = useSelector((state) => state.maps.selectedDate);
  const mapSelectedMetric = useSelector((state) => state.maps.selectedMetric);
  const fetchedMapData = useSelector(state => state.fetchedData.mapData);

  // Credits exhausted check system
  const [noCredits, setNoCredits] = useState(false);
  const navigate = useNavigate();
  useEffect (() => {
    if (noCredits) {
      navigate('/account');
    }
  }, [noCredits, navigate]);

  // Create simple functionality to update PDF export file name
  const UpdatePDFExportFileName = (fileName) => {
    dispatch(setMapPdfFileName(fileName));
  }
  // Supporting function to translate selected date for file name
  const simplifyDate = (date) => {
    return date ? translateDate(parseYYYYMM(date))  : null;
  };


  useEffect(() => {
    const fetchMapData = async () => {
      // Generate a UniqueRequestRef for the current selection
      // This function takes params in the order of location, metric, date.
      const UniqueRequestRef = generateUniqueRequestRef(['ReturnAll'], mapSelectedMetric, mapSelectedDate);
      const currentTime = new Date().getTime();

      const dataEntry = fetchedMapData[UniqueRequestRef];
      if (dataEntry) {
        const dataTimestamp = new Date(dataEntry.timestamp).getTime();
        if (currentTime - dataTimestamp < APP_LOCAL_STORAGE_TIMEOUT_MAX_AGE) {
          //console.log('Maps data is fresh, skipping API call.');
          // Update the current reference to the already-stored data (also as below)
          dispatch(updateMapDataRef(UniqueRequestRef));
          return; // In this case, data is still fresh, no need to fetch
        }
      }

      try {
        // This function also takes params in the order of location, metric, date.
        // One of which can be set to 'ReturnAll' to return an array along that dimension.
        const mapData = await fetchDataFromAPI('ReturnAll', mapSelectedMetric, mapSelectedDate, setNoCredits);
        // Store the fetched data along with its UniqueRequestRef in the Redux store
        dispatch(storeMapData({
          reference: UniqueRequestRef,
          data: mapData,
          timestamp: new Date().toISOString()
        }));
        // Also update the current reference to the newly-stored data
        dispatch(updateMapDataRef(UniqueRequestRef));
      } catch (error) {
        console.error('Error fetching or storing requested maps data:', error);
      }
    };
    // Only run the fetch if the selected dependencies are truthy
    if (mapSelectedDate && mapSelectedMetric) {
      fetchMapData();
    }

    // Always update the PDF export file name
    UpdatePDFExportFileName('EvaluateLocate Map Export '+mapSelectedMetric+' '+simplifyDate(mapSelectedDate)+'.pdf');

    // fetchedMapData is defined by other dependencies and its inclusion would also cause infinite loop. Hence next warning disable note.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ mapSelectedDate, mapSelectedMetric, dispatch ]);
};

export default useFetchMapDataOnSelectionChange;