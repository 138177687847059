import React from 'react';
import RegistrationTermsWording from './RegTermsConditionsWording';


function TermsConditionsBox({ onClose }) {
  return (
    <div>
      <div>
        {RegistrationTermsWording && <p className="form-plaintext-box">{RegistrationTermsWording}</p>}
      </div>
      <div>
        <button onClick={onClose} className="form-button muted-border">Close</button>
      </div>
    </div>
  );
}

export default TermsConditionsBox;
