import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  center: [53.5, -4],
  zoom: 7,
};

export const mapPersistenceSlice = createSlice({
  name: 'mapPersistence',
  initialState,
  reducers: {
    setMapCenter: (state, action) => {
      state.center = action.payload;
    },
    setMapZoom: (state, action) => {
      state.zoom = action.payload;
    }
  }
});

export const { setMapCenter, setMapZoom } = mapPersistenceSlice.actions;

export default mapPersistenceSlice.reducer;