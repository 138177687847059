import React, { useEffect, useState } from 'react';
import { roundToThreeSignificantFigures, formatDate } from '../../RequestDataSystem/DataDateFormattingFunctions';
import { determineStatPrefix, determineStatSuffix }   from '../../RequestDataSystem/DataMetricFormatFunctions';
import { useSelector, useDispatch } from 'react-redux';
import { ConvertDateToYYYYMM } from '../../RequestDataSystem/DataDateFormattingFunctions';
import { useNavigate } from 'react-router-dom';
import {
  updateMapDate,
  updateMapMetric,
} from '../../redux/DataMenuSelections/mapsSlice';
import {
  updateReportLocations,
  updateReportDate,
} from '../../redux/DataMenuSelections/reportsSlice';
import Modal from '../InfoComponents/ModalBox';

// Separate supporting function for vertical reference line
function VerticalLine({ visible, xPosition, height }) {

    // Additional delay to rendering of vertical line component
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 1000);
      return () => clearTimeout(timer);
    }, []); // Empty dependency array means this runs only once after mounting
    if (!isVisible) {
      return null;
    }
    // Now can return when it has completed the delay
    // also check for the overall setting for the line visibility as a param here
    if (!visible) return null;
    return (
      <div
        style={{
          position: 'absolute',
          left: `${xPosition+9}px`,
          top: '5px',
          bottom: '0px',
          width: '3px',
          backgroundColor: 'white',
          opacity: 0.8,
          height: `${height}px`
        }}
      />
    );
  }
  


function ChartTooltip({ tooltipActive, tooltipManual, chartGridWidth, chartGridHeight, chartPositionXOffset, chartPositionYOffset, payload, xPosition, yPosition, metricName, backupDate, isAnimatingChart, onClose }) {

    // Route navigation import for navigation from tooltip
    const navigate = useNavigate();
    const handleNavigate = (url) => {
      navigate(url);
    };
    // Define dispatch function
    const dispatch = useDispatch();

    // Directly get active selected metric
    const { selectedMetric: chartSelectedMetric } = useSelector(state => state.charts);
    const { selectedLocations: chartSelectedAreas } = useSelector(state => state.charts);
    
    // Set up of tooltip navigation functions
    const handleReportChoiceButton = (selectedDate) => {
      // Sync report areas with current chart areas
      dispatch(updateReportLocations(chartSelectedAreas));
      // Sync report date
      dispatch(updateReportDate(selectedDate));
      // Navigate after state update
      handleNavigate('/location-reports');
    }
    const handleMapChoiceButton = (selectedDate) => {
        // Sync map date with relevant date selection
        dispatch(updateMapDate(selectedDate));
        // Sync map metric with current chart metric
        dispatch(updateMapMetric(chartSelectedMetric));
        // Navigate after state update
        handleNavigate('/map');
    }

  
    // Define tooltip offset variables
    let tooltipWidth = 150;
    let tooltipHeight = 200;
    // Get bounding rectangles for custom-tooltip to amend tooltip position if necessary
    const chartTooltipElement = document.querySelector('.custom-tooltip');
    if (chartTooltipElement) {
      const tooltipBoundingRect = chartTooltipElement.getBoundingClientRect();
      tooltipWidth = tooltipBoundingRect.right - tooltipBoundingRect.left;
      tooltipHeight = tooltipBoundingRect.bottom - tooltipBoundingRect.top;
    }
    // Additional margin from edges
    const tooltipExtraAutoMargin = 15;

    const XOffset = ((xPosition - chartPositionXOffset + tooltipWidth + tooltipExtraAutoMargin)> chartGridWidth)? -(xPosition -chartPositionXOffset + tooltipWidth + tooltipExtraAutoMargin - chartGridWidth) : 0;
    const YOffset = ((yPosition - chartPositionYOffset + tooltipHeight + tooltipExtraAutoMargin)> chartGridHeight)? -(yPosition - chartPositionYOffset + tooltipHeight + tooltipExtraAutoMargin - chartGridHeight): 0;
    const XPosition = xPosition + XOffset;
    const YPosition = yPosition + YOffset;

    // This initial style is first applied in the return below
    const tooltipStyle = {
      position: 'absolute',
      left: `${XPosition}px`, 
      top: `${YPosition}px`
      // Note colours and other visuals set in CSS
    };

    
    // Chart Tooltip Modal system
    const [isTooltipModalOpen, setIsTooltipModalOpen] = useState(false);
    const handleOpenTooltipModal = () => setIsTooltipModalOpen(true);
    const handleCloseTooltipModal = () => setIsTooltipModalOpen(false);
  


    // Start of conditional rendering logic 
    if (!tooltipActive) return null;
    // Else continue with conditional return statement logic
    let label = null;
    let currentChartDatePointerRef = null;
    // Determine label based on payload availability
    if (payload && tooltipManual) {
        try{
            // First set the label in more natural format
            label = formatDate(payload[0].payload.date, 'monthYear');
            // Also set the formal reference (for navigation)
            currentChartDatePointerRef = ConvertDateToYYYYMM(payload[0].payload.date);
        } catch (error) {
        }            
    } else {
        label = formatDate(backupDate, 'monthYear');
        currentChartDatePointerRef = ConvertDateToYYYYMM(backupDate);
    }
    // Return statement fires if payload is present
    if( payload ) {
        return (
            <div>
                {tooltipActive && <VerticalLine visible={true} xPosition={xPosition} height={chartGridHeight} />}
                <div className="custom-tooltip" style={tooltipStyle}>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                      <h4 style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}><b>{label}</b></h4>
                      </div>
                      <button className="XCloseButton" onClick={onClose}>x</button>
                  </div>
                  {payload.map((item, index) => {
                    const value = roundToThreeSignificantFigures(item.value);
                    const formattedValue = value !== undefined ? value.toLocaleString(undefined, { maximumFractionDigits: 3 }) : 'N/A';
                    return (<p key={index} style={{ color: item.color }}>
                      <b>{`${item.name}: ${determineStatPrefix(metricName)}${formattedValue}${determineStatSuffix(metricName)}`}</b>
                      </p>)
                  }
                  )}

                  {!isAnimatingChart && !isTooltipModalOpen &&
                    <button className="form-button"
                    onClick={handleOpenTooltipModal}>
                        Explore more
                    </button>
                  }

                  <Modal
                    isOpen={isTooltipModalOpen}
                    onClose={handleCloseTooltipModal}
                    title={'Explore more:'}>
                          <div>
                            <h3>{metricName+', '+label}</h3>
                            <button className='form-button'
                              onClick={() => handleMapChoiceButton(currentChartDatePointerRef)}>
                              Map this metric at this date
                            </button>
                            <button className='form-button'
                              onClick={() => handleReportChoiceButton(currentChartDatePointerRef)}>
                              Build report on current areas at this date
                            </button>
                          </div>
                  </Modal>
                </div>
            </div>
          );
    } else {
        return null;
    }

  }

export default ChartTooltip;