import { useSelector, useDispatch } from 'react-redux';
import { setAvailableLocations, setAvailableMetrics, setAvailableDates, setAvailableGeographyTypes } from './dataMenuAvailableSlice';

export const useUpdateDataMenuState = () => {
  const dispatch = useDispatch();
  const availableLocations = useSelector((state) => state.dataMenu.availableLocations);
  const availableMetrics = useSelector((state) => state.dataMenu.availableMetrics);
  const availableDates = useSelector((state) => state.dataMenu.availableDates);
  const availableGeographyTypes = useSelector((state) => state.dataMenu.availableGeographyTypes);

  const updateAvailableLocations = (locations) => {
    dispatch(setAvailableLocations(locations));
  };

  const updateAvailableMetrics = (metrics) => {
    dispatch(setAvailableMetrics(metrics));
  };

  const updateAvailableDates = (dates) => {
    dispatch(setAvailableDates(dates));
  };

  const updateAvailableGeographyTypes = (geographyTypes) => {
    dispatch(setAvailableGeographyTypes(geographyTypes));
  };

  return {
    availableLocations,
    availableMetrics,
    availableDates,
    availableGeographyTypes,
    updateAvailableLocations,
    updateAvailableMetrics,
    updateAvailableDates,
    updateAvailableGeographyTypes,
  };
};
