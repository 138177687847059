import axios from 'axios';
import { API_URL } from '../config';





// This takes params in the order of location, metric, date. One of which can be set to 'ReturnAll' to return an array along that dimension.
const fetchDataFromAPI = async (locationLabel, metricLabel, dateLabel, setNoCredits) => {
    
    try {
        const token = localStorage.getItem('userToken');
        const response = await axios.post(
          `${API_URL}/DataRequest`,
          null,
          {
            params: {
              locationLabel:locationLabel,
              metricLabel:metricLabel,
              dateLabel:dateLabel,
            },
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        // Check if the API response has a nested 'data' object and return it directly
        const fetchedData = response.data && response.data.data ? response.data.data : response.data;
        return fetchedData;
    } catch (error) {

        // Check for 402 error and handle reroute to account page when credits are exhausted
        console.error('CONSOLE LOG error'+error);
        if (axios.isAxiosError(error) && error.response.status === 402) {
            console.log('found 402');
            setNoCredits(true);
        }

        // General error catch
        console.error('Error fetching data:', error);
        throw error;
    }
};
export default fetchDataFromAPI;