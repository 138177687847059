// Converts a hex code to an RGB array
function hexToRgb(hex) {
    let r = parseInt(hex.slice(1, 3), 16);
    let g = parseInt(hex.slice(3, 5), 16);
    let b = parseInt(hex.slice(5, 7), 16);
    return [r, g, b];
}

// Converts RGB array to HSL
function rgbToHsl(r, g, b) {
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h = 0, s = 0;  // Explicitly declare h and s
    let l = (max + min) / 2;  // Assign l separately for clarity

    if (max !== min) {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
                h = (r - g) / d + 4;
                break;
            default:
                // Log an unexpected issue or handle gracefully
                console.warn('Unexpected case in RGB to HSL conversion');
                break;
        }
        h /= 6;
    }

    return [h * 360, s * 100, l * 100];
}



// Wrapper function using above, to convert hex colours directly to HSL
export function hexToHsl(hex) {
    const [r, g, b] = hexToRgb(hex);
    return rgbToHsl(r, g, b);
}

// Interpolate between HSL colours with fractional weight to second input
export function interpolateHsl(hsl1, hsl2, fraction) {
    const [h1, s1, l1] = hsl1;
    const [h2, s2, l2] = hsl2;
    const h = h1 + (h2 - h1) * fraction;
    const s = s1 + (s2 - s1) * fraction;
    const l = l1 + (l2 - l1) * fraction;
    return `hsl(${h}, ${s}%, ${l}%)`;
}