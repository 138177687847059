import  { React, useState, useEffect, useCallback, useRef } from 'react';
import { addLocation } from '../../redux/DataRequestSelections/locationsSlice';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import UseWindowSize from '../../DisplaySystem/WindowSize';
import checkLocationMatches from '../../RequestDataSystem/CheckSearchedLocationMatches';

// Support function for zoom levels
const convertDegreesToZoom = (degrees) => {
  if (degrees >= 3.5) return 7;
  if (degrees >= 2.5) return 8;
  if (degrees >= 1) return 9;
  if (degrees >= 0.5) return 9.5;
  if (degrees >= 0.25) return 10;
  if (degrees >= 0.125) return 11;
  if (degrees >= 0.0625) return 12;
  if (degrees >= 0.03125) return 13;
  return 14; // Default zoom level for very small degrees
};

// MAIN COMPONENT
const MapSearch = ({ setMapSearchCenter, setMapSearchZoom, setMarkerActiveSetting, setNewMarkerLatLon, setNewMarkerFormattedAddress }) => {

  // Dynamic rendering system based on width
  const { width } = UseWindowSize();
  const isSmallScreen = width < 1000;
  const [addressBarManualClosed, setAddressBarManualClosed] = useState(false);

  // Conditional style toggles on and off depending on if address bar should be expanded
  const [addressBarActiveSetting, setAddressBarActiveSetting] = useState('');

  // States for input, results and chosen result
  const [addressSearchInput, setAddressSearchInput] = useState('');
  const inputRef = useRef(null);
  const [addressBarResults, setAddressBarResults] = useState([]);
  const [addressBarChosenResult, setAddressBarChosenResult] = useState(null);
  // Note that setSearchResults, setSearchInputValue are separate to pass info up to the parent


  // Core search function handles API call with Google Geocoding API
  const handleSearch = useCallback(async (searchStringParam) => {
    try {
      const trimmedString = searchStringParam.trim();
      const amendedString = trimmedString+ ', UK';
      const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
        params: {
          address: amendedString,
          region: 'gb',
          key: 'AIzaSyCLEZge_f_iEfQNZsVXXdr99pPsMww5XIs'
        }
    });
    if (response.data.status === 'OK' && response.data.results.length > 0 && response.data.results[0].formatted_address !== 'United Kingdom') {
        setAddressBarResults(response.data.results); // Set the local list of search results
      } else {
        console.error('Geocoding error: No results found or invalid status');
      }
    } catch (error) {
      console.error('Geocoding search error:', error);
    }
  }, [setAddressBarResults]);


  // Separate function to dispatch selected loctions from search functionality to redux
  const dispatch = useDispatch();
  const addSearchedLocation = (newLocation) => {
    dispatch(addLocation(newLocation));
  }

  // Source the available dataMenu areas to check if they match the chosen result address components
  const availableLocations = useSelector((state) => state.dataMenu.availableLocations);



  // Combine this with the function setAddressBarChosenResult
  
  const selectSingleLocation = (location) => {
    // Extract the long names from address components for matching
    const addressLongNames = location.address_components.map(component => component.long_name);
    // Parse the formatted address to extract unique word elements
    const formattedAddressWords = location.formatted_address.split(/[ ,]+/).filter(Boolean);
    // Combine the arrays and remove duplicates using a Set
    const addressNamesToCheck = [...new Set([...addressLongNames, ...formattedAddressWords])];
  
    // Compare unique address elements to available location areas menu
    const matches = checkLocationMatches(addressNamesToCheck, availableLocations);
    const availableAddressComponents = matches.filter(result => result.isMatch).map(result => result.component);

    // Add the matched components to the location object as a new property
    const updatedLocation = {
      ...location,
      availableDataMenuAreas: availableAddressComponents
    };

    console.log(updatedLocation);
    
    // Set the search input value to the chosen result
    setAddressBarChosenResult(updatedLocation);    
    // Also dispatch the chosen result to redux store of recent locations
    addSearchedLocation(updatedLocation);
    setMarkerActiveSetting(true);
    // Also pass up the lat and lon of the chosen result
    setNewMarkerFormattedAddress(updatedLocation.formatted_address);
    setNewMarkerLatLon({ lat: updatedLocation.geometry.location.lat, lon: updatedLocation.geometry.location.lng });

  }
  


  // Icon click expands/collapses the address bar or submits the search
  const handleClick = () => {
    if (addressBarActiveSetting==='') {
      // First click expands the address bar
      setAddressBarActiveSetting('-active');
    } else {
      if (addressSearchInput.length >= 3) {
        handleSearch(addressSearchInput); // Second click submits the search
      } else {
        setAddressBarActiveSetting('');
        setAddressBarManualClosed(true);
        setAddressSearchInput('');
        setAddressBarResults([]);
        setAddressBarChosenResult(null);
      }
    }
  }

  // Auto adjust the search bar's initial state based on screen size with a delay
    setTimeout(() => {
      if (!isSmallScreen && !addressBarManualClosed) { // If it's not a small screen and the search bar has not already been manually closed, expand the search bar
        setAddressBarActiveSetting('-active');
      }
    }, 250); // Delay in milliseconds for any auto search bar expansions
    

  // When the address bar is expanded, focus the input box
  useEffect(() => {
    if (addressBarActiveSetting === '-active') {
      inputRef.current?.focus();
    }
  }, [addressBarActiveSetting]);
    

  // User change in input box sets the local addressSearchInput state in this component and resets the timeout
  const [searchTimeout, setSearchTimeout] = useState(null);
  const handleInputChange = (event) => {
    const { value } = event.target;
    setAddressSearchInput(value); // Update the input state
    if (searchTimeout) {
      clearTimeout(searchTimeout); // Clear the existing timeout
      setSearchTimeout(null); // Reset the timeout state
    }
  };

  // Handle key press, used in input box
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === 'NumpadEnter' || event.key === 'Tab') {
      event.preventDefault(); // Prevent the default action to avoid undesired form submissions or focus changes
      if (addressBarResults.length > 0) {
        // If there is at least one result, choose the first one on a further enter key press
        selectSingleLocation(addressBarResults[0]);
      } else {
        // Enter key variations are the same as a click on the search icon if there are no results
        handleClick();
      }
    }
  };

  // Timeout such that after a certain delay of inactivity the search happens without a click
  useEffect(() => {
    // Only set up the auto-search if the input length is at least three characters
    if (addressSearchInput.length >= 3) {
      const newTimeout = setTimeout(() => {
        handleSearch(addressSearchInput); // Trigger the search
      }, 500); // Delay in milliseconds between checking the input
  
      // Set the timeout state
      setSearchTimeout(newTimeout);
  
      // Clear the timeout when the component unmounts or the input changes
      return () => clearTimeout(newTimeout);
    }
  }, [addressSearchInput, handleSearch]); // Depend on addressSearchInput



  // When there is only one result, set this as the location for the map
  useEffect(() => {
    let singleChoiceTimeout;
    if (addressBarResults.length===1) {
      // Take the info from the first location after a delay, if just one location
      singleChoiceTimeout = setTimeout(() => {
        selectSingleLocation(addressBarResults[0]);
        setAddressSearchInput('');
        setAddressBarResults([]);
      }, 3000); // Delay in ms to allow user to check a single location result is intended
    } 
    return () => clearTimeout(singleChoiceTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressBarResults])


  // Effect watches for a chosen result then navigates there
  // Debounce the redux setting to ensure in sync with the parent component. Must not be slower than manual map move position store to avoid moving back
  useEffect(() => {
    if (addressBarChosenResult){
      let locationZoomLevel = 12;
      try{
        const locationLatSize = addressBarChosenResult.geometry.bounds.northeast.lat - addressBarChosenResult.geometry.bounds.southwest.lat;
        const locationLngSize = addressBarChosenResult.geometry.bounds.northeast.lng - addressBarChosenResult.geometry.bounds.southwest.lng;
        const locationDegreesApproxSize = Math.max(locationLatSize, locationLngSize);
        locationZoomLevel = convertDegreesToZoom(locationDegreesApproxSize)
      } catch (error) {
        locationZoomLevel = 12;
        console.log('Error calculating zoom level for an area, reverting to default. Likely a point-type search query', error);
      }
      // Set state in redux and parent component
      const location = addressBarChosenResult.geometry.location;
      setMapSearchCenter({ lat: location.lat, lng: location.lng });
      setMapSearchZoom(locationZoomLevel);
      setAddressBarActiveSetting('');

      // Also asynchronously clear the local state for search input and results, ready for next use
      setAddressSearchInput('');
      setAddressBarResults([]);
      setAddressBarChosenResult(null);
    }
  }, [addressBarChosenResult, setMapSearchCenter, setMapSearchZoom]);

  

  return (
    <div className= {'map-search'+addressBarActiveSetting}>
      <button className= 'map-search-icon'
        onClick={handleClick}>
      </button>
      {addressBarActiveSetting==='-active'
      && <input className={'map-search-input'+addressBarActiveSetting}
        ref={inputRef}
        type="text"
        value={addressSearchInput}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Search any address..."
      />}
      {addressBarActiveSetting==='-active' &&
      <div className={'map-search-results-list'+addressBarActiveSetting}>
        {addressBarResults.map((result) => (
          <div
            className='map-search-result'
            key={result.place_id}
            onClick={() => selectSingleLocation(result)}
          >
            {result.formatted_address}
          </div>
        ))}
      </div>}
    </div>
  );
};

export default MapSearch;