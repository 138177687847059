import { useState, useEffect  , useMemo } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { selectAllChartData } from '../../redux/Selectors/ChartsPageSelectors';
import { parseYYYYMM, ConvertDateToYYYYMM, formatDate, determineFormat } from '../../RequestDataSystem/DataDateFormattingFunctions';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer, ReferenceLine } from 'recharts';
import ChartTooltip from './ChartTooltip';
import { curveCardinal } from 'd3-shape';
import DateRangeSlider from './SliderRange';
import LoadingWheel from '../../Assets/LoadingSystem/LoadingWheel';
import {determineStatPrefix, determineStatSuffix} from '../../RequestDataSystem/DataMetricFormatFunctions';
import { setLoading, setChartDateRangePersist } from '../../redux/AppearancePersistence/appearancePersistenceSlice';


// Supporting data transformation function
const transformDataToUnified = (inputData) => {
  // Define dateLabels within this function now
  let dateLabels = [];
  try {
    dateLabels=  inputData[0]?.labels?.DateLabels || [];
  } catch (error) {
    console.error('Error deriving date labels:', error);
  }

  // Transform the data into a unified format
  let unifiedData = dateLabels.map(date => {
    let dataPoint = { date: parseYYYYMM(date) };
    inputData.forEach(series => {
      const seriesId = `${series.labels.LocationLabels[0]}_${series.labels.MetricLabels[0]}`;
      const index = series.labels.DateLabels.indexOf(date);
      dataPoint[seriesId] = index !== -1 ? series.values[index] : null;
    });
    return dataPoint;
  });
  // Function to check if any series values in a data point are invalid

  // Check if any series values are invalid, not just if all are
  const anyValueInvalid = (dataPoint) => {
    const seriesValues = Object.keys(dataPoint).filter(key => key !== 'date').map(key => dataPoint[key]);
    return seriesValues.some(value => value === null || value === undefined || value === 'NA' || (typeof value === 'number' && isNaN(value)));
  };

  // Trim invalid data points from the start and end
  let startIndex = 0;
  let endIndex = unifiedData.length - 1;
  while (startIndex <= endIndex && anyValueInvalid(unifiedData[startIndex])) {
    startIndex++;
  }
  while (endIndex >= startIndex && anyValueInvalid(unifiedData[endIndex])) {
    endIndex--;
  }
 // Slice the data to remove invalid points
  unifiedData = unifiedData.slice(startIndex, endIndex + 1);
  return {
    unifiedData,
    startIndex,
    endIndex
  };
};

// Supporting functionality to set chart line default colour codes
const colours = ['#80ED99', '#ED6A5A', '#9e7bd6', '#E6331A', '#33FFCC', '#B366CC', '#4D8000',
                 '#B33300', '#CC80CC', '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399', '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680', 
                 '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933', '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3', '#E64D66', '#4DB380'];

// Supporting function to return a colour hex code for a given series index
const dynamicColorForIndex = (seriesName, index) => {
  if (seriesName === "United Kingdom") {
    return "#2FE6DE "; // Light Grey colour for "United Kingdom" as special case
  } else {
    return colours[index % colours.length];
  }
}
// Function to round a number or an array of numbers to significant figures
const roundToSignificantFigures = (num, sigFigs) => {
  if (Array.isArray(num)) {
      return num.map(n => roundToSignificantFigures(n, sigFigs));
  }
  if (num === 0) return 0;
  const multiplier = Math.pow(10, sigFigs - Math.ceil(Math.log10(Math.abs(num))));
  return Math.round(num * multiplier) / multiplier;
};
// Standalone function to round a number A to the nearest multiple of M, with an option to round up or down
const roundToNearestMultiple = (A, M, direction = 'nearest') => {
  if (M === 0) return A; // Avoid division by zero
  switch (direction) {
      case 'up':
          return Math.ceil(A / M) * M;
      case 'down':
          return Math.floor(A / M) * M;
      default:
          return Math.round(A / M) * M;
  }
};





// Main function
function ChartWindow({setChartFilteredData}) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [isAnimatingChart, setIsAnimatingChart] = useState(false);


  // Get bounding rectangles for chart grid, Y-axis, recharts-wrapper and chart container
  const chartGridElement = document.querySelector('.recharts-cartesian-grid');
  const chartWrapperElement = document.querySelector('.recharts-wrapper');
  // Calculate the chart Position Offsets and grid sizes if the elements exist
  let chartPositionXOffset = 0;
  let chartPositionYOffset = 0;
  let chartGridWidth = 1;
  let chartGridHeight = 1;
  if (chartGridElement && chartWrapperElement) {
    // Get the bounding rectangles of the grid and the container
    const chartGridBoundingRect = chartGridElement.getBoundingClientRect();
    const chartsWrapperBoundingRect = chartWrapperElement.getBoundingClientRect();
    // Calculate the grid offset relative to the recharts-wrapper and the grid width
    chartPositionXOffset = chartGridBoundingRect.left - chartsWrapperBoundingRect.left;
    chartPositionYOffset = chartGridBoundingRect.top - chartsWrapperBoundingRect.top;
    chartGridWidth = chartGridBoundingRect.right - chartGridBoundingRect.left;
    chartGridHeight = chartGridBoundingRect.bottom - chartGridBoundingRect.top;
  } else {
  }



  // Get name of metric being displayed
  const metricName = useSelector(state => state.charts.selectedMetric);
  // Get chart data from Redux state management
  const inputData = useSelector((state) => {
    try {
      return selectAllChartData(state);
    } catch (error) {
      console.error('Error fetching chart data:', error);
      return [];
    }
  }, shallowEqual);


  // Combine all series with the same date x axis, noting the beginning and end index in terms of the original dateLabels range
  const [unifiedData, setUnifiedData] = useState([]);
  const [validDataStartIndex, setValidDataStartIndex] = useState(null);
  const [validDataEndIndex, setValidDataEndIndex] = useState(null);

  // Update the unified data and the start and end index values whenever inputData changes
  useEffect(() => {
    if (inputData.length > 0) {
      try {
        const { unifiedData, startIndex, endIndex } = transformDataToUnified(inputData);
        setUnifiedData(unifiedData);
        setValidDataStartIndex(startIndex);
        setValidDataEndIndex(endIndex);
      } catch (error) {
        console.error('Error transforming data:', error);
        setUnifiedData([]);
        setValidDataStartIndex(0);
        setValidDataEndIndex(0);
      }
    }
  }, [inputData]);


  // Ready initial values of selected range
  const initialRange = useMemo(() => {
    return unifiedData.length > 1 ? [0, unifiedData.length - 1] : [0, 1];
  }, [unifiedData]);
  // Define variables for selected range and date format: all with initial values
  const [selectedRange, setSelectedRange] = useState(initialRange);
  const [dateFormat, setDateFormat] = useState(determineFormat(initialRange[1]-initialRange[0]) || 'defaultFormat');

  // Ready initial values of filtered data
  const initialFilteredData = useMemo(() => {
    const initialFormattedData = unifiedData.map(data => ({
      ...data,
      date: formatDate(data.date, 'monthYear')
    }));
    return initialFormattedData.slice(initialRange[0], initialRange[1] + 1);
  }, [unifiedData, initialRange]);
  // Define filtered data
  const [filteredData, setFilteredData] = useState(initialFilteredData);


  // Create system to adjust x axis tick interval for date format
  const getXAxisTicks = (data, dateFormat) => {
    if (dateFormat === 'year') {
      const ticks = [];
      data.forEach((entry) => {
        const date = entry.date;
        if (date.getMonth() === 0) { // Check if the month is January
          ticks.push(entry.date);
        }
      });
      return ticks;
    }
    return data.map(entry => entry.date); // Default to every month
  };
  // Initialisation of x axis ticks
  const [xAxisTicks, setXaxisTicks] = useState(() => null);
  

  // Define variables for tooltips legend behaviour
  const [tooltipActive, setTooltipActive] = useState(true);
  const [tooltipManual, setTooltipManual] = useState(false);
  const [tooltipPositionX, setTooltipPositionX] = useState(250); // Initial default position
  const [tooltipPositionY, setTooltipPositionY] = useState(150);


  // Define associated functionality for closing tooltip button
  const handleCloseTooltip = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // Actual setting of tooltip to inactive is done finally
    setTooltipActive(false);
  }
  // Define the system to store the tooltip position in terms of a ratio of the grid
  // This changes after either clicks or new data selection
  const [tooltipRatio, setTooltipRatio] = useState({x: 1, y: 0.5});
  // Recalculate the tooltip position whenever the chart dimensions or click ratio change
  useEffect(() => {
      let tooltipPositionReCalc = {
        x: (tooltipRatio.x * chartGridWidth) + chartPositionXOffset,
        y: (tooltipRatio.y * chartGridHeight) + chartPositionYOffset
      }
      setTooltipPositionX(tooltipPositionReCalc.x);
      setTooltipPositionY(tooltipPositionReCalc.y);
    
  }, [tooltipManual, tooltipRatio.x, tooltipRatio.y, chartGridWidth, chartGridHeight, chartPositionXOffset, chartPositionYOffset]);
  // Additional support useEffect to ensure the tooltip ratio does not exceed 0 to 1 range
  // This applies whenever the position ratio changes (new data or new click)
  useEffect (() => {
    if (tooltipRatio.x > 1) {setTooltipRatio({x: 1, y: tooltipRatio.y});}
    if (tooltipRatio.y > 1) {setTooltipRatio({x: tooltipRatio.x, y: 1});}
    if (tooltipRatio.x < 0) {setTooltipRatio({x: 0, y: tooltipRatio.y});}
    if (tooltipRatio.y < 0) {setTooltipRatio({x: tooltipRatio.x, y: 0});}
  }, [tooltipRatio.x, tooltipRatio.y]);


 
  // Define variables for last data label and payload (for auto end tooltip) with suitable initialisation
  const [lastDataPayload, setLastDataPayload] = useState(() => {
    if (!initialFilteredData.length) return [];
    const lastData = initialFilteredData[initialFilteredData.length - 1];
    return inputData.map((series, index) => ({
      name: `${series.labels.LocationLabels[0]}`,
      value: lastData[`${series.labels.LocationLabels[0]}_${series.labels.MetricLabels[0]}`],
      color: dynamicColorForIndex(index, series.labels.LocationLabels[0])
    }));
  });

  // Get simple available dates vector
  const availableDates = useSelector(state => state.dataMenu.availableDates);
  const [simpleAvailableDates, setSimpleAvailableDates] = useState([]); // Initialise as empty array
  // Update simple available menu arrays when Redux changes
  useEffect(() => {
    if (availableDates.length>0) {
      setSimpleAvailableDates(availableDates ? availableDates.map(date => date.Date) : []);
    }
  }, [availableDates]);

  // Get chart time range history, stored as YYYYMM
  const chartDateRangePersist = useSelector(state => state.appearancePersistence.chartDateRangePersist);

  // Persistent chart time range, stored as [n,m] start and end integers in the range of available data for the selected metric
  const [userPreferredRangeSelection, setUserPreferredRangeSelection] = useState([0, unifiedData.length - 1]);

  // Translate user preferred range selection YYYYMM pair to index pair
  useMemo (() => {
    if(chartDateRangePersist.length === 2 && simpleAvailableDates.length > 0) {
      // Find the position index of the matches for elements of chartDateRangePersist in simpleAvailableDates
      const preferredIndexBestMatches = chartDateRangePersist.map(date => simpleAvailableDates.indexOf(date));
      // Replace a -1 match (outside range) with the last index of simpleAvailableDates
      preferredIndexBestMatches.forEach(match => {
        if (match === -1) {
          preferredIndexBestMatches[preferredIndexBestMatches.indexOf(match)] = simpleAvailableDates.length - 1;
        }
      });
    setUserPreferredRangeSelection(preferredIndexBestMatches);
  }
  }, [chartDateRangePersist, simpleAvailableDates]);

  // Initial display selection is the range of the user preferred range within the available data range, and in terms of the unified data range
  const [initialDisplaySelection, setInitialDisplaySelection] = useState(userPreferredRangeSelection);
  // Monitor for validity of user preferred range selection, to set initial display selection
  useEffect(() => {
    if (validDataEndIndex && unifiedData.length > 0) {
      // Preferred range start is the user preference, adjusted for the valid start index (though this must be greater than or equal to 0)
      const initialRangeStart = Math.max(userPreferredRangeSelection[0]-validDataStartIndex, 0);
      // Preferred range end is the user preference, also adjusted back for the valid start index (though never greater than the length of the unified data to display)
      const initialRangeEnd   = Math.min(userPreferredRangeSelection[1]-validDataStartIndex, unifiedData.length-1);

      setInitialDisplaySelection([initialRangeStart, initialRangeEnd]);
    }
    // Only new valid start and end indices for new data reset the initial preferred range in this way
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validDataStartIndex, validDataEndIndex] );


  // User (via slider) also updates selectedRange
  const handleSliderChange = (newRange) => {
    setSelectedRange(newRange);
  };
  
  // Separate defined variables for startRange and endRange allow more dynamic labelling
  const [startRange, setStartRange] = useState(selectedRange[0]);
  const [endRange, setEndRange] = useState(selectedRange[1]);
  // New selectedRange changes dateFormat, also sets the separate startRange and endRange, also updates its persistent store
  useEffect(() => {
    setStartRange(selectedRange[0]);
    setEndRange(selectedRange[1]);
    setDateFormat(determineFormat(selectedRange[1] - selectedRange[0]));
  }, [selectedRange]); 

  // Access to the last date from the filteredData for use as a backupDate
  const [lastDate, setLastDate] = useState("");

  // Store formatted preferred date range
  const [formattedUserPreferredStartDate, setFormattedUserPreferredStartDate] = useState(chartDateRangePersist[0]);
  const [formattedUserPreferredEndDate, setFormattedUserPreferredEndDate] = useState(chartDateRangePersist[1]);
  // With a considerable debounce delay, update the user preferred date range selection in Redux
  useEffect (() => {
    if( !isLoading && !isFadingOut && !isAnimatingChart && formattedUserPreferredStartDate!=="" && formattedUserPreferredEndDate!=="") {
      setTimeout(() => {
        if (formattedUserPreferredStartDate !== chartDateRangePersist[0] || formattedUserPreferredEndDate !== chartDateRangePersist[1]) {
          dispatch(setChartDateRangePersist([formattedUserPreferredStartDate, formattedUserPreferredEndDate]));
        }
      }, 1000);
    }
    // This *only* takes place when formattedUserPreferredStartDate or formattedUserPreferredEndDate change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedUserPreferredStartDate, formattedUserPreferredEndDate])



  
  // Any new endRange resets lastData and resets tooltip as active & non-manual
  useEffect(() => {
    // Check that selectedRange is within bounds
    if (endRange >= 0 && endRange < unifiedData.length && endRange >= selectedRange[0]) {
  
      // Reset filtered data for new selectedRange
      const newDataSlice = unifiedData.slice(selectedRange[0], endRange + 1);
      setFilteredData(newDataSlice);
      // Also pass up to parent the new data slice
      setChartFilteredData(newDataSlice);

      // Set the associated date sent on for the label, using the particular date from the above object (in this case the end date)
      setLastDate(newDataSlice[newDataSlice.length - 1].date);

      // Update the relevent user preferred date in local state
      const newLastDateFormatted = ConvertDateToYYYYMM(newDataSlice[newDataSlice.length - 1].date);
      setFormattedUserPreferredEndDate(newLastDateFormatted);


      // Reset lastDataLabel for new selectedRange
      const lastData = newDataSlice[newDataSlice.length - 1];
  
      // Reset lastDataPayload for the last visible data in new selectedRange
      const dataPayload = Object.keys(lastData).filter(key => key !== 'date').map(key => {
        const seriesName = key.split('_')[0];
        const seriesIndex = inputData.findIndex(series => `${series.labels.LocationLabels[0]}_${series.labels.MetricLabels[0]}` === key);
        return {
          name: seriesName,
          value: lastData[key],
          color: dynamicColorForIndex(seriesName, seriesIndex)
        };
      });
      setLastDataPayload(dataPayload);

      // Reset tooltip properties to auto & active & right-hand side
      setTooltipRatio({x: 1, y: tooltipRatio.y});
      setTooltipManual(false);
      setTooltipActive(true);
    }
  // This effect purposefully only takes place with its own logic when the **endRange** changes, not any change at all to selectedRange
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endRange, unifiedData]);

  // Similarly, in parallel, any new startRange resets lastData and resets tooltip as active & non-manual
  const [startRangeLast, setStartRangeLast] = useState(selectedRange[0]);

  // Parallel useEffect apart from failsafe above
  useEffect(() => {
    // Check that startRange has truly changed
    if (startRange===startRangeLast) {
      return;
    }
    // Check that selectedRange is within bounds (also does not apply if isExporting)
    if (!isExporting && !isAnimatingChart && startRange >= 0 && startRange < unifiedData.length && startRange <= selectedRange[1]) {
      // Reset filtered data for new selectedRange
      const newDataSlice = unifiedData.slice(startRange, selectedRange[1] + 1);
      setFilteredData(newDataSlice);
      // Also pass up to parent the new data slice
      setChartFilteredData(newDataSlice);

      // Set the associated date sent on for the label, using the particular date from the above object (in this case the start date)
      setLastDate(newDataSlice[0].date);

      // Update the relevent user preferred date in local state
      const newFirstDateFormatted = ConvertDateToYYYYMM(newDataSlice[0].date);
      setFormattedUserPreferredStartDate(newFirstDateFormatted);

  
      // Reset lastDataLabel for new selectedRange
      const firstData = newDataSlice[0];
  
      // Reset lastDataPayload for the last visible data in new selectedRange
      const dataPayload = Object.keys(firstData).filter(key => key !== 'date').map(key => {
        const seriesName = key.split('_')[0];
        const seriesIndex = inputData.findIndex(series => `${series.labels.LocationLabels[0]}_${series.labels.MetricLabels[0]}` === key);
        return {
          name: seriesName,
          value: firstData[key],
          color: dynamicColorForIndex(seriesName, seriesIndex)
        };
      });
      setLastDataPayload(dataPayload); //This is the last in sense of most recent, but first in series, in this specific parellel case

      // Reset tooltip properties to auto & active & **left**-hand side
      setTooltipRatio({x: 0, y: tooltipRatio.y});
      setTooltipManual(false);
      setTooltipActive(true);

      // Set the failsafe for startRangeLast
      setStartRangeLast(startRange);
    }
  // This effect purposefully only takes place with its own logic when the **startRange** changes, not any change at all to selectedRange
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startRange, unifiedData]);

  

  // User clicks set both manual position and sets toolTipActive
  const handleChartClick = (event) => {
    if (!event.activeCoordinate) {
      return;
    }
    // Get click coordinates with defaults if errors
    let xClick = event.activeCoordinate?.x ?? setTooltipPositionX;
    let yClick = event.activeCoordinate?.y ?? setTooltipPositionY;
    // Calculate the adjusted xClick position, relative to the grid
    let adjustedXClick = xClick - chartPositionXOffset;
    let adjustedYClick = yClick - chartPositionYOffset;
    adjustedXClick = Math.max(adjustedXClick, 0); // Ensure that the adjusted xClick is not less than 0
    adjustedXClick = Math.min(adjustedXClick, chartGridWidth); // Ensure that the adjusted xClick is not greater than the grid width
    adjustedYClick = Math.max(adjustedYClick, 0); // Ensure that the adjusted yClick is not less than 0
    adjustedYClick = Math.min(adjustedYClick, chartGridHeight); // Ensure that the adjusted yClick is not greater than the grid height
 
    // Catch any other errors from the click event
    if (!event.activePayload || !event.activeCoordinate) return;
    // Extract the payload and format it for the tooltip content
    setLastDataPayload(event.activePayload);
  
    // The click on the chart sets it to manual click position and active
    setTooltipManual(true);
    setTooltipActive(true);

    setTooltipRatio({x: adjustedXClick/chartGridWidth, y: adjustedYClick/chartGridHeight});
  };

  
  


  // Loading state logic final step before return statement
  // Set universal loading state to false once all actions are complete
  useEffect (() => {
    if (!isLoading && !isFadingOut ) {
      dispatch(setLoading(false));
    }
    if (isLoading || isFadingOut || !unifiedData || !unifiedData.length || !selectedRange || !chartGridWidth) {
      dispatch(setLoading(true));
    }
  }, [isLoading, isFadingOut, unifiedData, selectedRange, chartGridWidth, dispatch]);
  // Setting fade out status after loading 
  useEffect(() => {
    // If required data is available and isLoading is still true, start the fade-out process.
    if (!!inputData.length && filteredData && isLoading) {
      setIsFadingOut(true); // Begin fade-out
      const timeoutId = setTimeout(() => {
        setIsLoading(false); // After the fade-out animation, hide the overlay
        setIsFadingOut(false); // Reset fading out state
      }, 700); // Duration should match fade-out animation duration

      return () => clearTimeout(timeoutId);
    }
  }, [inputData.length, filteredData, xAxisTicks, isLoading]);

  // Import universal state to double check if in exporting mode
  const isExporting = useSelector(state => state.appearancePersistence.exportingState);
  
  // Final resetting of selected range to ensure all in line with new data and after other elements are ready
  useEffect (() => {
    if(!isLoading && !isFadingOut && unifiedData.length>0 && initialDisplaySelection && initialDisplaySelection.length === 2) {
      // Super cool animation only if not in exporting mode
      if (!isExporting) {
        setIsAnimatingChart(true);
        const chartPortionAnimate =0.75;
        const animationStartIndex = Math.round(initialDisplaySelection[0]+((initialDisplaySelection[1]-initialDisplaySelection[0])*(1-chartPortionAnimate)));
        const totalRemainingSteps = initialDisplaySelection[1] - animationStartIndex;
        const animationPeriod = 1500;
        for (let i = animationStartIndex; i <= initialDisplaySelection[1]; i++) {
          const delayTimeForStep =animationPeriod*((i-animationStartIndex)/totalRemainingSteps);
          setTimeout(() => setSelectedRange([initialDisplaySelection[0], i]), delayTimeForStep);
        }
        setTimeout(() => {
          setIsAnimatingChart(false);
          setSelectedRange([initialDisplaySelection[0], initialDisplaySelection[1]]);
        }, (animationPeriod+100)); // Timeout for final stage is purposefully a little longer than final in loop to ensuire loop is complete first
      } else {
        // If in exporting mode, just set the selected range
        setTimeout(() => {
          setSelectedRange([initialDisplaySelection[0], initialDisplaySelection[1]]);
        }, (100)); // Also aplpy a small delay here, within teh export setup time, to ensure everything is in order
      }
    }
    // This runs purposefully when the initial display selection is set, not just when there is unified data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isFadingOut, initialDisplaySelection]);




  
  // Dynamic post loading updating of x axis ticks on dateFormat or filteredData change
  useEffect(() => {
    if(filteredData && !isLoading){
      setXaxisTicks(getXAxisTicks(filteredData, dateFormat));
    }
  }, [filteredData, dateFormat, isLoading]);

  // Sort the tooltip payload by value in descending order just before rendering
  const [sortedPayload, setSortedPayload] = useState([...lastDataPayload].sort((a, b) => b.value - a.value));
  useEffect (() => {
    setSortedPayload([...lastDataPayload].sort((a, b) => b.value - a.value));
  }, [lastDataPayload])



  // Calculate overall min and max values for chart y axis range
  const [chartMin, setChartMin] = useState(0);
  const [chartMax, setChartMax] = useState(100);
  const [chartYTicks, setChartYTicks] = useState([-1, 0, 1, 2]);
  useEffect (() => {
    if(filteredData){
      let overallMin = Infinity; // Set initial min
      let overallMax = -Infinity;  // Set initial max
      try {
        filteredData.forEach(item => {
            Object.keys(item).forEach(key => {
                // Explicitly exclude the 'date' property
                if (key !== 'date') {
                    const value = item[key];
                    // Check if the value is a number before processing
                    if (typeof value === 'number') {
                        // Update overall min and max values
                        overallMin = Math.min(overallMin, value);
                        overallMax = Math.max(overallMax, value);
                    }
                }
            });
        });
      } catch (error) {
        console.error('Error calculating overall min and max values for chart y axis range: ', error);
        overallMin = 0;
        overallMax = 100;
      }

      // Calculate rounded min and max values
      const rangeExtendRatio = 0.2;
      const minMaxExtendRatio = 0.1;
      let overallRange = overallMax - overallMin;
      let initialMin = overallMin; // Store the initial min for later
      overallMin = overallMin -(minMaxExtendRatio*overallMin) -(rangeExtendRatio*overallRange);
      overallMax = overallMax +(minMaxExtendRatio*overallMax) +(rangeExtendRatio*overallRange);
      overallMax = roundToSignificantFigures(overallMax, 2)
      overallMin = roundToSignificantFigures(overallMin, 2)
      const roundedRange = roundToSignificantFigures(overallMax - overallMin, 1);

      let tickRange = roundToSignificantFigures(roundedRange / 4, 1);
      // Allow for more precision and smaller tick ranges if zero arises
      if( tickRange===0 ) {tickRange = roundToSignificantFigures(roundedRange / 4, 2)};
      if( tickRange===0 ) {tickRange = roundToSignificantFigures(roundedRange / 4, 3)};

      overallMin = roundToNearestMultiple(overallMin, tickRange, 'down');
      overallMax = roundToNearestMultiple(overallMax, tickRange, 'up');
      if (initialMin >0 && overallMin < 0) overallMin = 0;
      overallMin = roundToSignificantFigures(overallMin, 2);
      overallMax = roundToSignificantFigures(overallMax, 2);

      // Finalise to ensure round numbers are applied to final tick range too
      let amendedTickRange = roundToSignificantFigures((overallMax-overallMin)/ 4, 1);
      // Again allow for more precision and smaller tick ranges if zero arises
      if( amendedTickRange===0 ) {amendedTickRange = roundToSignificantFigures((overallMax-overallMin)/ 4, 2)};
      if( amendedTickRange===0 ) {amendedTickRange = roundToSignificantFigures((overallMax-overallMin)/ 4, 3)};

      // Create ticks vector
      let ticks = [];
      for (let tick = overallMin; tick <= overallMax; tick += amendedTickRange) {
          ticks.push(tick);
      }
      ticks = roundToSignificantFigures(ticks, 3);

      // If ticks includes both a negative element and a positive element but not zero, add zero as a tick
      if (ticks.some(num => num < 0) && ticks.some(num => num > 0) &&!ticks.includes(0)) {
        ticks.push(0);
        ticks.sort((a, b) => a - b); // Keep the ticks sorted after adding 0
      }
      // Also check that ticks includes the overallMax, if the latter is a number
      if (typeof overallMax === 'number' && !isNaN(overallMax)) {
        const tolerance = 1e-5; // Allow for almost the same value
        const alreadyIncluded = ticks.some(tick => Math.abs(tick - overallMax) < tolerance);
        if (!alreadyIncluded) {
          ticks.push(overallMax); // Add overallMax as a tick if it is not already included (within a tolerance)
          ticks.sort((a, b) => a - b);  // Keep the ticks sorted after adding overallMax
        }
      }

      setChartMin(overallMin);
      setChartMax(overallMax);
      setChartYTicks(ticks);
    }
  }, [filteredData, isLoading])



  // Handle y axis tooltip position
  useEffect(() => {
    if (!tooltipManual && sortedPayload && sortedPayload.length > 0) {
      const valueNumbers = sortedPayload.map(item => item.value);
      const chartVentiles =(chartMax - chartMin)/20;
  
      // Round all elements of valueNumbers to the nearest multiple of chartVentiles
      const roundedValueNumbers = valueNumbers.map(num => roundToNearestMultiple(num, chartVentiles, 'nearest'));
      // Add artificial valueNumbers to the roundedValueNumbers array at the extremes
      roundedValueNumbers.unshift(chartMin - 5*chartVentiles);
      roundedValueNumbers.push(chartMax + 5*chartVentiles);
      // Create a complete array of the chartVentiles
      const chartVentilesArray = Array.from({length: 20}, (_, i) => chartMin + (i * chartVentiles));
  
      // Return the closest element of roundedValueNumbers to each element of chartVentilesArray
      const closestVentiles = chartVentilesArray.map(num => roundedValueNumbers.reduce((a, b) => Math.abs(b - num) < Math.abs(a - num) ? b : a));
      // Score each element of chartDecileArray based on their distance to the matching element of closestDeciles
      const scores = chartVentilesArray.map(num => Math.abs(num - closestVentiles[chartVentilesArray.indexOf(num)]));
      // Find the index of the maximum score, i.e. the part of the range furthest from datapoints
      const maxScoreIndex = scores.indexOf(Math.max(...scores));
      // Calculate the y position of the tooltip based on the maxScoreIndex
      const yRatio = 1 -((maxScoreIndex + 1) / 20);

      setTooltipRatio({x: tooltipRatio.x, y: yRatio});
    }
  }, [sortedPayload, chartMin, chartMax, tooltipRatio.x, selectedRange, tooltipManual])




  // Final chart window combined return statement 
  return (
    <div className='content-container'>
      {(isLoading || isFadingOut) && <div className={`loading-overlay overlay-dark ${isFadingOut ? 'fadeOut' : ''}`}><LoadingWheel /></div>}
      <div className="chart-and-slider">
        <div className="ResponsiveContainerWrapper">
          <ResponsiveContainer style={{paddingLeft: '10px'}}>
            {!isLoading && (<LineChart
              data={filteredData}
              onClick={handleChartClick}
              margin={{ top: 5, right: 30, bottom: 5, left: 20 }}>
                <XAxis
                  style={{paddingLeft: '10px'}}
                  dataKey="date"
                  type="category"
                  tick={{ fill: '#fff' }}
                  tickFormatter={(date) => formatDate(date, dateFormat)}
                  ticks={xAxisTicks}
                />
                <YAxis
                  tickFormatter={(value) => `${determineStatPrefix(metricName)}${value.toLocaleString()}${determineStatSuffix(metricName)}`}
                  tick={{ fill: '#fff' }}
                  domain={[chartMin, chartMax]}
                  ticks={chartYTicks}
                />
                <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.5} />
                <ReferenceLine y={0} stroke="#ffffff" strokeWidth={1} />

                {inputData.map((series, index) => {
                  const seriesId = `${series.labels.LocationLabels[0]}_${series.labels.MetricLabels[0]}`;
                  const seriesName = series.labels.LocationLabels[0];
                  return (
                    <Line
                      key={index}
                      type="monotone"
                      curve={curveCardinal}
                      dataKey={seriesId}
                      name={seriesName}
                      stroke={dynamicColorForIndex(seriesName, index)}
                      strokeWidth={2.5}
                      dot={false}
                      activeDot={{ r: 5 }}
                      isAnimationActive={true}
                      animationDuration={500}
                    />
                  );
                })}
            </LineChart> )}

            {!isLoading && !isFadingOut && (<ChartTooltip
              tooltipActive={tooltipActive} // Active state of the tooltip
              tooltipManual={tooltipManual} // Manual state of the tooltip (confirms label behaviour)
              chartGridWidth=  {chartGridWidth}
              chartGridHeight= {chartGridHeight}
              chartPositionXOffset={chartPositionXOffset}
              chartPositionYOffset={chartPositionYOffset}
              payload={sortedPayload} // Sorted and prepared data for display
              xPosition={tooltipPositionX} // Position of the tooltip to display
              yPosition={tooltipPositionY} // Position of the tooltip to display
              metricName={metricName}
              backupDate={lastDate}
              isAnimatingChart={isAnimatingChart}
              onClose={handleCloseTooltip}
            />)}
          </ResponsiveContainer>
        </div>
        <div className="DateRangeSliderWrapper">
          <DateRangeSlider
            domain={[0, Math.max(1, unifiedData.length - 1)]} // Prevents negative or zero max value
            values={selectedRange}
            onChange={handleSliderChange}
          />
        </div>
        </div>
    </div>
  );
}

export default ChartWindow;