import React, { useEffect, useState, useReducer } from 'react';
import { jwtDecode } from 'jwt-decode'; // Correct import for jwt-decode
import { NEW_USER_GUIDE_VERSION_CUTOFF_DAYS_SINCE_LAST_LOGIN } from '../../config';
import { setNewUserGuideVersion } from '../../redux/AppearancePersistence/appearancePersistenceSlice';
import { useDispatch } from 'react-redux';

// Set initial state for authentication
const initialState = {
  isAuthenticated: false,
  FirstName: '',
  SecondName: '',
  Username: 'Unknown',
  Company: null,
  Email: null,
  AlternativeEmail: null,
  PhoneNumber: null,
  LastLoginDate: null,
  Permissions: ['Unknown'],
};

// Define the reducer function
const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOG_IN':
      return {
        ...state,
        isAuthenticated: true,
        FirstName: action.payload.FirstName,
        SecondName: action.payload.SecondName,
        Username: action.payload.Username,
        Company: action.payload.Company,
        Email: action.payload.Email,
        AlternativeEmail: action.payload.AlternativeEmail,
        PhoneNumber: action.payload.PhoneNumber,
        LastLoginDate: action.payload.LastLoginDate,
        Permissions: action.payload.Permissions,
      };
    case 'LOG_OUT':
      return {
        ...state,
        isAuthenticated: false,
        FirstName: null,
        SecondName: null,
        Username: 'Unknown',
        Company: null,
        Email: null,
        AlternativeEmail: null,
        PhoneNumber: null,
        LastLoginDate: null,
        Permissions: ['Unknown'],
      };
    default:
      return state;
  }
};

// Create the context
const AuthContext = React.createContext(initialState);

// Define the Provider component
export const AuthProvider = ({ children }) => {
  const [authState, dispatch] = useReducer(authReducer, initialState);

  // Note the Redux dispatch is named explicitly in this component
  const dispatchRedux = useDispatch();


  const [isLoadingInitialPermissions, setIsLoadingInitialPermissions] = useState(true);

  // Check token validity on load and initialise the auth state
  useEffect(() => {
    const token = localStorage.getItem('userToken');
    let logoutTimer;

    if (token) {
      try {
        const decodedToken = jwtDecode(token);

        const currentTime = Date.now() / 1000;

        // Check if the token is expired
        if (decodedToken.exp < currentTime) {
          localStorage.removeItem('userToken');
          dispatch({ type: 'LOG_OUT' });
        } else {
          // Token is still valid, set the auth state based on the decoded token
          dispatch({
            type: 'LOG_IN',
            payload: {
              FirstName: decodedToken.FirstName,
              SecondName: decodedToken.SecondName,
              Username: decodedToken.Username,
              Company: decodedToken.Company,
              Email: decodedToken.Email,
              AlternativeEmail: decodedToken.AlternativeEmail,
              PhoneNumber: decodedToken.PhoneNumber,
              LastLoginDate: decodedToken.LastLoginDateISO,
              Permissions: Array.isArray(decodedToken.Permissions)
                ? decodedToken.Permissions
                : [decodedToken.Permissions],
            },
          });

          // Set the newUserGuideVersion state to true if the last log in date is more than a set number of days ago (newUserCuttoffDays from config.js)
          if (decodedToken.LastLoginDateISO) {
            const lastLoginDate = new Date(decodedToken.LastLoginDateISO);
            const daysSinceLastLogin = (currentTime - lastLoginDate.getTime()/1000) / (60 * 60 * 24);
            // console.log('Days since last login:', daysSinceLastLogin);
            if (daysSinceLastLogin > NEW_USER_GUIDE_VERSION_CUTOFF_DAYS_SINCE_LAST_LOGIN) {
              dispatchRedux(setNewUserGuideVersion(true));
            } else {
              dispatchRedux(setNewUserGuideVersion(false));
            }
          }

          // Set up automatic logout when the token expires (5 minutes early to avoid sudden expiry)
          const timeUntilExpiry = (decodedToken.exp * 1000) - Date.now() - 5 * 60 * 1000; // 5 minutes before expiry
          logoutTimer = setTimeout(() => {
            localStorage.removeItem('userToken');
            dispatch({ type: 'LOG_OUT' });
          }, timeUntilExpiry);
        }
      } catch (e) {
        // If token is invalid, remove it and log out the user
        console.error('Invalid token:', e);
        localStorage.removeItem('userToken');
        dispatch({ type: 'LOG_OUT' });
        setTimeout(() => window.location.reload(), 100);
      }
    }
    // Now confirm any checks on existing token are complete
    setIsLoadingInitialPermissions(false);

    // Cleanup the timer if the component unmounts or token changes
    return () => {
      if (logoutTimer) {
        clearTimeout(logoutTimer);
      }
    };
    // Empty dependency array means this runs only once after mounting
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider value={{ authState, dispatch, isLoadingInitialPermissions }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;