import axios from 'axios';
import { API_URL } from '../config';


// Main fetchDataMenu function to update the state with the latest available data menu, when needed
const fetchDataMenu = async (
    setMenuLoading, 
    setMenuError, 
    updateAvailableLocations, 
    updateAvailableMetrics, 
    updateAvailableDates, 
    updateAvailableGeographyTypes,
    dispatch) => {

    // Define function to calculate if enough time has passed to recheck the backend for any updates to DataMenu availability
    const shouldFetchDataMenu = () => {
        const lastUpdated = localStorage.getItem('dataMenuLastUpdated');
        // Set minutes between refreshing the menu options
        const minsBetweenMenuUpdates = 1;
            if (!lastUpdated) {
            return true;
            }
        const lastUpdatedTime = new Date(lastUpdated);
        const currentTime = new Date();
        const diffInMins = Math.abs(currentTime - lastUpdatedTime) / 6e4; // Convert milliseconds to minutes
        return diffInMins > minsBetweenMenuUpdates;
    };
    
    // Function to handle log off when 403 error is detected
    const handleLogOff = () => {
        localStorage.removeItem('userToken');
        dispatch({ type: 'LOG_OUT' });
        window.location.href = '/home'; // Redirects to '/home' route
    };

    
    if (!shouldFetchDataMenu()) {
      // Set menu component loading state to false if no need to update
      setMenuLoading(false);
      return;
    } else {
      setMenuLoading(true);
    }
    let response; // Declare response outside try-catch to access it in the later 'finally' block
    try {
      const token = localStorage.getItem('userToken');
      response = await axios.get(`${API_URL}/GetDataMenu`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        params: {
          locations: true,
          metrics: true,
          dates: true
        }
      });
      //console.log(response);
    } catch (error) {
      // Check for 403 error and handle log off
      if (axios.isAxiosError(error) && error.response && error.response.status === 403) {
        handleLogOff();
      } else {
        setMenuError(`Error fetching data menu: ${error.message}`);
      }
      setMenuLoading(false);
    } finally {
      if (response && response.data.data) {
        // Update Redux store with fetched data
        if (response.data.data.locations) updateAvailableLocations(response.data.data.locations);
        if (response.data.data.metrics) updateAvailableMetrics(response.data.data.metrics);
        if (response.data.data.dates) updateAvailableDates(response.data.data.dates);

        // Update dataMenuLastUpdated
        localStorage.setItem('dataMenuLastUpdated', new Date().toISOString());
      }
      // Determine unique geography types in available geography types
      let uniqueGeographyTypes = [];
      if (response && response.data.data && response.data.data.locations) {
        uniqueGeographyTypes = [...new Set(response.data.data.locations.map(location => location.Type))];
        updateAvailableGeographyTypes(uniqueGeographyTypes);
        //console.log(uniqueGeographyTypes);
      }
      // Set menu component loading state to false once data menu has been fetched and processed
      setMenuLoading(false);
    }
  };

  export default fetchDataMenu;