import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedDate: '', // This is set to the latest available date automatically by DataMenu
  selectedLocations: [''],
  currentDataRefs: [],
  addressSpecificTitle: '',
  selectedReportMetrics: [
    'Economic Vitality Index (EVI)',
    'Economic Momentum (EVI % change)'
  ],
  reportColourCoding: false
};

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    updateReportDate: (state, action) => {
      state.selectedDate = action.payload;
    },
    updateReportLocations: (state, action) => {
      state.selectedLocations = action.payload;
    },
    updateReportDataRefs: (state, action) => {
      // This will expect action.payload to be an array of refs
      state.currentDataRefs = action.payload;
    },
    appendReportDataRef: (state, action) => {
      // This option appends a new reference without duplicating existing ones
      if (!state.currentDataRefs.includes(action.payload)) {
        state.currentDataRefs.push(action.payload);
      }
    },
    updateAddressSpecificTitle: (state, action) => {
      state.addressSpecificTitle = action.payload;
    },
    updateReportMetric: (state, action) => {
      const { index, newValue } = action.payload;
      if (index >= 0 && index < state.selectedReportMetrics.length) {
        state.selectedReportMetrics[index] = newValue;
      }
    },
    appendReportMetric: (state, action) => {
      const { newMetric } = action.payload;
      if (!state.selectedReportMetrics.includes(newMetric)) {
        state.selectedReportMetrics.push(newMetric);
      }
    },
    removeReportMetric: (state, action) => {
      const { index } = action.payload;
      if (index >= 0 && index < state.selectedReportMetrics.length) {
        state.selectedReportMetrics.splice(index, 1);
      }
    },
    shiftUpReportMetric: (state, action) => {
      const { index } = action.payload;
      // Ensure the index is greater than 0 to be able to move up
      if (index > 0 && index < state.selectedReportMetrics.length) {
        // Swap the current element with the one above it
        const temp = state.selectedReportMetrics[index - 1];
        state.selectedReportMetrics[index - 1] = state.selectedReportMetrics[index];
        state.selectedReportMetrics[index] = temp;
      }
    },
    shiftDownReportMetric: (state, action) => {
      const { index } = action.payload;
      // Ensure the index is less than the last possible index to move down
      if (index >= 0 && index < state.selectedReportMetrics.length - 1) {
        // Swap the current element with the one below it
        const temp = state.selectedReportMetrics[index + 1];
        state.selectedReportMetrics[index + 1] = state.selectedReportMetrics[index];
        state.selectedReportMetrics[index] = temp;
      }
    },
    setTrueFalseReportColourCoding: (state, action) => {
      state.reportColourCoding = action.payload;
    },

  },
});

export const { updateReportDate,
               updateReportLocations,
               updateReportDataRefs,
               appendReportDataRef,
               updateAddressSpecificTitle,
               updateReportMetric,
               appendReportMetric,
               removeReportMetric,
               shiftUpReportMetric,
               shiftDownReportMetric,
               setTrueFalseReportColourCoding  } = reportsSlice.actions;
export default reportsSlice.reducer;