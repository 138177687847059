// Helper function to round numbers to a maximum of four significant figures
const roundToSignificantFigures = (value, precision = 4) => {
    if (typeof value !== 'number') return value;
    return Number(value.toPrecision(precision));
};

// Initial supporting function to filter inputData based on metricsToDisplay
const filterInputDataByMetrics = (inputData, metricsToDisplay) => {
    // Step 1: Iterate over inputData and reduce it to an object
    return inputData.reduce((acc, dataItem) => {
        // Extract labels and values
        const { labels, values } = dataItem;

        // Step 2: Find indices of the metrics that are in metricsToDisplay
        const metricLabels = labels.MetricLabels;
        const indicesToKeep = metricLabels.reduce((acc, metric, index) => {
            if (metricsToDisplay.includes(metric)) {
                acc.push(index);
            }
            return acc;
        }, []);

        // Step 3: Filter metricLabels and values by the indices
        const filteredMetricLabels = indicesToKeep.map(index => metricLabels[index]);
        const filteredValues = indicesToKeep.map(index => values[index]);

        // Step 4: Extract LocationLabels (assuming it's always an array with a single value)
        const location = labels.LocationLabels[0];

        // Step 5: Build a new filtered object without timestamp and DataLabels
        acc[location] = {
            labels: {
                MetricLabels: filteredMetricLabels,
            },
            values: filteredValues,
        };

        return acc;
    }, {});
};

// Function to transform filteredInputData into a simpler array structure
export const convertReportDataToCSV = (inputData, metricsToDisplay) => {

    // Step 0: apply stage above
    const filteredInputData = filterInputDataByMetrics(inputData, metricsToDisplay);

    // Step 1: Extract location labels (top-level keys of filteredInputData)
    const locationLabels = Object.keys(filteredInputData);

    // Step 2: Extract metric labels from the first location (assuming consistency)
    const firstLocation = locationLabels[0];
    const metricLabels = filteredInputData[firstLocation].labels.MetricLabels;

    // Step 3: Initialize the result array with the column headers (first column is "Area")
    const resultArray = [["Metric", ...locationLabels]];

    // Step 4: Iterate over each metric label
    metricLabels.forEach((metricLabel, metricIndex) => {
        // Create a row starting with the metric label
        const row = [metricLabel];
        // Step 5: Gather values for this metric across all locations, rounding each value
        locationLabels.forEach((location) => {
            const value = filteredInputData[location].values[metricIndex];
            row.push(roundToSignificantFigures(value));
        });
        // Step 6: Add the row to the result array
        resultArray.push(row);
    });
    // Step 7: Convert the result array into CSV string format
    const csvContent = resultArray.map(row => row.join(",")).join("\n");

    console.log(csvContent);

    return csvContent;
};