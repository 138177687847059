import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from     '../../config';
import ErrorBox from        '../../Components/InfoComponents/ErrorBox';
import LoadingWheel from    '../../Assets/LoadingSystem/LoadingWheel';

/* Note that 'tokens' externally mean credits for the use of the system, refered to here and throughout the code and database as 'credits' */
/* This is important to distinguish between the authentication Token as referenced below */
/* In code, 'credits' is always the name for access tokens as described externally */

function AdminCreditsInfo({displayCredits}) {
    const [creditsDisplay, setCreditsDisplay] = useState(null);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchCredits = async () => {
            setIsLoading(true);
            setError('');

            try {
                const token = localStorage.getItem('userToken');
                const response = await axios.get(`${API_URL}/GetProductCredits`, {
                    headers: { Authorization: `Bearer ${token}` }
                });

                if (response.status === 200 && response.data.status === 'Success') {
                    setCreditsDisplay(response.data.ProductCreditsRemaining);
                } else {
                    setError('Failed to fetch user credits.');
                }
            } catch (err) {
                if (err.response) {
                    setError(err.response.data.message || 'An error occurred.');
                } else {
                    setError('Network error fetching credits balance. Check connection.');
                }
            } finally {
                setIsLoading(false);
            }
        };

        fetchCredits();
    }, []);

    return (
        <div className='form-container top-start'>
            {displayCredits && <div className="form-box top-start">
                <h2>User's Tokens</h2>
                {isLoading ? <LoadingWheel /> : (
                    error ? <ErrorBox message={error} /> : (
                        <div className="credits-display">
                            <div  style={{textAlign: 'center'}}>
                                <h1 style={{marginBottom: '5px'}}><strong>{creditsDisplay !== null ? creditsDisplay.toLocaleString() : '...loading...'}</strong> </h1>
                                <p style={{marginTop: '0px'}}>tokens remaining</p>
                            </div>
                        </div>
                    )
                )}
            </div>}
        </div>
    );
}

export default AdminCreditsInfo;
