// Main function to convert chart data into CSV format
export const convertChartDataToCSV = (data) => {
    // Helper function to extract unique headers from the data
    const extractHeaders = (data) => {
        // Use flatMap and Set to extract unique keys, ensuring every field is included
        return Array.from(new Set(data.flatMap((item) => Object.keys(item))));
    };
    // Helper function to format each row based on the headers
    const formatRow = (headers, row) => {
        return headers
        .map((header) => {
            const value = row[header];
            // Handle date field: convert to "YYYY-MM-DD" format
            if (header.toLowerCase() === "date" && value) {
            return `"${formatDate(value)}"`; // Format the date in a standard way
            }
            // Handle numeric values: avoid scientific notation and round to 4 significant figures
            if (typeof value === "number") {
            return `"${roundToSignificantFigures(value)}"`; // Ensure consistent rounding
            }
            // Handle other values: escape quotes, commas, and newlines if necessary
            return value != null ? escapeValue(value) : ""; // Treat null and undefined as empty
        })
        .join(","); // Join all values into a single row
    };
    // Helper function to round numeric values to four significant figures, avoiding scientific notation
    const roundToSignificantFigures = (value, precision = 4) => {
        // Use Number.toFixed to avoid scientific notation and ensure consistent formatting
        const roundedValue = Number(value.toPrecision(precision));
        return roundedValue.toString(); // Convert back to string to ensure CSV compatibility
    };
    // Helper function to format the date as "YYYY-MM-DD", rounding to the nearest month's start date
    const formatDate = (date) => {
        let originalDate = new Date(date);
        // Add 2 hours to ensure we are not affected by DST transitions
        originalDate.setHours(originalDate.getHours() + 2);
        const year = originalDate.getFullYear();
        const month = originalDate.getMonth();
        const day = originalDate.getDate();
        // Determine the midpoint of the current month
        const midpoint = 15;
        // Round to the nearest month's start based on the midpoint
        let nearestMonthStart;
        if (day <= midpoint) {
            // Before or on the 15th, round to the start of the current month
            nearestMonthStart = new Date(year, month, 1);
        } else {
            // After the 15th, round to the start of the next month
            nearestMonthStart = new Date(year, month + 1, 1);
        }
        // Set the hour to midday to avoid timezone issues
        nearestMonthStart.setHours(12);
        // Return the formatted date as "YYYY-MM-DD"
        return nearestMonthStart.toISOString().split("T")[0];
    };
    // Helper function to escape values containing special characters (e.g., quotes, commas, newlines)
    const escapeValue = (value) => {
        const escapedValue = value.toString().replace(/"/g, '""');
        // Only add quotes if the value contains commas, quotes, or newlines
        return /[",\n]/.test(escapedValue) ? `"${escapedValue}"` : escapedValue;
    };

    // MAIN FUNCTION SECTION COMBINING ABOVE

    // Step 0: Check if data is provided
    if (!data || data.length === 0) {
      return ""; // Return an empty string if no data is provided
    }
    // Step 1: Extract headers from the data
    const headers = extractHeaders(data);
    let csvContent = headers.join(",") + "\n"; // Prepare CSV header row
    // Step 2: Generate CSV rows for each data point
    data.forEach((row) => {
      csvContent += formatRow(headers, row) + "\n"; // Add each formatted row to CSV content
    });
  
    return csvContent.trim() // Return the complete CSV content as a string, also removing leading/trailing whitespace
  };