const RegistrationTermsWording = `
Terms & Conditions

By registering your interest in EvaluateLocate, you agree that you have read and understood the following terms of registration, all future use of this platform and all future use of any and all associated information, software and services.

You agree to the following terms:

1.0 Personal data consent
You agree to EvaluateLocate holding, processing and analysing the information provided by you for the purposes of user registration, account management, user authentication and tracking of your usage of any and all services and products whether provided on a paid or unpaid basis.

1.1 Usage and browsing data
You agree to EvaluateLocate holding, processing and analysing data on all aspects of your usage of this and any other related tool, product and service, for the purposes of usage tracking, future product development, marketing and recommendation of additional services. This applies to all tools, services and products whether provided on a paid or unpaid basis.

2.0 Limits of reliability:
EvaluateLocate provides comparable, consistent, 'nowcast' and forecast metrics using standard economic definitions and incorporating source data from a number of official sources. This is for information purposes only and does not constitute any form of investment or financial advice.
We make no guarantee for the future availability of such estimates or the EvaluateLocate Vitality Index, which are provided on a best-endeavours basis.

3.0 Withholding of permission to republish materials
No part of this material or any research report resulting from such may be (i) copied, photocopied or duplicated in any form by any means or (ii) redistributed without the prior written consent of EvaluateLocate.

3.1 Use of source data:
Within its internal model, EvaluateLocate contains original data sourced from the Office for National Statistics, Scottish Government, NISRA and HM Land Registry licensed under the Open Government Licence, in addition to public register information via Companies House. This research is based on current public information that we consider to be reliable, but we do not guarantee it as accurate or complete, and it should not be relied on as such.

3.2 Trademarks and intellectual property:
“Evaluate Locate” is a registered trademark, number UK00003449764, held by EvaluateLocate Limited.
You note that copyright is an automatic intellectual property which applies to all wording, descriptions and computer code contained on any EvaluateLocate website, application, software or other materials.
In particular, the EvaluateLocate Vitality Index (or “EVI”) is powered by a proprietary model, protected under UK copyright law.

4.0 Jurisdiction of any dispute
Any dispute resulting from these terms or any use of EvaluateLocate products and services shall be settled in the English courts.

BY TICKING "I ACCEPT THE TERMS AND CONDITIONS" YOU AGREE TO THESE TERMS OF USE AND REGISTRATION.`;

export default RegistrationTermsWording;