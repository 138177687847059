import axios from 'axios';
import { API_URL } from '../config';

// Function to notify the backend of a data export and deduct credits
export const notifyDataExport = async () => {
    try {
        const token = localStorage.getItem('userToken');
        if (!token) {
            console.error("No user token found.");
            return { success: false, message: "Authentication required." };
        }

        const response = await axios.post(`${API_URL}/DataExportNotice`, {}, {
            headers: { Authorization: `Bearer ${token}` }
        });

        if (response.status === 200 && response.data.status === 'Success') {
            //console.log("Data export successfully recorded and credits deducted.");
            return { success: true, message: "Data export processed successfully." };
        } else {
            console.error("Failed to record data export:", response.data.message);
            return { success: false, message: response.data.message };
        }
    } catch (error) {
        if (error.response) {
            console.error("Error notifying data export:", error.response.data.message);
            return { success: false, message: error.response.data.message };
        } else {
            console.error("Network error:", error.message);
            return { success: false, message: "Network error. Please try again later." };
        }
    }
};