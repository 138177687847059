function GetDeviceAndBrowserInfo() {
    const userAgent = navigator.userAgent.toLowerCase();
    let deviceName = "Unknown Device";
    let browserName = "Unknown Browser";
  
    // Identify the device
    if (/android/.test(userAgent)) {
      deviceName = "Android Device";
    } else if (/iphone|ipad|ipod/.test(userAgent)) {
      deviceName = "iOS Device";
    } else if (/windows phone/.test(userAgent)) {
      deviceName = "Windows Phone";
    } else if (/macintosh|mac os x/.test(userAgent)) {
      deviceName = "MacOS Device";
    } else if (/windows/.test(userAgent)) {
      deviceName = "Windows PC";
    } else if (/linux/.test(userAgent)) {
      deviceName = "Linux Device";
    }
  
    // Identify the browser
    if (/firefox/.test(userAgent)) {
      browserName = "Mozilla Firefox";
    } else if (/opr/.test(userAgent) || /opera/.test(userAgent)) {
      browserName = "Opera";
    } else if (/chrome/.test(userAgent) && !/edg/.test(userAgent)) {
      browserName = "Google Chrome";
    } else if (/safari/.test(userAgent) && !/chrome/.test(userAgent)) {
      browserName = "Safari";
    } else if (/edg/.test(userAgent)) {
      browserName = "Microsoft Edge";
    } else if (/msie/.test(userAgent) || /trident/.test(userAgent)) {
      browserName = "Internet Explorer";
    }
  
    return {
      deviceName,
      browserName,
    };
  }
  
  export default GetDeviceAndBrowserInfo;  