import { convertChartDataToCSV } from "./ChartCSVTransform";
import { notifyDataExport } from './NotifyDataExport';
  
export const handleChartCSVExport = async ({ data, filename = "EvaluateLocate_Exported_Chart_Data.csv" }) => {
    // Step 0: Immediately start checking credits and setting up for export
    try {
        const { success, message } = await notifyDataExport();
        if (success) {
            setTimeout(() => {
                    // Step 1: Check if data is available
                    if (!data || data.length === 0) {
                        console.error("No data available to export."); // Log error or provide feedback
                        return;
                    }
                    // Step 2: Convert data to CSV using the imported function
                    const csvContent = convertChartDataToCSV(data);
                    // Step 3: Create a Blob for the CSV content
                    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
                    // Step 4: Create a temporary link element for downloading the CSV file
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(blob);
                    link.setAttribute("download", filename);
                    // Append the link to the DOM, trigger the click, and remove it afterward
                    document.body.appendChild(link);
                    link.click();
                    URL.revokeObjectURL(link.href); // Free the Blob URL memory
                    document.body.removeChild(link);

            }, 500); // Export process actually starts after slight additional delay
        } else {
            console.error(`Failed to export: ${message}`); // Provide feedback on failure
            alert(`Failed to export: ${message}`);
        }
    } catch (error) {
        console.error('Credit balance check failed:', error);
        alert('Failed to perform credit balance check. Please try again.');
    }
};