import React, { useState, useContext, useEffect } from 'react';
import { InstallPromptContext } from './InstallPromptContext';
import UseWindowSize from '../../DisplaySystem/WindowSize';
import downloadGeneralSymbol from '../../Assets/SocialShareLogos/downloadGeneralSymbol.png';
import GetDeviceAndBrowserInfo from './GetDeviceBrowserInfo';

const InstallButton = () => {
    const deferredPrompt = useContext(InstallPromptContext);

    const [showButton, setShowButton] = useState(true);

    const { width } = UseWindowSize();
    const horizontalLayoutBreakpoint = 1200;
    const wideScreen = width > horizontalLayoutBreakpoint;

    const {deviceName, browserName} = GetDeviceAndBrowserInfo();
    const installButtonDetail = `${deviceName ? `For ${deviceName}` : ''}${browserName ? ` via ${browserName}` : ''}`;



    // Effect to detect if app is installed
    useEffect(() => {
        const isAppInstalled = window.matchMedia('(display-mode: standalone)').matches;
        // Hide button if app is already installed
        if (isAppInstalled) {
            setShowButton(false);
        }
    }, []);

    const handleInstallClick = () => {
        if (deferredPrompt) {
            // Use the saved event from top level to trigger the instell prompt again
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then(choiceResult => {
                console.log(`User response: ${choiceResult.outcome}`);
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                    // If the user accepts the prompt, hide the button
                    setShowButton(false);
                } else {
                    console.log('User dismissed the install prompt');
                }
            });
        }
    };

    return (
        <>
            {deferredPrompt && showButton &&(
                <div style={{width: '100%', marginTop: '6px', marginBottom: '10px', padding: '0px',  display: 'flex', justifyContent: wideScreen? 'left' : 'center', textAlign: 'center'}}>
                    <button
                        onClick={handleInstallClick}
                        className="install-button">
                        <img src={downloadGeneralSymbol} alt="install symbol" className="social-share-image" style = {{ height: '30px', width: '30px', marginLeft: '15px'}}/>
                        <div style={{width: '100%', justifyContent: 'left', textAlign: 'left'}}>
                            <p style={{margin: '10px', marginTop: '3px', marginBottom: '0px'}}>{'Install app on this device'}</p>
                            <p style={{margin: '0px', marginTop: '0px', marginLeft: '10px', marginBottom: '3px', fontSize: '12px'}}>{installButtonDetail}</p>
                        </div>
                    </button>
                </div>
            )}
        </>
    );
};

export default InstallButton;