import React from 'react';
import ReactDOM from 'react-dom/client';
import { initializeGA, sendWebVitals } from './Analytics/Analytics';
import { AuthProvider } from './Authentication/PermissionLevels/AuthContext';
import './DisplaySystem/Layout.css'
import './DisplaySystem/Forms.css'
import './DisplaySystem/Components.css'
import App from './App';
import { store } from './redux/store';
import { Provider } from 'react-redux';


// Initialize Google Analytics and send web vitals
initializeGA('G-40HMDBHSVW');
sendWebVitals();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Provider>
  </React.StrictMode>
);


// Service worker registration, enabling app like and downloadable offline functionality
if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) { // Note that naming of serviceWorker on this line does not depend on filename
  window.addEventListener('load', function() {
    navigator.serviceWorker.register('/serviceWorkerV110.js').then(registration => { // Naming of serviceWorker on this line *does* need updating based on filename
      setInterval(() => {
        registration.update();
        console.log('Service Worker registered with scope:', registration.scope);
      }, 1000 * 3600); // Check for updates every hour
    })
    .catch(error => {
      console.log('Service Worker registration failed:', error);
    });
  });
} else {
  console.log("Current environment not production and/or serviceWorker not available in navigator")
}